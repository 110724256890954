import React from 'react';
import { useSelector } from 'react-redux';
import { API_BASE_URL, BASE_URL } from '../../../../config';

const HeaderProfile = () => {
    const admin = useSelector((state) => state.admin.value);
    return (
        <>
            <div className="dashboardContentHeaderUser">
                <div className="dashboardContentHeaderUserName">
                    <p className="dashboardContentHeaderUserNameTxt">{admin.name}</p>
                </div>
                <div className="dashboardContentHeaderUserPic">
                    <div className="dashboardContentHeaderUserPicImage">
                        {
                            admin?.profile_pic
                            ?
                                <img src={`${API_BASE_URL}${admin.profile_pic}`} className="dashboardContentHeaderUserPicImg" />
                            :
                                <img src={`${BASE_URL}images/admin-icon.jpg`} className="dashboardContentHeaderUserPicImg" />
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default HeaderProfile;
