import React, { useEffect, useState } from 'react'

const RadioButtonGroup = ({lable, isMandatory, isError, error, children}) => {
    const [showError, setShowError] = useState(isError);
    const [errorTxt, setErrorTxt] = useState(error);

    useEffect(() => {
        setShowError(isError);
        setErrorTxt(error);
    }, [isError]);

    return (
        <div className="formStep">
            <div className="formStepLable">
                <p className="formStepLableTxt">
                    {lable}
                    {
                        isMandatory === true
                        ?
                            <span className="formStepLableTxtCheck">*</span>
                        :
                            <></>
                    }
                </p>
            </div>

            <div className="formStepInput vCenter">
                {children}
            </div>

            {
                showError === true
                ?
                    <div className="formStepError">
                        <p className="formStepErrorTxt">{errorTxt}</p>
                    </div>
                :
                    <></>
            }
            
        </div>
    )
}

export default RadioButtonGroup