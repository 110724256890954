import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Heading from '../../../../components/dashboard/common/table/Heading';
import HeadingItem from '../../../../components/dashboard/common/table/HeadingItem';
import RowItem from '../../../../components/dashboard/common/table/RowItem';
import Table from '../../../../components/dashboard/common/table/Table';
import TableButton from '../../../../components/dashboard/common/table/TableButton';
import TableRow from '../../../../components/dashboard/common/table/TableRow';
import RowImage from '../../../../components/dashboard/common/table/RowImage';
import RowIcon from '../../../../components/dashboard/common/table/RowIcon';
import Pagination from '../../../../components/general/Pagination';
import PlainContainer from '../../../../components/general/containers/PlainContainer';
import Section from '../../../../components/general/containers/Section';
import { CATEGORY_ACTIVATED, CATEGORY_DELETED, CATEGORY_INACTIVATED, REQUEST_ERROR } from '../../../../utils/messages';
import { notifyError, notifySuccess } from '../../../../utils/toastify';

import axios from 'axios';

import { API_BASE_URL, DEFAULT_IP_ADDRESS, DEFAULT_PAGE_SIZE, FIRST_PAGE, LOGGER } from '../../../../config';
import { getUserIpAddress } from '../../../../utils/functions';

const Categories = () => {
    
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const [totalPages, setTotalPages] = useState({});
    const [currentPage, setCurrentPage] = useState(FIRST_PAGE);

    const [categories, setCategories] = useState([]);

    const getCategories = async (currentPage=FIRST_PAGE,pageSize=DEFAULT_PAGE_SIZE) => {
        try{
            const getCategoriesReq = await axios.get(`${API_BASE_URL}category/auth?page=${currentPage}&size=${pageSize}`, config);
            if(getCategoriesReq.status === 200){
                setCategories(getCategoriesReq.data.items);
                setTotalPages(getCategoriesReq.data.totalPages);
            }
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER && console.log(error);
        }
    }

    useEffect(() => {
        getCategories(currentPage);
    }, []);

    const updateThisCategoryStatus = async (id, status) => {
        let is_active;

        if(status === true){
            is_active = false;
        }
        else if (status === false){
            is_active = true;
        }
        else{
            return;
        }

        const data = {
            id: id,
            is_active: is_active
        }

        try{
            const updateCategoryStatusReq = await axios.patch(`${API_BASE_URL}category/active/${id}`, data, config);
            if(updateCategoryStatusReq.status === 200){
                getCategories(currentPage);
                notifySuccess(is_active ? CATEGORY_ACTIVATED : CATEGORY_INACTIVATED);
            }
            else{
                notifyError(REQUEST_ERROR);
            }
        }
        catch(error) {
            notifyError(REQUEST_ERROR);
        }
    }

    const deleteThisCategory = async (id) => {

        const data = {
            is_archived: true
        }
        try{
            const deleteCategoryReq = await axios.patch(`${API_BASE_URL}category/archive/${id}`, data, config);
            if(deleteCategoryReq.status === 200){
                getCategories();
                notifySuccess(CATEGORY_DELETED);
            }
            else{
                notifyError(REQUEST_ERROR);
            }
        }
        catch(error) {
            notifyError(REQUEST_ERROR);
        }
    }

    return (
        <Section heading="categories" description={routeText} action={() => navigate("create")} actionLable="create" showAction={true}>
            <Table>
                <Heading>
                    <HeadingItem size="1" isIcon={false} icon="" lable={`sl no`} />
                    <HeadingItem size="2" isIcon={false} icon="" lable={`icon`} />
                    <HeadingItem size="4" isIcon={false} icon="" lable={`name`} />
                    <HeadingItem size="1" isIcon={false} icon="" lable={``} />
                    <HeadingItem size="2" isIcon={false} icon="" lable={`status`} />
                    <HeadingItem size="3" isIcon={false} icon="" lable={`action`} />
                </Heading>
                <PlainContainer styles={{width: '100%'}}>
                    {
                        categories.map((category) => {
                            return (
                                <TableRow key={category.id}>
                                    <RowItem size="1" lable={category.category_id} isCenter={true} hasChildren={false}></RowItem>
                                    <RowItem size="2" isCenter={false} hasChildren={true}>
                                        {
                                            category.icon
                                            ?
                                            <RowIcon name={category.icon} />
                                            :
                                            category?.image
                                            ?
                                            <RowImage src={category?.image} />
                                            :
                                            <></>
                                        }
                                    </RowItem>
                                    <RowItem size="4" lable={category.name} isCenter={false} hasChildren={false}></RowItem>
                                    <RowItem size="1" lable={''} isCenter={true} hasChildren={true}>
                                        <RowIcon name="fa-solid fa-eye" action={() => navigate(`details/${category.id}`)} styles={{cursor: 'pointer'}} />
                                    </RowItem>
                                    <RowItem size="2" lable="2" isCenter={true} hasChildren={true}>
                                        {
                                            category.is_active === true
                                            ?
                                            <TableButton styles={{width: '100%'}} action={() => updateThisCategoryStatus(category.id, category.is_active)} type="success">active</TableButton>
                                            :
                                            <TableButton styles={{width: '100%'}} action={() => updateThisCategoryStatus(category.id, category.is_active)} type="danger">inactive</TableButton>
                                        }
                                    </RowItem>
                                    <RowItem size="3" lable="3" isCenter={false} hasChildren={true}>
                                        <TableButton styles={{width: 'calc(50% - 5px)', marginRight: '10px'}} action={() => navigate(`edit/${category.id}`)} type="primary">edit</TableButton>
                                        <TableButton styles={{width: 'calc(50% - 5px)'}} action={() => deleteThisCategory(category.id)} type="danger">delete</TableButton>
                                    </RowItem>
                                </TableRow>
                            )
                        })
                    }
                </PlainContainer>
            </Table>
            <Pagination totalPages={totalPages} handlePageChange={getCategories} setCurrentPage={setCurrentPage}/>
        </Section>
    );
};

export default Categories;