import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_BASE_URL, LOGGER } from '../../../config';
import { REQUEST_ERROR } from '../../../utils/messages';
import { notifyError } from '../../../utils/toastify';
import PlainContainer from '../../general/containers/PlainContainer';
import StatSingle from './StatSingle';

const Stats = ({auth, from, to}) => {

    const [totalUsers, setTotalUsers] = useState(0);
    const [totalArticles, setTotalArticles] = useState(0);
    const [totalWebsiteVisits, setTotalWebsiteVisits] = useState(0);
    const [totalPublicUsers, setTotalPublicUsers] = useState(0);

    return (
        <PlainContainer type="fullwidth" styles={{display: 'flex', flexDirection: 'row'}}>
            <StatSingle color="#c02329" showIcon={false} icon="" lable="admin users" value={totalUsers} />
            <StatSingle color="#278855" showIcon={false} icon="" lable="no. of articles" value={totalArticles} />
            <StatSingle color="#0a1f3e" showIcon={false} icon="" lable="website visits" value={totalWebsiteVisits} />
            <StatSingle color="#9c9a2d" showIcon={false} icon="" lable="public users" value={totalPublicUsers} />
        </PlainContainer>
    )
}

export default Stats