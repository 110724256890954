import React from 'react'

const AutoCompleteBubble = ({items = [], action}) => {
    return (
        <div className="dashboardContentMainFormStepInputSelectedAutoComplete">
            {
                items.map((item, i) => {
                    return (
                        <div className="dashboardContentMainFormStepInputSelectedAutoCompleteSingle" key={i + 1}>
                            <div className="dashboardContentMainFormStepInputSelectedAutoCompleteSingleInner">
                                <p className="dashboardContentMainFormStepInputSelectedAutoCompleteSingleTxt">{item.name}</p>

                                <div className="dashboardContentMainFormStepInputSelectedAutoCompleteSingleRemove" onClick={() => action(item.id)}>
                                    <i className='fa-solid fa-trash-can dashboardContentMainFormStepInputSelectedAutoCompleteSingleRemoveIco'></i>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default AutoCompleteBubble