import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import Form from '../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../components/general/forms/core/FormActions';
import FileInput from '../../../../../components/general/forms/FileInput';
import PlainStep from '../../../../../components/general/forms/PlainStep';
import TextArea from '../../../../../components/general/forms/TextArea';
import TextInput from '../../../../../components/general/forms/TextInput';
import SelectInput from '../../../../../components/general/forms/SelectInput';
import RadioButtonGroup from '../../../../../components/general/forms/RadioButtonGroup';
import CheckBoxGroup from '../../../../../components/general/forms/CheckBoxGroup';
import CheckBoxInput from '../../../../../components/general/forms/CheckBoxInput';
import RadioButtonInput from '../../../../../components/general/forms/RadioButtonInput';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../config';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';
import { PRODUCT_UPDATED, REQUEST_ERROR } from '../../../../../utils/messages';
import SplitContainer from '../../../../../components/general/containers/SplitContainer';

const EditProduct = () => {
    let { id } = useParams(); 

    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const tempServiceTypes = [
        {
            id: 'BOTH',
            name: 'both'
        },
        {
            id: 'STORE',
            name: 'store'
        },
        {
            id: 'ONLINE',
            name: 'online'
        }
    ]

    const tempFoodTypes = [
        {
            id: 'VEG',
            name: 'veg'
        },
        {
            id: 'EGG',
            name: 'egg'
        },
        {
            id: 'NON_VEG',
            name: 'non veg'
        }
    ]

    const tempSpiceLevels = [
        {
            id: 'NO_SPICE',
            name: 'no spice'
        },
        {
            id: 'MILD',
            name: 'mild'
        },
        {
            id: 'MEDIUM',
            name: 'medium'
        },
        {
            id: 'VERY_SPICY',
            name: 'very spicy'
        }
    ];

    const [serviceTypes, setServiceTypes] = useState(tempServiceTypes);
    const [foodTypes, setFoodTypes] = useState(tempFoodTypes);
    const [spiceLevels, setSpiceLevels] = useState(tempSpiceLevels);
    const [categories, setCategories] = useState([]);

    const [name, setName] = useState("");
    const [category, setCategory] = useState("0");
    const [shortcode, setShortcode] = useState("");
    const [description, setDescription] = useState("");
    const [storePrice, setStorePrice] = useState("0");
    const [onlinePrice, setOnlinePrice] = useState("0");
    const [smallImageURL, setSmallImageURL] = useState("");
    const [smallImageID, setSmallImageID] = useState("");
    const [largeImageURL, setLargeImageURL] = useState("");
    const [largeImageID, setLargeImageID] = useState("");
    const [serviceType, setServiceType] = useState("BOTH");
    const [foodType, setFoodType] = useState("VEG");
    const [spiceLevel, setSpiceLevel] = useState("NO_SPICE");

    const [isNameError, setIsNameError] = useState(false);
    const [isShortcodeError, setIsShortcodeError] = useState(false);
    const [isCategoryError, setIsCategoryError] = useState(false);
    const [isDescriptionError, setIsDescriptionError] = useState(false);
    const [isStorePriceError, setIsStorePriceError] = useState(false);
    const [isOnlinePriceError, setIsOnlinePriceError] = useState(false);
    const [isSmallImageURLError, setIsSmallImageURLError] = useState(false);
    const [isSmallImageIDError, setIsSmallImageIDError] = useState(false);
    const [isLargeImageURLError, setIsLargeImageURLError] = useState(false);
    const [isLargeImageIDError, setIsLargeImageIDError] = useState(false);
    const [isServiceTypeError, setIsServiceTypeError] = useState(false);
    const [isFoodTypeError, setIsFoodTypeError] = useState(false);
    const [isSpiceLevelError, setIsSpiceLevelError] = useState(false);

    const [nameErrorTxt, setNameErrorTxt] = useState("");
    const [shortcodeErrorTxt, setShortcodeErrorTxt] = useState("");
    const [categoryErrorTxt, setCategoryErrorTxt] = useState("");
    const [descriptionErrorTxt, setDescriptionErrorTxt] = useState("");
    const [storePriceErrorTxt, setStorePriceErrorTxt] = useState("");
    const [onlinePriceErrorTxt, setOnlinePriceErrorTxt] = useState("");
    const [smallImageIDErrorTxt, setSmallImageIDErrorTxt] = useState("");
    const [largeImageIDErrorTxt, setLargeImageIDErrorTxt] = useState("");
    const [serviceTypeErrorTxt, setServiceTypeErrorTxt] = useState("");
    const [foodTypeErrorTxt, setFoodTypeErrorTxt] = useState("");
    const [spiceLevelErrorTxt, setSpiceLevelErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const [showSmallImagePreview, setShowSmallImagePreview] = useState(true);
    const [showLargeImagePreview, setShowLargeImagePreview] = useState(true);

    const [showStorePrice, setShowStorePrice] = useState(true);
    const [showOnlinePrice, setShowOnlinePrice] = useState(true);

    const getCategories = async () => {
        try{
            const getCategoriesReq = await axios.get(`${API_BASE_URL}category`);
            if(getCategoriesReq.status === 200){
                setCategories(getCategoriesReq.data.items);
            }
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER && console.log(error);
        }
    }

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        if(serviceType === "BOTH"){
            setShowStorePrice(true);
            setShowOnlinePrice(true);
        }
        else if(serviceType === "STORE"){
            setShowStorePrice(true);
            setShowOnlinePrice(false);
        }
        else if(serviceType === "ONLINE"){
            setShowStorePrice(false);
            setShowOnlinePrice(true);
        }
        else{
            setShowStorePrice(true);
            setShowOnlinePrice(true);
        }
    }, [serviceType]);

    const getThisProduct = async (id) => {
        console.log(id);
        try{
            const getThisProductReq = await axios.get(`${API_BASE_URL}product/auth/${id}`, config);
            if(getThisProductReq.status === 200){
                console.log(getThisProductReq.data);
                setName(getThisProductReq.data.name);
                setShortcode(getThisProductReq.data.shortcode);
                setCategory(getThisProductReq.data.category.id);
                setServiceType(getThisProductReq.data.service_type);
                setFoodType(getThisProductReq.data.food_type);
                setSpiceLevel(getThisProductReq.data.spice_level);
                setStorePrice(getThisProductReq.data.price.base.store);
                setOnlinePrice(getThisProductReq.data.price.base.online);
                setDescription(getThisProductReq.data.description);
                setSmallImageURL(getThisProductReq.data.small_image?.url);
                setSmallImageID(getThisProductReq.data.small_image?.id);
                setLargeImageURL(getThisProductReq.data.large_image?.url);
                setLargeImageID(getThisProductReq.data.large_image?.id);
            }
            else{
                console.log('error')
                notifyError(REQUEST_ERROR);
            }
        }
        catch (error) {
            LOGGER && console.log(error);
            notifyError(REQUEST_ERROR);
        }
    }

    useEffect(() => {
        getThisProduct(id);
    }, []);

    const shortcodeChanged = async (data) => {
        setShortcode(data);
        try{
            const shortCodeStatusReq = await axios.get(`${API_BASE_URL}product/check-shortcode?shortcode=${data}`, config);
            if(shortCodeStatusReq.status === 200){
                setShortcodeErrorTxt('');
                setIsShortcodeError(false);
            }
        }
        catch(error){
            if(error.response.status === 403){
                setShortcodeErrorTxt('Shortcode Unavailable');
                setIsShortcodeError(true);
            }
            else{
                setShortcodeErrorTxt("Something Went Wrong. Try Again.");
                setIsShortcodeError(true);
            }
        }
    }

    const checkAndSetShortcode = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        shortcodeChanged(value)
    }

    const checkAndSetStorePrice = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setStorePrice(value);
    }

    const checkAndSetOnlinePrice = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setOnlinePrice(value);
    }

    const submitCreateProductForm = async (e) => {
        e.preventDefault();

        if(name == ''){
            setNameErrorTxt('Name is required');
            setIsNameError(true);
            return;
        }
        else{
            setNameErrorTxt('');
            setIsNameError(false);
        }

        if(shortcode === ''){
            setShortcodeErrorTxt('shortcode is required');
            setIsShortcodeError(true);
            return;
        }
        else{
            setShortcodeErrorTxt('');
            setIsShortcodeError(false);
        }

        if(category == '0'){
            setCategoryErrorTxt('Category is required');
            setIsCategoryError(true);
            return;
        }
        else{
            setCategoryErrorTxt('');
            setIsCategoryError(false);
        }

        if(showStorePrice === true){
            if(storePrice === ''){
                setStorePriceErrorTxt('Store price is required');
                setIsStorePriceError(true);
                return;
            }
            else{
                setStorePriceErrorTxt('');
                setIsStorePriceError(false);
            }
        }

        if(showOnlinePrice === true){
            if(onlinePrice === ''){
                setOnlinePriceErrorTxt('Online price is required');
                setIsOnlinePriceError(true);
                return;
            }
            else{
                setOnlinePriceErrorTxt('');
                setIsOnlinePriceError(false);
            }
        }
  
        const data = {
            name: name,
            shortcode: shortcode,
            category: category,
            description: description,
            store_price: storePrice,
            online_price: onlinePrice,
            service_type: serviceType,
            food_type: foodType,
            spice_level: spiceLevel,
            small_image: smallImageID,
            large_image: largeImageID
        }
        
        console.log(data);
    
        try {
            const updateProductReq = await axios.patch(`${API_BASE_URL}product/${id}`, data, config);
            notifySuccess(PRODUCT_UPDATED);
            navigate('/dashboard/masterdata/products');
            LOGGER &&  console.log(updateProductReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }

    }

    const resetForm = () => {
  
    }

    return (
        <Section heading="products" description={routeText} action={() => navigate("/dashboard/masterdata/products")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitCreateProductForm(e)} noValidate>
                    <Form>
                        <SplitContainer type="fullwidth">
                            <TextInput lable="name" isMandatory={true} isError={isNameError} error={nameErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={name} placeholder="Please Enter The Product Name" onChange={(e) => setName(e.target.value)} />
                            </TextInput>
                        </SplitContainer>
                        <SplitContainer type="fullwidth">
                            <TextInput lable="shortcode" isMandatory={true} isError={isShortcodeError} error={shortcodeErrorTxt}>
                                <input type="tel" className="formStepInputTxt capitalize" value={shortcode} placeholder="Please Enter The Shortcode" onChange={(e) => checkAndSetShortcode(e)} />
                            </TextInput>
                            <SelectInput lable="service type" capitalize={true} isMandatory={true} placeholder="Please Select The Service Type" value={serviceType} onchange={(data) => {setServiceType(data);}} isError={isServiceTypeError} error={serviceTypeErrorTxt} options={serviceTypes} />
                        </SplitContainer>
                        <SplitContainer type="fullwidth">
                            <SelectInput lable="food type" capitalize={true} isMandatory={true} placeholder="Please Select The Food Type" value={foodType} onchange={(data) => {setFoodType(data);}} isError={isFoodTypeError} error={foodTypeErrorTxt} options={foodTypes} />
                            <SelectInput lable="spice level" capitalize={true} isMandatory={true} placeholder="Please Select The Spice Level" value={spiceLevel} onchange={(data) => {setSpiceLevel(data);}} isError={isSpiceLevelError} error={spiceLevelErrorTxt} options={spiceLevels} />
                        </SplitContainer>
                        <SplitContainer type="fullwidth">
                            {
                                showStorePrice === true
                                ?
                                    <TextInput lable={`store price`} isMandatory={true} isError={isStorePriceError} error={storePriceErrorTxt}>
                                        <input type="tel" maxlength="4" className="formStepInputTxt capitalize" value={storePrice} placeholder={`Please Enter The Store Price`} onChange={(e) => checkAndSetStorePrice(e)} />
                                    </TextInput>
                                :
                                <></>
                            }
                            {
                                showOnlinePrice === true
                                ?
                                    <TextInput lable={`online price`} isMandatory={true} isError={isOnlinePriceError} error={onlinePriceErrorTxt}>
                                        <input type="tel" maxlength="4" className="formStepInputTxt capitalize" value={onlinePrice} placeholder={`Please Enter The online Price`} onChange={(e) => checkAndSetOnlinePrice(e)} />
                                    </TextInput>
                                :
                                <></>
                            }
                        </SplitContainer>
                        <TextArea lable="description" rows={3} isMandatory={false} placeholder="Please Enter The Description" value={description} onchange={(data) => setDescription(data)} isError={isDescriptionError} error={descriptionErrorTxt} />
                        <PlainStep>
                            <FileInput lable="small image" isMandatory={false} value={smallImageID} preview={smallImageURL} showPreview={showSmallImagePreview} onchange={(data) => {setSmallImageID(data); setShowSmallImagePreview(false); LOGGER &&  console.log(data)}} isError={isSmallImageIDError} error={smallImageIDErrorTxt} reset={resetAttachment} />
                            <FileInput lable="large image" isMandatory={false} value={largeImageID} preview={largeImageURL} showPreview={showLargeImagePreview} onchange={(data) => {setLargeImageID(data); setShowLargeImagePreview(false); LOGGER &&  console.log(data)}} isError={isLargeImageIDError} error={largeImageIDErrorTxt} reset={resetAttachment} />
                        </PlainStep>
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default EditProduct