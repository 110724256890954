import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Heading from '../../../../components/dashboard/common/table/Heading';
import HeadingItem from '../../../../components/dashboard/common/table/HeadingItem';
import RowItem from '../../../../components/dashboard/common/table/RowItem';
import Table from '../../../../components/dashboard/common/table/Table';
import TableButton from '../../../../components/dashboard/common/table/TableButton';
import TableRow from '../../../../components/dashboard/common/table/TableRow';
import RowImage from '../../../../components/dashboard/common/table/RowImage';
import RowIcon from '../../../../components/dashboard/common/table/RowIcon';
import Pagination from '../../../../components/general/Pagination';
import PlainContainer from '../../../../components/general/containers/PlainContainer';
import Section from '../../../../components/general/containers/Section';
import { PRODUCT_ACTIVATED, PRODUCT_DELETED, PRODUCT_INACTIVATED, REQUEST_ERROR } from '../../../../utils/messages';
import { notifyError, notifySuccess } from '../../../../utils/toastify';

import axios from 'axios';

import { API_BASE_URL, DEFAULT_IP_ADDRESS, DEFAULT_PAGE_SIZE, FIRST_PAGE, LOGGER } from '../../../../config';
import { getUserIpAddress } from '../../../../utils/functions';

const Products = () => {
    
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const [totalPages, setTotalPages] = useState({});
    const [currentPage, setCurrentPage] = useState(FIRST_PAGE);

    const [products, setProducts] = useState([]);

    const getProducts = async (currentPage=FIRST_PAGE,pageSize=DEFAULT_PAGE_SIZE) => {
        try{
            const getProductsReq = await axios.get(`${API_BASE_URL}product/auth?page=${currentPage}&size=${pageSize}`, config);
            if(getProductsReq.status === 200){
                console.log(getProductsReq.data.items);
                setProducts(getProductsReq.data.items);
                setTotalPages(getProductsReq.data.totalPages);
            }
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER && console.log(error);
        }
    }

    useEffect(() => {
        getProducts(currentPage);
    }, []);

    const updateThisProductStatus = async (id, status) => {

        const data = {
            is_active: status
        }

        try{
            const updateProductStatusReq = await axios.patch(`${API_BASE_URL}product/active/${id}`, data, config);
            if(updateProductStatusReq.status === 200){
                getProducts(currentPage);
                notifySuccess(status ? PRODUCT_ACTIVATED : PRODUCT_INACTIVATED);
            }
            else{
                notifyError(REQUEST_ERROR);
            }
        }
        catch(error) {
            notifyError(REQUEST_ERROR);
        }
    }

    const deleteThisProduct = async (id) => {
        const data = {
            is_archived: true
        }
        try{
            const deleteProductReq = await axios.patch(`${API_BASE_URL}product/archive/${id}`, data, config);
            if(deleteProductReq.status === 200){
                getProducts(currentPage);
                notifySuccess(PRODUCT_DELETED);
            }
            else{
                notifyError(REQUEST_ERROR);
            }
        }
        catch(error) {
            notifyError(REQUEST_ERROR);
        }
    }

    return (
        <Section heading="products" description={routeText} action={() => navigate("create")} actionLable="create" showAction={true}>
            <Table>
                <Heading>
                    <HeadingItem size="1" isIcon={false} icon="" lable={`sl no`} />
                    <HeadingItem size="4" isIcon={false} icon="" lable={`name`} />
                    <HeadingItem size="1" isIcon={true} icon="fa-solid fa-shop" lable={`store`} />
                    <HeadingItem size="1" isIcon={true} icon="fa-solid fa-globe" lable={`store`} />
                    <HeadingItem size="2" isIcon={false} icon="" lable={`store price`} />
                    <HeadingItem size="2" isIcon={false} icon="" lable={`online price`} />
                    <HeadingItem size="1" isIcon={false} icon="" lable={``} />
                    <HeadingItem size="2" isIcon={false} icon="" lable={`status`} />
                    <HeadingItem size="4" isIcon={false} icon="" lable={`action`} />
                </Heading>
                <PlainContainer styles={{width: '100%'}}>
                {
                    products.map((product, i) => {
                    return (
                        <TableRow key={product.id}>
                            <RowItem size="1" lable={product.product_id} isCenter={true} hasChildren={false}></RowItem>
                            <RowItem size="4" lable={`${product.name} - ${product.shortcode}`} isCenter={false} hasChildren={false}></RowItem>
                            <RowItem size="1" isCenter={false} hasChildren={true}>
                                {
                                    product.service_type == "BOTH" || product.service_type == "STORE"
                                    ?
                                        <RowIcon name="fa-solid fa-circle-check" />
                                    :
                                        <RowIcon name="fa-solid fa-ban" />
                                }
                            </RowItem>
                            <RowItem size="1" isCenter={false} hasChildren={true}>
                                {
                                    product.service_type == "BOTH" || product.service_type == "ONLINE"
                                    ?
                                        <RowIcon name="fa-solid fa-circle-check" />
                                    :
                                        <RowIcon name="fa-solid fa-ban" />
                                }
                            </RowItem>
                            <RowItem size="2" lable={product.price.final.store} isCenter={false} hasChildren={false}></RowItem>
                            <RowItem size="2" lable={product.price.final.online} isCenter={false} hasChildren={false}></RowItem>
                            <RowItem size="1" isCenter={false} hasChildren={true}>
                                <RowIcon name="fa-solid fa-eye" action={() => navigate(`details/${product.id}`)} styles={{cursor: 'pointer'}} />
                            </RowItem>
                            <RowItem size="2" lable="2" isCenter={true} hasChildren={true}>
                                {
                                product.is_active === true
                                ?
                                    <TableButton styles={{width: '100%'}} action={() => updateThisProductStatus(product.id, false)} type="success" disableClick={false}>active</TableButton>
                                :
                                    <TableButton styles={{width: '100%'}} action={() => updateThisProductStatus(product.id, true)} type="danger" disableClick={false}>inactive</TableButton>
                                }
                            </RowItem>
                            <RowItem size="4" lable="2" isCenter={false} hasChildren={true}>
                                <TableButton styles={{width: 'calc(50% - 5px)', marginRight: '10px'}} type="primary" action={() => navigate(`edit/${product.id}`)}>edit</TableButton>
                                <TableButton styles={{width: 'calc(50% - 5px)'}} action={() => deleteThisProduct(product.id)} type="danger">delete</TableButton>
                            </RowItem>
                        </TableRow>
                    )
                    })
                }
                </PlainContainer>
            </Table>
      
            <Pagination totalPages={totalPages} handlePageChange={getProducts} setCurrentPage={setCurrentPage}/>
        
        </Section>
    );
};

export default Products;