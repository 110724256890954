import React from 'react'

const CheckBoxInput = ({lable, id, reference, name, value, onchange, checked, disabled}) => {
  return (
    <label className="checkbox">
        <input type="checkbox" ref={reference} id={id} name={name} value={value} onChange={(e) => onchange(e)} defaultChecked={checked} disabled={disabled} />
        <span className="checkmark"></span>
        {lable}
    </label>
  )
}

export default CheckBoxInput