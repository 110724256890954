import React from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import Stats from '../../../components/dashboard/common/Stats';
import Heading from '../../../components/dashboard/common/table/Heading';
import HeadingItem from '../../../components/dashboard/common/table/HeadingItem';
import RowItem from '../../../components/dashboard/common/table/RowItem';
import Table from '../../../components/dashboard/common/table/Table';
import TableRow from '../../../components/dashboard/common/table/TableRow';
import PlainContainer from '../../../components/general/containers/PlainContainer';
import Section from '../../../components/general/containers/Section';
import TableButton from '../../../components/dashboard/common/table/TableButton';
import RowIcon from '../../../components/dashboard/common/table/RowIcon';
import RecentStoreOrders from '../../../components/dashboard/home/RecentStoreOrders';
import NewCustomers from '../../../components/dashboard/home/NewCustomers';

const Home = () => {
  let navigate = useNavigate();

  const admin = useSelector((state) => state.admin.value);

  const config = {
    headers: {
      'Authorization': admin.access_token
    }
  }

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;

  return (
    <>
      <Stats auth={config} from={today} to={today} />

      <PlainContainer styles={{marginTop: '20px', width: '100%'}}>
        <p>Home Content To Come Here!</p>
      </PlainContainer>
      
    </>  
  );
};

export default Home;