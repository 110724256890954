import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import Form from '../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../components/general/forms/core/FormActions';
import FileInput from '../../../../../components/general/forms/FileInput';
import PlainStep from '../../../../../components/general/forms/PlainStep';
import TextArea from '../../../../../components/general/forms/TextArea';
import TextInput from '../../../../../components/general/forms/TextInput';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../config';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';
import { STORE_CREATED, REQUEST_ERROR } from '../../../../../utils/messages';
import SplitContainer from '../../../../../components/general/containers/SplitContainer';
import { getUserIpAddress } from '../../../../../utils/functions';

const AddStore = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [latlong, setLatlong] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [imageId, setImageId] = useState("");

    const [isNameError, setIsNameError] = useState(false);
    const [isAddressError, setIsAddressError] = useState(false);
    const [isLatlongError, setIsLatlongError] = useState(false);
    const [isImageError, setIsImageError] = useState(false);

    const [nameErrorTxt, setNameErrorTxt] = useState("");
    const [addressErrorTxt, setAddressErrorTxt] = useState("");
    const [latlongErrorTxt, setLatlongErrorTxt] = useState("");
    const [imageErrorTxt, setImageErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const submitCreateStoreForm = async (e) => {
        e.preventDefault();

        if(name == ''){
            setNameErrorTxt('Name is required');
            setIsNameError(true);
            return;
        }
        else{
            setNameErrorTxt('');
            setIsNameError(false);
        }

        if(address == ''){
            setAddressErrorTxt('Address is required');
            setIsAddressError(true);
            return;
        }
        else{
            setAddressErrorTxt('');
            setIsAddressError(false);
        }

        if(latlong == ''){
            setLatlongErrorTxt('Latlng is required');
            setIsLatlongError(true);
            return;
        }
        else{
            setLatlongErrorTxt('');
            setIsLatlongError(false);
        }

        const data = {
            name: name,
            address: address,
            image: imageId,
            latlong: latlong
        }

        LOGGER &&  console.log(data);

        try {
            const createStoreReq = await axios.post(`${API_BASE_URL}store`, data, config);
            notifySuccess(STORE_CREATED);
            navigate('/dashboard/masterdata/stores');
            LOGGER &&  console.log(createStoreReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {

        setName("");
        setAddress("");
        setLatlong("");
        setImageUrl("");
        setImageId("");

        setIsNameError(false);
        setIsAddressError(false);
        setIsLatlongError(false);
        setIsImageError(false);

        setNameErrorTxt("");
        setAddressErrorTxt("");
        setLatlongErrorTxt("");
        setImageErrorTxt("");

        setResetAttachment(true)
    }

    return (
        <Section heading="stores" description={routeText} action={() => navigate("/dashboard/masterdata/stores")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitCreateStoreForm(e)} noValidate>
                    <Form>
                        <TextInput lable="name" capitalize={true} isMandatory={true} isError={isNameError} error={nameErrorTxt}>
                            <input type="text" className="formStepInputTxt capitalize" value={name} placeholder="Please Enter The Store Name" onChange={(e) => setName(e.target.value)} />
                        </TextInput>
                        <TextArea lable="address" rows={3} isMandatory={false} placeholder="Please Enter The Address" value={address} onchange={(data) => setAddress(data)} isError={isAddressError} error={addressErrorTxt} />
                        <TextInput lable="latlong" isMandatory={false} isError={isLatlongError} error={latlongErrorTxt}>
                            <input type="text" className="formStepInputTxt" value={latlong} placeholder="Please Enter The Latlong" onChange={(e) => setLatlong(e.target.value)} />
                        </TextInput>
                        <PlainStep>
                            <FileInput lable="image" isMandatory={false} value={imageId} preview={imageUrl} onchange={(data) => {setImageId(data); LOGGER &&  console.log(data)}} isError={isImageError} error={imageErrorTxt} reset={resetAttachment} />
                        </PlainStep>
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default AddStore