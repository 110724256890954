import React from 'react';

const Text = ({variant, type, color, isCapital, styles = {}, children}) => {
  //let textClass = "typography";

  let variantClass;

  if(variant === "page-heading"){
    variantClass = `pageHeadingTxt ${isCapital === false ? `emailTxt` : ''}`;
  }
  else if(variant === "page-subheading"){
    variantClass = `pageSubHeadingTxt ${isCapital === false ? `emailTxt` : ''}`;
  }
  else if(variant === "stats-lable"){
    variantClass = `statsLableTxt ${isCapital === false ? `emailTxt` : ''}`;
  }
  else if(variant === "stats-value"){
    variantClass = `statsValueTxt ${isCapital === false ? `emailTxt` : ''}`;
  }
  else if(variant === "section-heading"){
    variantClass = `sectionHeadingTxt ${isCapital === false ? `emailTxt` : ''}`;
  }
  else if(variant === "section-description"){
    variantClass = `sectionDescriptionTxt ${isCapital === false ? `emailTxt` : ''}`;
  }
  else if(variant === "table-heading"){
    variantClass = `tableHeadingTxt ${isCapital === false ? `emailTxt` : ''}`;
  }
  else if(variant === "table-content"){
    variantClass = `tableContentTxt ${isCapital === false ? `emailTxt` : ''}`;
  }
  else if(variant === "popup-lable"){
    variantClass = `popupLableTxt ${isCapital === false ? `emailTxt` : ''}`;
  }
  else if(variant === "fileupload-lable"){
    variantClass = `fileUploadLableTxt ${isCapital === false ? `emailTxt` : ''}`;
  }

  return (
      <>
        {
          type === 'default'
          ?
            <p className={`${variantClass}`} style={styles}>{children}</p>
          :
            <></>
        }
      </>
  );
};

export default Text;
