import React, { useEffect, useState } from 'react';

const TextArea = ({lable, isMandatory, placeholder, value, onchange, isError, error, rows}) => {

    const [showError, setShowError] = useState(isError);
    const [errorTxt, setErrorTxt] = useState(error);
    const [valueTxt, setValueTxt] = useState(value);

    useEffect(() => {
        setShowError(isError);
        setErrorTxt(error);
    }, [isError]);

    useEffect(() => {
        setValueTxt(value);
    }, [value]);

    return (
        <div className="formStep">
            <div className="formStepLable">
                <p className="formStepLableTxt">
                    {lable}
                    {
                        isMandatory === true
                        ?
                            <span className="formStepLableTxtCheck">*</span>
                        :
                            <></>
                    }
                </p>
            </div>

            <div className="formStepInput">
                <textarea className="formStepInputTxtarea" rows={rows} placeholder={placeholder} onChange={(e) => onchange(e.target.value)} value={valueTxt}></textarea>
            </div>

            {
                showError === true
                ?
                    <div className="formStepError">
                        <p className="formStepErrorTxt">{errorTxt}</p>
                    </div>
                :
                    <></>
            }
            
        </div>
    );
};

export default TextArea;
