export const REQUEST_ERROR = "Something went wrong";

//Image Upload
export const IMAGE_UPLOADED = "Image uploaded successfully";

// admin
export const ADMIN_CREATED = "Admin created successfully";
export const ADMIN_UPDATED = "Admin updated successfully";
export const ADMIN_DELETED = "Admin deleted successfully";
export const ADMIN_INACTIVATED = "Admin inactivated successfully";
export const ADMIN_ACTIVATED = "Admin activated successfully";
export const ADMIN_LOGIN_FAILED = "Invalid Email ID or Password";
export const ADMIN_LOGIN_SUCCESS = "Login Successfull";

// masterdata >> category
export const CATEGORY_CREATED = "Category created successfully";
export const CATEGORY_UPDATED = "Category updated successfully";
export const CATEGORY_DELETED = "Category deleted successfully";
export const CATEGORY_INACTIVATED = "Category inactivated successfully";
export const CATEGORY_ACTIVATED = "Category activated successfully";

// masterdata >> pizza sizes
export const PIZZA_SIZE_CREATED = "Pizza size created successfully";
export const PIZZA_SIZE_UPDATED = "Pizza size updated successfully";
export const PIZZA_SIZE_DELETED = "Pizza size deleted successfully";
export const PIZZA_SIZE_INACTIVATED = "Pizza size inactivated successfully";
export const PIZZA_SIZE_ACTIVATED = "Pizza size activated successfully";

// masterdata >> pizza crusts
export const PIZZA_CRUST_CREATED = "Pizza crust created successfully";
export const PIZZA_CRUST_UPDATED = "Pizza crust updated successfully";
export const PIZZA_CRUST_DELETED = "Pizza crust deleted successfully";
export const PIZZA_CRUST_INACTIVATED = "Pizza crust inactivated successfully";
export const PIZZA_CRUST_ACTIVATED = "Pizza crust activated successfully";

// masterdata >> products
export const PRODUCT_CREATED = "Product created successfully";
export const PRODUCT_UPDATED = "Product updated successfully";
export const PRODUCT_DELETED = "Product deleted successfully";
export const PRODUCT_INACTIVATED = "Product inactivated successfully";
export const PRODUCT_ACTIVATED = "Product activated successfully";
export const PRODUCT_RECOMMENDED = "Product made recommended successfully";
export const PRODUCT_NOT_RECOMMENDED = "Product removed from recommended successfully";

// masterdata >> products >> variants
export const PRODUCT_VARIANT_CREATION_FAILED = "Failed to create product variant";
export const PRODUCT_VARIANT_DELETED = "Product variant deleted successfully";
export const PRODUCT_VARIANT_CREATED = "Variant type created successfully";
export const PRODUCT_VARIANT_UPDATED = "Variant type updated successfully";
export const PRODUCT_SINGLE_VARIANT_CREATED = "Variant created successfully";
export const PRODUCT_SINGLE_VARIANT_DELETED = "Variant removed successfully";
export const PRODUCT_SINGLE_VARIANT_UPDATED = "Variant updated successfully";
export const PRODUCT_SINGLE_VARIANT_CREATION_FAILED = "Failed to add variant";
export const PRODUCT_SINGLE_VARIANT_DEFAULT_UPDATED = "Variant defaults updated successfully";
export const PRODUCT_SINGLE_VARIANT_DEFAULT_COUNT_FAILED = "Sorry, Defaults count exeeded";
export const PRODUCT_SINGLE_VARIANT_MANDATORY_UPDATED = "Variant mandatory updated successfully";
export const PRODUCT_SINGLE_VARIANT_MANDATORY_COUNT_FAILED = "Sorry, Mandatory count exeeded";

// masterdata >> store
export const STORE_CREATED = "Store created successfully";
export const STORE_UPDATED = "Store updated successfully";
export const STORE_DELETED = "Store deleted successfully";
export const STORE_INACTIVATED = "Store inactivated successfully";
export const STORE_ACTIVATED = "Store activated successfully";

// orders
export const ORDER_CREATED = "Order placed successfully";
export const ORDER_UPDATED = "Order updated successfully";
export const PRODUCT_ADDED_TO_ORDER = "Product successfully added to order";
export const PRODUCT_DELETED_FROM_ORDER = "Product deleted from order";
export const ORDER_CONFIRMED = "Order confirmed successfully";
export const ORDER_DELETED = "Order deleted successfully";
export const ORDER_PAID = "Payment received successfully";
export const ORDER_DELIVERED = "Order delivered successfully";
export const ORDER_INACTIVATED = "Order inactivated successfully";
export const ORDER_ACTIVATED = "Order activated successfully";
export const ORDER_ACCEPTED = "Order accepted successfully";
export const ORDER_REJECTED = "Order rejected successfully";
export const ORDER_CANCELLED = "Order cancelled successfully";
export const SELECT_DELIVERY_BOY = "Please select a delivery boy";
export const ORDER_SENT = "Order sent successfully";
export const CANNOT_DELIVER_WITHOUT_PAYMENT = "Order cannot be delivered before accepting payment";


// orders
export const BASIC_ORDER_CREATED = "Basic order created successfully";
export const BASIC_ORDER_UPDATED = "Basic order updated successfully";

//OTP
export const OTP_VALIDATED = "OTP validated successfully";


//Order Item
export const ITEM_ADDED = "Item added successfully";

// Customer 
export const CUSTOMER_CREATED = "Customer created successfully";
export const CUSTOMER_UPDATED = "Customer updated successfully";
export const CUSTOMER_DELETED = "Customer deleted successfully";
export const CUSTOMER_INACTIVATED = "Customer inactivated successfully";
export const CUSTOMER_ACTIVATED = "Customer activated successfully";
export const CUSTOMER_AVAILABLE = "Customer available";
export const CUSTOMER_NOT_AVAILABLE = "Customer not available";
export const CUSTOMER_CANT_BE_ACTIVATED = "Can't activate the user before validating the phone no.";

// masterdata >> coupon
export const COUPON_CREATED = "Coupon created successfully";
export const COUPON_UPDATED = "Coupon updated successfully";
export const COUPON_DELETED = "Coupon deleted successfully";
export const COUPON_INACTIVATED = "Coupon inactivated successfully";
export const COUPON_ACTIVATED = "Coupon activated successfully";

// recommended products
export const RECOMMENDED_NOT_SELECTED = "Please Select a Product";
export const RECOMMENDED_CREATED = "Product Added To Recommended";
export const RECOMMENDED_UPDATED = "Product Sorting Updated";
export const RECOMMENDED_DELETED = "Product Removed From Recommended";