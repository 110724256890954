import React, { useEffect, useState } from 'react';

const TextInput = ({lable, readonly, reference, isMandatory, type, placeholder, value, onchange, isError, capitalize, error, children}) => {

    const [showError, setShowError] = useState(isError);
    const [errorTxt, setErrorTxt] = useState(error);
    const [valueTxt, setValueTxt] = useState(value);
    
    useEffect(() => {
        setShowError(isError);
        setErrorTxt(error);
    }, [isError]);

    useEffect(() => {
        setValueTxt(value);
    }, [value]);

    return (
        <div className="formStep">
            <div className="formStepLable">
                <p className="formStepLableTxt">
                    {lable}
                    {
                        isMandatory === true
                        ?
                            <span className="formStepLableTxtCheck">*</span>
                        :
                            <></>
                    }
                </p>
            </div>

            {/* <div className="formStepInput">
                {
                    type === "text"
                    ?
                        <input type="text" ref={reference} className={capitalize ? "formStepInputTxt capitalize" : "formStepInputTxt"} value={valueTxt} placeholder={placeholder} onChange={(e) => onchange(e.target.value)} readOnly={readonly} />
                    :
                    type === "number"
                    ?
                        <input type="tel" ref={reference} className={capitalize ? "formStepInputTxt capitalize" : "formStepInputTxt"} value={valueTxt} placeholder={placeholder} onChange={(e) => onchange(e.target.value)} readOnly={readonly} />
                    :
                    type === "email"
                    ?
                        <input type="email" ref={reference} className={capitalize ? "formStepInputTxt emailTxt capitalize" : "formStepInputTxt emailTxt"} value={valueTxt} placeholder={placeholder} onChange={(e) => onchange(e.target.value)} readOnly={readonly} />
                    :
                    type === "password"
                    ?
                        <input type="password" ref={reference} className={capitalize ? "formStepInputTxt passwordTxt capitalize" : "formStepInputTxt passwordTxt"} value={valueTxt} placeholder={placeholder} onChange={(e) => onchange(e.target.value)} readOnly={readonly} />
                    :
                        <input type="text" ref={reference} className={capitalize ? "formStepInputTxt capitalize" : "formStepInputTxt"} value={valueTxt} placeholder={placeholder} onChange={(e) => onchange(e.target.value)} readOnly={readonly} />
                }
            </div> */}

            <div className="formStepInput">{children}</div>

            {
                showError === true
                ?
                    <div className="formStepError">
                        <p className="formStepErrorTxt">{errorTxt}</p>
                    </div>
                :
                    <></>
            }
            
        </div>
    );
};

export default TextInput;
