import React from 'react';
import { BRAND_NAME, AUTHOR_NAME } from '../../config';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footerCopyright">
                <p className="footerCopyrightTxt">copyright &copy; 2022, all rights reserved {BRAND_NAME}.</p>
            </div>
            <div className="footerPowered">
                <p className="footerPoweredTxt">powered by: {AUTHOR_NAME}.</p>
            </div>
        </div>
    );
};

export default Footer;
