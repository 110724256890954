import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Heading from '../../../../../components/dashboard/common/table/Heading';
import HeadingItem from '../../../../../components/dashboard/common/table/HeadingItem';
import RowItem from '../../../../../components/dashboard/common/table/RowItem';
import Table from '../../../../../components/dashboard/common/table/Table';
import TableButton from '../../../../../components/dashboard/common/table/TableButton';
import TableRow from '../../../../../components/dashboard/common/table/TableRow';
import DetailSection from '../../../../../components/dashboard/common/detailSection/DetailSection';
import DetailSectionContent from '../../../../../components/dashboard/common/detailSection/DetailSectionContent';
import DetailSectionRow from '../../../../../components/dashboard/common/detailSection/DetailSectionRow';
import UpdateProductVariantTypePopUp from '../../../../../components/dashboard/popups/UpdateProductVariantTypePopUp';
import DisplayProductVariantsPopUp from '../../../../../components/dashboard/popups/DisplayProductVariantsPopUp';
import NoDataContainer from '../../../../../components/general/containers/NoDataContainer';
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import { API_BASE_URL, LOGGER } from '../../../../../config';
import { PRODUCT_VARIANT_CREATION_FAILED, PRODUCT_VARIANT_DELETED, REQUEST_ERROR } from '../../../../../utils/messages';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';
import UpdateProductSizePricePopUp from '../../../../../components/dashboard/popups/UpdateProductSizePricePopUp';

const ProductDetails = () => {
    let { id } = useParams(); 
    console.log(id);
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const [product, setProduct] = useState();
    const [showProductDetails, setShowProductDetails] = useState(false);
    const [crusts, setCrusts] = useState([]);
    const [showCrusts, setShowCrusts] = useState(false);

    const getThisProduct = async (product_id) => {
        try{
            const getThisProductReq = await axios.get(`${API_BASE_URL}product/auth/${product_id}`, config);
            if(getThisProductReq.status === 200){
                setProduct(getThisProductReq.data);
                setShowProductDetails(true);
                console.log(getThisProductReq.data);
                if(getThisProductReq.data.category.name === "pizza"){
                    getCrusts();
                }
                else{
                    setCrusts([]);
                    setShowCrusts(false);
                }
            }
            else{
                notifyError(REQUEST_ERROR);
                setShowProductDetails(false);
            }
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            setShowProductDetails(false);
            LOGGER && console.log(error);
        }
    }
    
    useEffect(() => {
        getThisProduct(id);
    }, []);

    useEffect(() => {
        console.log(product);
    }, [product]);

    const getCrusts = async () => {
        try{
            const getCrustsReq = await axios.get(`${API_BASE_URL}pizza-crust/auth?sort=1`, config);
            if(getCrustsReq.status === 200){
                setCrusts(getCrustsReq.data.items);
                setShowCrusts(true);
                console.log(getCrustsReq.data);
            }
            else{
                notifyError(REQUEST_ERROR);
            }
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER && console.log(error);
        }
    }

    const [hideUpdateProductSizePricePopUp, setHideUpdateProductSizePricePopUp] = useState(true);
    const [sizeToEdit, setSizeToEdit] = useState();
    const [crustToEdit, setCrustToEdit] = useState();

    const showUpdateProductSizePricePopUpHandler = (size, crust) => {
        setSizeToEdit(size);
        setCrustToEdit(crust);

        setHideUpdateProductSizePricePopUp(false);
    }

    const closeUpdateProductSizePricePopUp = () => {
        setHideUpdateProductSizePricePopUp(true);
    }

    const [updateThisVariantType, setUpdateThisVariantType] = useState(0);
    const [hideUpdateVariantTypePopUp, setHideUpdateVariantTypePopUp] = useState(true);

    const showUpdateVariantTypePopUp = (variant_id) => {
        setUpdateThisVariantType(variant_id);
        setHideUpdateVariantTypePopUp(false);
    }

    const closeUpdateVariantTypePopUp = () => {
        setHideUpdateVariantTypePopUp(true);
    }

    const getAddedVariantTypeData = (data) => {
        console.log(data);
        if (data.status === 201) {
            getThisProduct(data.data.product);
            setHideUpdateVariantTypePopUp(true);
        }
        else{
            notifyError(PRODUCT_VARIANT_CREATION_FAILED)
            setHideUpdateVariantTypePopUp(true);
        }
    }

    const deleteThisVariantType = async (variant_type_id) => {
        const data = {}
    
        try {
            const deleteProductVariantTypeReq = await axios.post(`${API_BASE_URL}product/${product.id}/variant_type/${variant_type_id}/delete`, data, config);
            notifySuccess(PRODUCT_VARIANT_DELETED);
            getThisProduct(id);
            LOGGER &&  console.log(deleteProductVariantTypeReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const [updateThisVariant, setUpdateThisVariant] = useState(0);
    const [hideUpdateVariantPopUp, setHideUpdateVariantPopUp] = useState(true);

    const showUpdateVariantPopUp = (variant_id) => {
        setUpdateThisVariant(variant_id);
        console.log(variant_id);
        setHideUpdateVariantPopUp(false);
    }

    const getAddedVariantData = () => {

    }

    const closeUpdateVariantPopUp = () => {
        setHideUpdateVariantPopUp(true);
        getThisProduct(id);
    }

    const concludeUpdateProductSizePricePopUp = (status) => {
        setHideUpdateProductSizePricePopUp(true);
        if(status === true){
            getThisProduct(id);
        }
    }

    const updateProduct = (status) => {
        if(status === true){
            getThisProduct(id);
            console.log(true);
        }
    }

    return (
        <>
            <Section heading="product details" description={routeText} action={() => navigate("/dashboard/masterdata/products/create")} actionLable="create new product" showAction={true}>
                <PlainContainer type="fullwidth">
                    {
                        showProductDetails 
                        ?
                        <>
                            <DetailSection lable="basic details" hasPrimaryAction={true} lablePrimaryAction={`edit`} primaryAction={() => navigate(`/dashboard/masterdata/products/edit/${id}`)} hasSecondaryAction={false}>
                                <DetailSectionContent hasPadding={true}>
                                    <DetailSectionRow lable={`name`} value={product.name} isImage={false} isIcon={false} hasChildren={false} />
                                    <DetailSectionRow lable={`shortcode`} value={product.shortcode} isImage={false} isIcon={false} hasChildren={false} />
                                    <DetailSectionRow lable={`category`} value={product.category.name} isImage={false} isIcon={false} hasChildren={false} />
                                    {
                                        product.food_type === "VEG"
                                        ?
                                        <DetailSectionRow lable={`food type`} value={`Vegetarian`} isImage={false} isIcon={false} hasChildren={false} />
                                        :
                                        product.food_type === "EGG"
                                        ?
                                        <DetailSectionRow lable={`food type`} value={`Egg`} isImage={false} isIcon={false} hasChildren={false} />
                                        :
                                        <DetailSectionRow lable={`food type`} value={`Non Vegetarian`} isImage={false} isIcon={false} hasChildren={false} />
                                    }
                                    {
                                        product.service_type === "BOTH"
                                        ?
                                        <>
                                            <DetailSectionRow lable={`Available at`} value={`Store & Online`} isImage={false} isIcon={false} hasChildren={false} />
                                            <DetailSectionRow lable={`store price`} value={product.price.final.store} isImage={false} isIcon={false} hasChildren={false} />
                                            <DetailSectionRow lable={`online price`} value={product.price.final.online} isImage={false} isIcon={false} hasChildren={false} />
                                        </>
                                        :
                                        product.service_type === "STORE"
                                        ?
                                        <>
                                            <DetailSectionRow lable={`Available at`} value={`Store`} isImage={false} isIcon={false} hasChildren={false} />
                                            <DetailSectionRow lable={`store price`} value={product.price.final.store} isImage={false} isIcon={false} hasChildren={false} />
                                        </>
                                        :
                                        product.service_type === "ONLINE"
                                        ?
                                        <>
                                            <DetailSectionRow lable={`Available at`} value={`Online`} isImage={false} isIcon={false} hasChildren={false} />
                                            <DetailSectionRow lable={`online price`} value={product.price.final.online} isImage={false} isIcon={false} hasChildren={false} />
                                        </>
                                        :
                                        <></>
                                    }
                                    <DetailSectionRow lable={`description`} value={product.description} isImage={false} isIcon={false} hasChildren={false} />
                                    <DetailSectionRow lable={`small image`} value={product.small_image?.url} isImage={true} isIcon={false} hasChildren={false} />
                                    <DetailSectionRow lable={`large image`} value={product.large_image?.url} isImage={true} isIcon={false} hasChildren={false} />
                                </DetailSectionContent>
                            </DetailSection>
                        </>
                        :
                        <></>
                    }
                </PlainContainer>

                {
                    showCrusts
                    ?
                        <PlainContainer type="fullwidth" styles={{marginTop: "25px"}}>
                            <DetailSection lable="sizes & crusts" hasPrimaryAction={false} lablePrimaryAction={`add more`} primaryAction={() => console.log(0)} hasSecondaryAction={false}>
                                <DetailSectionContent hasPadding={true}>
                                    <Table>
                                        <Heading>
                                            <HeadingItem size="3" isIcon={false} icon="" lable={`sizes`} />
                                            {
                                                crusts.map((crust) => {
                                                    return (
                                                        <HeadingItem key={crust.id} size="2" isIcon={false} icon="" lable={crust.name} />
                                                    )
                                                })
                                            }
                                        </Heading>
                                        <PlainContainer styles={{width: '100%'}}>
                                            {
                                                product.sizes.map((size) => {
                                                    return(
                                                        <TableRow key={size.id}>
                                                            <RowItem size="3" lable={size.pizza_size.name} isCenter={false} hasChildren={false}></RowItem>
                                                            {
                                                                size.crusts.map((crust) => {
                                                                    return (
                                                                        <RowItem key={crust.id} size="2" lable={``} isCenter={false} hasChildren={true}>
                                                                            {/* <p className='tableContentTxt'>Store: {crust.price.base.store}, Online: {crust.price.base.online}</p> */}
                                                                            <div className="pizzaCrustPrice">
                                                                                <div className="pizzaCrustPriceContent">
                                                                                    <div className="pizzaCrustPriceContentSingle">
                                                                                        <div className="pizzaCrustPriceContentSingleIcon">
                                                                                            <i className="fa-solid fa-store pizzaCrustPriceContentSingleIco"></i>
                                                                                        </div>
                                                                                        <div className="pizzaCrustPriceContentSingleText">
                                                                                            <p className="pizzaCrustPriceContentSingleTxt">
                                                                                                <span className="tableContentTxt">{crust.price.base.store}</span> 
                                                                                                <i className="fa-solid fa-indian-rupee-sign"></i>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="pizzaCrustPriceContentSingle">
                                                                                        <div className="pizzaCrustPriceContentSingleIcon">
                                                                                            <i className="fa-solid fa-globe pizzaCrustPriceContentSingleIco"></i>
                                                                                        </div>
                                                                                        <div className="pizzaCrustPriceContentSingleText">
                                                                                            <p className="pizzaCrustPriceContentSingleTxt">
                                                                                                <span className="tableContentTxt">{crust.price.base.online}</span> 
                                                                                                <i className="fa-solid fa-indian-rupee-sign"></i>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="pizzaCrustPriceAction">
                                                                                    <div className="pizzaCrustPriceActionButton" onClick={() => showUpdateProductSizePricePopUpHandler(size, crust)}>
                                                                                        <i className="fa-solid fa-pen pizzaCrustPriceActionButtonIco"></i>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </RowItem>
                                                                    )
                                                                })
                                                            }
                                                            
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                            
                                        </PlainContainer>
                                    </Table>
                                </DetailSectionContent>
                            </DetailSection>
                        </PlainContainer>
                    :
                        <></>
                }

                

                <PlainContainer type="fullwidth" styles={{marginTop: "25px"}}>
                    <DetailSection lable="variants" hasPrimaryAction={true} lablePrimaryAction={`add more`} primaryAction={() => showUpdateVariantTypePopUp(0)} hasSecondaryAction={false}>
                        {
                            showProductDetails 
                            ?
                            <>
                                <DetailSectionContent hasPadding={true}>
                                    {
                                        product.variants.length === 0
                                        ?
                                        <NoDataContainer width={`100%`} icon={`fa-regular fa-face-meh`} heading={`no variant types found`} description={`it seems like you haven't added any variant type yet. Please click on the below button to add a variant type.`} isPrimary={true} primaryType={`button`} primaryLable={`add variant type`} primaryAction={() => showUpdateVariantTypePopUp(0)} />
                                        :
                                        <Table>
                                            <Heading>
                                                <HeadingItem size="1" isIcon={false} icon="" lable={`sl no`} />
                                                <HeadingItem size="3" isIcon={false} icon="" lable={`name`} />
                                                <HeadingItem size="2" isIcon={false} icon="" lable={`min`} />
                                                <HeadingItem size="2" isIcon={false} icon="" lable={`max`} />
                                                <HeadingItem size="2" isIcon={false} icon="" lable={`variants`} />
                                                <HeadingItem size="3" isIcon={false} icon="" lable={`action`} />
                                            </Heading>
                                            <PlainContainer styles={{width: '100%'}}>
                                                {
                                                    product.variants.map((variant, i) => {
                                                        return (
                                                            <TableRow key={variant.id}>
                                                                <RowItem size="1" lable={i+1} isCenter={true} hasChildren={false}></RowItem>
                                                                <RowItem size="3" lable={variant.lable} isCenter={false} hasChildren={false}></RowItem>
                                                                <RowItem size="2" lable={variant.min_selection} isCenter={true} hasChildren={false}></RowItem>
                                                                <RowItem size="2" lable={variant.max_selection} isCenter={true} hasChildren={false}></RowItem>
                                                                <RowItem size="2" lable={0} isCenter={true} hasChildren={true}>
                                                                    <PlainContainer styles={{display: 'flex', justifyContent: 'center'}} type={`fullwidth`}>
                                                                        <TableButton styles={{width: '30px', height: '30px', borderRadius: '15px'}} action={() => showUpdateVariantPopUp(variant.id)} type="primary">{variant.types.length}</TableButton>
                                                                    </PlainContainer>
                                                                </RowItem>
                                                                <RowItem size="3" lable="3" isCenter={false} hasChildren={true}>
                                                                    <TableButton styles={{width: 'calc(50% - 5px)', marginRight: '10px'}} action={() => showUpdateVariantTypePopUp(variant.id)} type="primary">edit</TableButton>
                                                                    <TableButton styles={{width: 'calc(50% - 5px)'}} action={() => deleteThisVariantType(variant.id)} type="danger">delete</TableButton>
                                                                </RowItem>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </PlainContainer>
                                        </Table>
                                    }                            
                                </DetailSectionContent>
                            </>
                            :
                            <></>
                        }
                    </DetailSection>
                </PlainContainer>
            </Section>

            {
                hideUpdateProductSizePricePopUp === false
                ?
                    <UpdateProductSizePricePopUp 
                        lable="Update Product Price" 
                        product={product.id} 
                        size={sizeToEdit} 
                        crust={crustToEdit} 
                        primaryLable="submit" 
                        primaryAction={(e) => getAddedVariantTypeData(e)} 
                        secondaryType="danger" 
                        secondaryLable="close" 
                        secondaryAction={() => closeUpdateProductSizePricePopUp()} 
                        concludeProductSizePricePopUp={(status) => concludeUpdateProductSizePricePopUp(status)}
                        updateProduct={(status) => updateProduct(status)}
                     />
                :
                    <></>
            }

            {
                hideUpdateVariantTypePopUp === false
                ?
                    <UpdateProductVariantTypePopUp 
                        lable="Update Variant Type" 
                        product={product} 
                        variant_type_id={updateThisVariantType} 
                        primaryLable="submit" 
                        primaryAction={(e) => getAddedVariantTypeData(e)} 
                        secondaryType="danger" 
                        secondaryLable="cancel" 
                        secondaryAction={() => closeUpdateVariantTypePopUp()} 
                        getAddedVariantTypeData={(e) => getAddedVariantTypeData(e)} 
                    />
                :
                    <></>
            }

            {
                hideUpdateVariantPopUp === false
                ?
                    <DisplayProductVariantsPopUp 
                        lable="Variants" 
                        product={product} 
                        variant_type_id={updateThisVariant} 
                        primaryLable="submit" 
                        primaryAction={(e) => getAddedVariantData(e)} 
                        secondaryType="danger" 
                        secondaryLable="cancel" 
                        secondaryAction={() => closeUpdateVariantPopUp()} 
                        getAddedVariantTypeData={(e) => getAddedVariantData(e)} 
                    />
                :
                    <></>
            }
        </>
    )
}

export default ProductDetails