import React from 'react';
import Button from '../../general/forms/Button';
import Text from '../../general/typography/Text';

const PopUp = ({lable, isPrimary = true, primaryLable, primaryAction, secondaryLable, isSecondary, secondaryAction, secondaryType, children}) => {
    return (
        <>
            <div className="popUp center">
                <div className="popUpMain">
                    <div className="popUpLable center">
                        <Text variant="popup-lable" type="default">{lable}</Text>
                        {
                            isPrimary == false && isSecondary == false
                            ?
                                <div className="popUpHeaderClose" onClick={secondaryAction}>
                                    <i className="fa-regular fa-circle-xmark popUpHeaderCloseIco"></i>
                                </div>
                            :
                            <></>
                        }
                    </div>
                    <div className="popUpContent">
                        {children}
                    </div>
                    {
                        isPrimary != false && isSecondary != false
                        ?
                        <div className="popUpActions center">
                            <div className="popUpActionsInner">

                                {
                                    isSecondary != false
                                    ?
                                        <Button action={secondaryAction} styles={isPrimary ? {marginRight: '20px'} : {}} type={secondaryType}>{secondaryLable}</Button>
                                    :
                                        <></>
                                }

                                {
                                    isPrimary != false
                                    ?
                                        <Button action={primaryAction} type="primary">{primaryLable}</Button>
                                    :
                                        <></>
                                }
                            </div>
                        </div>
                        :
                        <></>
                    }
                    
                </div>
                <div className="popUpBackground">&nbsp;</div>
            </div>
        </>
    )
}

export default PopUp