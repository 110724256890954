import React from 'react'
import Button from '../forms/Button'

const NoDataContainer = ({width, icon, heading, description, isPrimary, primaryType, primaryLable, primaryAction, isSecondary, secondaryType, secondaryLable, secondaryAction, styles}) => {
    return (
        <div className="noDataFound">
            <div className="noDataFoundMain" style={styles}>
                <div className="noDataFoundInner">
                    <div className="noDataFoundVisual">
                        <div className="noDataFoundIcon">
                            <div className="noDataFoundIconInner">
                                <i className={`${icon} noDataFoundIco`}></i>
                            </div>
                        </div>
                    </div>
                    <div className="noDataFoundContent">
                        <div className="noDataFoundContentHeading">
                            <p className="noDataFoundContentHeadingTxt">{heading}</p>
                        </div>
                        <div className="noDataFoundContentDescription">
                            <p className="noDataFoundContentDescriptionTxt">{description}</p>
                        </div>
                    </div>
                    <div className="noDataFoundAction">
                        <div className="noDataFoundActionInner">
                            {
                                isPrimary === true
                                ?
                                <div className="noDataFoundActionSingle">
                                    <Button type={primaryType} action={primaryAction}>{primaryLable}</Button>
                                </div>
                                :
                                <></>
                            }
                            {
                                isSecondary === true
                                ?
                                <div className="noDataFoundActionSingle">
                                    <Button type={secondaryType} action={secondaryAction}>{secondaryLable}</Button>
                                </div>
                                :
                                <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoDataContainer