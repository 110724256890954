import React, { useEffect, useState } from 'react';

import PlainContainer from '../../general/containers/PlainContainer';
import Text from '../../general/typography/Text';
import PopUp from './PopUp';

import { useSelector } from 'react-redux';
import axios from 'axios';
import { API_BASE_URL, LOGGER } from '../../../config';
import TextInput from '../../general/forms/TextInput';
import { notifyError, notifySuccess } from '../../../utils/toastify';
import { PRODUCT_VARIANT_CREATED, PRODUCT_VARIANT_UPDATED, REQUEST_ERROR } from '../../../utils/messages';

const UpdateProductVariantTypePopUp = ({lable, primaryLable, primaryAction, secondaryLable, secondaryAction, secondaryType, getAddedVariantTypeData, product, variant_type_id}) => {
    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    console.log(variant_type_id);

    const getThisVariantType = async (data) => {
        if(data != 0){
            try {
                const getThisVariantType = await axios.get(`${API_BASE_URL}product/variant_type?slug=${product.seo.slug}&variant_type=${data}`, config);
                LOGGER &&  console.log(getThisVariantType);
                if(getThisVariantType.data){
                    setName(getThisVariantType.data.lable);
                    setMinSelection(getThisVariantType.data.min_selection);
                    setMaxSelection(getThisVariantType.data.max_selection);
                }
            }
            catch (error) {
                notifyError(REQUEST_ERROR);
                LOGGER &&  console.log(error);
            }
        }
    }

    useEffect(() => {
        if(variant_type_id != 0){
            getThisVariantType(variant_type_id);
        }
    }, []);

    const [name, setName] = useState('');
    const [isNameError, setIsNameError] = useState(false);
    const [nameErrorTxt, setNameErrorTxt] = useState('');

    const [maxSelection, setMaxSelection] = useState('0');
    const [isMaxSelectionError, setIsMaxSelectionError] = useState(false);
    const [maxSelectionErrorTxt, setMaxSelectionErrorTxt] = useState('');

    const [minSelection, setMinSelection] = useState('0');
    const [isMinSelectionError, setIsMinSelectionError] = useState(false);
    const [minSelectionErrorTxt, setMinSelectionErrorTxt] = useState('');

    const checkAndSetMinSelection = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setMinSelection(value)
    }

    const checkAndSetMaxSelection = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setMaxSelection(value)
    }

    const addVariantType = async (e) => {
        e.preventDefault();

        if(name == ''){
            setNameErrorTxt('Name is required');
            setIsNameError(true);
            return;
        }
        else{
            setNameErrorTxt('');
            setIsNameError(false);
        }

        if(minSelection == ''){
            setMinSelectionErrorTxt('Min selection is required');
            setIsMinSelectionError(true);
            return;
        }
        else{
            setMinSelectionErrorTxt('');
            setIsMinSelectionError(false);
        }

        if(maxSelection == ''){
            setMaxSelectionErrorTxt('Max selection is required');
            setIsMaxSelectionError(true);
            return;
        }
        else{
            setMaxSelectionErrorTxt('');
            setIsMaxSelectionError(false);
        }

        const variantType = {
            lable: name,
            min_selection: minSelection,
            max_selection: maxSelection
        }
        console.log(variantType);

        if(variant_type_id === 0){
            try {
                const createProductVariantTypeReq = await axios.post(`${API_BASE_URL}product/variant_type/${product.id}/add`, variantType, config);
                notifySuccess(PRODUCT_VARIANT_CREATED);
                getAddedVariantTypeData(createProductVariantTypeReq);
                LOGGER &&  console.log(createProductVariantTypeReq);
            }
            catch (error) {
                notifyError(REQUEST_ERROR);
                LOGGER &&  console.log(error);
            }
        }
        else{
            try {
                const updateProductVariantTypeReq = await axios.post(`${API_BASE_URL}product/${product.id}/variant_type/${variant_type_id}/update`, variantType, config);
                notifySuccess(PRODUCT_VARIANT_UPDATED);
                getAddedVariantTypeData(updateProductVariantTypeReq);
                LOGGER &&  console.log(updateProductVariantTypeReq);
            }
            catch (error) {
                notifyError(REQUEST_ERROR);
                LOGGER &&  console.log(error);
            }
        }
    }

    return (
        <PopUp lable={lable} primaryLable={primaryLable} primaryAction={addVariantType} secondaryLable={secondaryLable} secondaryAction={secondaryAction} secondaryType={secondaryType}>
            <PlainContainer styles={{display: 'flex', flexDirection: 'column', position: 'static'}} type={`fullwidth`}>
                <TextInput lable="variant type" capitalize={true} isMandatory={true} isError={isNameError} error={nameErrorTxt}>
                    <input type="text" className="formStepInputTxt capitalize" value={name} placeholder="Please Enter The Variant Type" onChange={(e) => setName(e.target.value)} />
                </TextInput>
                <TextInput lable="min selection" capitalize={true} isMandatory={true} isError={isMinSelectionError} error={minSelectionErrorTxt}>
                    <input type="tel" maxLength={2} className="formStepInputTxt capitalize" value={minSelection} placeholder="Please Enter The Min Selection" onChange={(e) => checkAndSetMinSelection(e)} />
                </TextInput>
                <TextInput lable="max selection" capitalize={true} isMandatory={true} isError={isMaxSelectionError} error={maxSelectionErrorTxt}>
                    <input type="tel" maxLength={2} className="formStepInputTxt capitalize" value={maxSelection} placeholder="Please Enter The Max Selection" onChange={(e) => checkAndSetMaxSelection(e)} />
                </TextInput>
            </PlainContainer>
        </PopUp>
    )
}

export default UpdateProductVariantTypePopUp