import React from 'react'

const SplitContainer = ({type, styles, children}) => {
    return (
        <div className={type === 'fullwidth' ? "splitContainer fullWidth" : "splitContainer"} style={styles}>
            {children}
        </div>    
    )
}

export default SplitContainer