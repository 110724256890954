import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import verifyToken from './verify-jwt';
import { ROLES, LOGGER } from './config';

import RootOutlet from './pages/dashboard/rootOutlet';
import DefaultOutlet from './components/general/DefaultOutlet';

import Login from './pages/login';
import Home from './pages/dashboard/home';
import Categories from './pages/dashboard/masterdata/categories/Categories';
import AddCategory from './pages/dashboard/masterdata/categories/add/AddCategory';
import PizzaSizes from './pages/dashboard/masterdata/pizzaSizes/PizzaSizes';
import AddPizzaSize from './pages/dashboard/masterdata/pizzaSizes/add/AddPizzaSize';
import PizzaCrusts from './pages/dashboard/masterdata/pizzaCrusts/PizzaCrusts';
import AddPizzaCrust from './pages/dashboard/masterdata/pizzaCrusts/add/AddPizzaCrust';
import Products from './pages/dashboard/masterdata/products/Products';
import AddProduct from './pages/dashboard/masterdata/products/add/AddProduct';
import ProductDetails from './pages/dashboard/masterdata/products/details/ProductDetails';
import Stores from './pages/dashboard/masterdata/stores/Stores';
import AddStore from './pages/dashboard/masterdata/stores/add/AddStore';
import CreateBasicOrder from './pages/dashboard/orders/create/basic/CreateBasicOrder';
import AddProductToOrder from './pages/dashboard/orders/create/addproducts/AddProductToOrder';
import EditCategory from './pages/dashboard/masterdata/categories/edit/EditCategory';
import EditPizzaSize from './pages/dashboard/masterdata/pizzaSizes/edit/EditPizzaSize';
import EditPizzaCrust from './pages/dashboard/masterdata/pizzaCrusts/edit/EditPizzaCrust';
import EditProduct from './pages/dashboard/masterdata/products/edit/EditProduct';
import EditStore from './pages/dashboard/masterdata/stores/edit/EditStore';
import Coupons from './pages/dashboard/masterdata/coupons/Coupons';
import AddCoupon from './pages/dashboard/masterdata/coupons/add/AddCoupon';
import RecommendedProducts from './pages/dashboard/masterdata/recommended-products/RecommendedProduct';
import AddRecommendedProduct from './pages/dashboard/masterdata/recommended-products/add/AddRecommendedProduct';

const AppRouter = () => {

    const [auth, setAuth] = useState(null);
  
    const admin = useSelector((state) => state.admin.value);
    
    const isAdmin = ROLES.ADMIN === admin.role;
    const isSuperAdmin = ROLES.SUPER_ADMIN === admin.role;
  
    useEffect(() => {
      async function checkIsLoggedIn() {
        if(admin.is_logged_in === true){
          const checkToken = await verifyToken(admin.access_token);
          setAuth(checkToken.status);
        }
        else{
          setAuth(false);
        }
      }
      checkIsLoggedIn();
    }, [admin]);
  
    return (
        <>
            {
                auth != null
                ?
                <Routes>
                {!auth && (
                    <Route path="/" element={<Login />} />
                )}
                {auth && (
                    <>
                    <Route path="/" element={<Navigate to={"/dashboard"} />} />
                    <Route path="/dashboard" element={<RootOutlet />}>
						          <Route index element={<Home />} />
                      <Route path="masterdata" element={<DefaultOutlet />} >
                        <Route path="categories" element={<DefaultOutlet />}>
                          <Route index element={<Categories />} />
                          <Route path='create' element={<AddCategory />} />
                          <Route path='edit/:id' element={<EditCategory />} />
                          {/* <Route path='details/:id' element={<BlogCategoryDetails />} /> */}
                          {/* <Route path='edit/seo/:id' element={<EditBlogCategorySEO />} /> */}
                        </Route>
                        <Route path="pizza-sizes" element={<DefaultOutlet />}>
                          <Route index element={<PizzaSizes />} />
                          <Route path='create' element={<AddPizzaSize />} />
                          <Route path='edit/:id' element={<EditPizzaSize />} />
                          {/* <Route path='details/:id' element={<BlogCategoryDetails />} /> */}
                          {/* <Route path='edit/seo/:id' element={<EditBlogCategorySEO />} /> */}
                        </Route>
                        <Route path="pizza-crusts" element={<DefaultOutlet />}>
                          <Route index element={<PizzaCrusts />} />
                          <Route path='create' element={<AddPizzaCrust />} />
                          <Route path='edit/:id' element={<EditPizzaCrust />} />
                          {/* <Route path='details/:id' element={<BlogCategoryDetails />} /> */}
                          {/* <Route path='edit/seo/:id' element={<EditBlogCategorySEO />} /> */}
                        </Route>
                        <Route path="products" element={<DefaultOutlet />}>
                          <Route index element={<Products />} />
                          <Route path='create' element={<AddProduct />} />
                          <Route path='edit/:id' element={<EditProduct />} />
                          <Route path='details/:id' element={<ProductDetails />} />
                          {/* <Route path='edit/seo/:id' element={<EditBlogCategorySEO />} /> */}
                        </Route>
                        <Route path="stores" element={<DefaultOutlet />}>
                          <Route index element={<Stores />} />
                          <Route path='create' element={<AddStore />} />
                          <Route path='edit/:id' element={<EditStore />} />
                          {/* <Route path='details/:id' element={<BlogCategoryDetails />} /> */}
                          {/* <Route path='edit/seo/:id' element={<EditBlogCategorySEO />} /> */}
                        </Route>
                        <Route path="coupons" element={<DefaultOutlet />}>
                          <Route index element={<Coupons />} />
                          <Route path='create' element={<AddCoupon />} />
                        </Route>
                        <Route path="recommended-products" element={<DefaultOutlet />}>
                          <Route index element={<RecommendedProducts />} />
                          <Route path='create' element={<AddRecommendedProduct />} />
                        </Route>
                      </Route>
                      <Route path="orders" element={<DefaultOutlet />}>
                        <Route path='create' element={<DefaultOutlet />}>
                          <Route index element={<CreateBasicOrder />} />
                          <Route path='addproduct/:id' element={<AddProductToOrder />} />
                          {/* <Route path='summary/:id' element={<CreateOrderSummary />} /> */}
                        </Route>
                        {/* <Route path='store-orders' element={<DefaultOutlet />}>
                          <Route index element={<StoreOrders />} />
                          <Route path='details/:id' element={<StoreOrderDetails />} />
                        </Route> */}
                      </Route>
                    </Route>
                    </>
                )}
                {/* <Route path="/404" element={<div>404</div>} /> */}
                <Route path="*" element={<Navigate to={auth ? "/dashboard" : "/"} />} />
                </Routes>
                :
                <></>
            }
        </>
    );
  };
  
  export default AppRouter;
  