import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Heading from '../../../../components/dashboard/common/table/Heading';
import HeadingItem from '../../../../components/dashboard/common/table/HeadingItem';
import RowItem from '../../../../components/dashboard/common/table/RowItem';
import Table from '../../../../components/dashboard/common/table/Table';
import TableButton from '../../../../components/dashboard/common/table/TableButton';
import TableRow from '../../../../components/dashboard/common/table/TableRow';
import RowImage from '../../../../components/dashboard/common/table/RowImage';
import RowIcon from '../../../../components/dashboard/common/table/RowIcon';
import Pagination from '../../../../components/general/Pagination';
import PlainContainer from '../../../../components/general/containers/PlainContainer';
import Section from '../../../../components/general/containers/Section';
import { REQUEST_ERROR } from '../../../../utils/messages';
import { notifyError, notifySuccess } from '../../../../utils/toastify';

import axios from 'axios';

import { API_BASE_URL, DEFAULT_PAGE_SIZE, FIRST_PAGE, LOGGER } from '../../../../config';

const Coupons = () => {
    
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    return (
        <Section heading="coupons" description={routeText} action={() => navigate("create")} actionLable="create" showAction={true}>
            
        </Section>
    );
};

export default Coupons;