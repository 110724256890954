import React, { useState, useEffect, useRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import Form from '../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../components/general/forms/core/FormActions';
import PlainStep from '../../../../../components/general/forms/PlainStep';
import TextInput from '../../../../../components/general/forms/TextInput';
import Autocomplete from '../../../../../components/general/forms/Autocomplete';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../config';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';
import AutoCompleteBubble from '../../../../../components/general/forms/AutoCompleteBubble';
import { RECOMMENDED_CREATED, RECOMMENDED_NOT_SELECTED, REQUEST_ERROR } from '../../../../../utils/messages';

const AddRecommendedProduct = () => {

    let navigate = useNavigate();
    const location = useLocation();
  
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
  
    useEffect(() => {
      let tempRoute = '';
      routeArray.map(route => {
        tempRoute = tempRoute.concat(`${route} / `);
        tempRoute = tempRoute.replace('-', ' ');
      });
      setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
      return str.substring(0, str.length - chars);
    }
  
    const admin = useSelector((state) => state.admin.value);
  
    const config = {
      headers: {
        'Authorization': admin.access_token
      }
    }

    const [name, setName] = useState('');
    const [autoCompleteProducts, setAutoCompleteProducts] = useState([]);
    const [showProductsAutocomplete, setShowProductsAutocomplete] = useState(false);
    const [showSelectedAutoCompleteProduct, setShowSelectedAutoCompleteProduct] = useState(false);
    const [selectedAutoCompleteProduct, setSelectedAutoCompleteProduct] = useState([]);

    const nameChanged = async (e) => {
        setName(e);
        console.log(e);
        if(e.length >= 3){
            try {
                const getSimilarProductsReq = await axios.get(`${API_BASE_URL}product/similar/${e}`, config);
                console.log(getSimilarProductsReq);
                if (getSimilarProductsReq.status === 200) {
                    setAutoCompleteProducts(getSimilarProductsReq.data);
                    setShowProductsAutocomplete(true);
                }
                else{
                    setAutoCompleteProducts([]);
                    setShowProductsAutocomplete(false);
                }
            }
            catch (error) {
                LOGGER && console.log(error);
                notifyError(REQUEST_ERROR);
                setAutoCompleteProducts([]);
                setShowProductsAutocomplete(false);
            }
        }
        else{
            setAutoCompleteProducts([]);
            setShowProductsAutocomplete(false);
        }
    }

    const resetForm = () => {
        console.log('reset');
    }

    const productAutoCompleteSelectionHandler = (product) => {
        console.log(product);
        const selectedProductArr = [];
        const selected_product = {
            id: product.id,
            shortcode: product.shortcode,
            name: product.name
        }
        setName('');
        selectedProductArr.push(selected_product);
        setSelectedAutoCompleteProduct(selectedProductArr);
        setShowSelectedAutoCompleteProduct(true);
        setShowProductsAutocomplete(false);
    }
    
    const deleteThisAutoCompleteProduct = (product) => {
        setSelectedAutoCompleteProduct([]);
        setShowSelectedAutoCompleteProduct(false);
    }

    const submitCreateRecommendedProductForm = async (e) => {
        e.preventDefault();
        console.log(selectedAutoCompleteProduct);
        if(selectedAutoCompleteProduct.length === 0){
            notifyError(RECOMMENDED_NOT_SELECTED);
        }
        else{
            const data = {
                product: selectedAutoCompleteProduct[0].id
            }
        
            LOGGER &&  console.log(data);

            try {
                const createRecProductReq = await axios.post(`${API_BASE_URL}recommended`, data, config);
                notifySuccess(RECOMMENDED_CREATED);
                navigate('/dashboard/masterdata/recommended-products');
                LOGGER &&  console.log(createRecProductReq);
            }
            catch (error) {
                notifyError(REQUEST_ERROR);
                LOGGER &&  console.log(error);
            }
        }
    }

    return (
        <Section heading="recommended products" description={routeText} action={() => navigate("/dashboard/masterdata/recommended-products")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitCreateRecommendedProductForm(e)} noValidate>
                    <Form>
                        <PlainStep>
                            <TextInput lable="name" capitalize={true} isMandatory={false} isError={false} error={``}>
                                <input type="text" className="formStepInputTxt capitalize" value={name} placeholder="Enter The Product Name" onChange={(e) => nameChanged(e.target.value)} />
                                {
                                    showProductsAutocomplete
                                    ?
                                    <>
                                        <Autocomplete items={autoCompleteProducts} action={(data)=>productAutoCompleteSelectionHandler(data)} />
                                    </>
                                    :
                                    <></>
                                }
                                {
                                    showSelectedAutoCompleteProduct
                                    ?
                                        <>
                                            <AutoCompleteBubble items={selectedAutoCompleteProduct} action={(data) => deleteThisAutoCompleteProduct(data)} />
                                        </>
                                    :
                                        <></>
                                }
                            </TextInput>
                        </PlainStep>
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default AddRecommendedProduct