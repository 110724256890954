import React from 'react'

const Autocomplete = ({items, action}) => {
    return (
        <div className="dashboardContentMainFormStepInputAutoComplete">
            {
                items.map(item => {
                    return (
                        <div className='dashboardContentMainFormStepInputAutoCompleteSingle' key={item.id} onClick={()=>action(item)}>
                            <p>{item.name}</p>
                        </div>
                    )
                })
            }
            
        </div>
    )
}

export default Autocomplete