import React from 'react';
import { Link, useLocation, useMatch } from 'react-router-dom';

const NavItem = ({ name, icon, goTo, showNav, active }) => {
    return (
        <>
            <Link to={goTo}>
                {
                    showNav
                    ?
                    <div className={active ? `dashboardNavSectionSingle active` : `dashboardNavSectionSingle`}>
                        <div className="dashboardNavSectionSingleIcon">
                            <i className={`${icon} dashboardNavSectionSingleIco`} />
                        </div>
                        <div className="dashboardNavSectionSingleContent">
                            <div className="dashboardNavSectionSingleText">
                                <p className="dashboardNavSectionSingleTxt">{name}</p>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={active ? `dashboardNavSectionSingle active` : `dashboardNavSectionSingle`}>
                        <div className="dashboardNavSectionSingleIcon noNav">
                            <i className={`${icon} dashboardNavSectionSingleIco`} />
                        </div>
                    </div>
                }
            </Link>
        </>
    );
};

export default NavItem;
