import React, { useEffect, useState } from 'react'

const SelectInput = ({lable, isMandatory, placeholder, value, onchange, isError, capitalize, error, options}) => {

    const [showError, setShowError] = useState(isError);
    const [errorTxt, setErrorTxt] = useState(error);
    const [valueTxt, setValueTxt] = useState(value);

    useEffect(() => {
        setShowError(isError);
        setErrorTxt(error);
    }, [isError]);

    useEffect(() => {
        setValueTxt(value);
    }, [value]);

    return (
        <div className="formStep">
            <div className="formStepLable">
                <p className="formStepLableTxt">
                    {lable}
                    {
                        isMandatory === true
                        ?
                            <span className="formStepLableTxtCheck">*</span>
                        :
                            <></>
                    }
                </p>
            </div>

            <div className="formStepInput">
                <select className={capitalize ? "formStepInputSelect capitalize" : "formStepInputSelect"} value={valueTxt} onChange={(e) => onchange(e.target.value)}>
                    <option value="0">{placeholder}</option>
                    {
                        options.map(option => {
                            return (
                                <option value={option.id} key={option.id}>{option.name}</option>
                            )
                        })
                    }
                </select>
            </div>

            {
                showError === true
                ?
                    <div className="formStepError">
                        <p className="formStepErrorTxt">{errorTxt}</p>
                    </div>
                :
                    <></>
            }
            
        </div>
    )
}

export default SelectInput