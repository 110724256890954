import React from 'react'

const TableButton = ({action, type, styles, children, disableClick }) => {

  let classContent = `tableButton ${type} ${disableClick === true ? `disableClick` : ``}`

  return (
    <>
        <button type="button" style={styles} onClick={action} className={classContent}>{children}</button>
    </>
  )
}

export default TableButton