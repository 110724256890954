import React from 'react'
import PlainContainer from '../../../general/containers/PlainContainer'
import Text from '../../../general/typography/Text'

const HeadingItem = ({size, lable, isIcon, icon}) => {
    return (
        <div className={`tableHeadingItem center col${size}`} style={{flex: size}}>
            {
                isIcon === false
                ?
                <Text variant="table-heading" type="default" color="#3d3d3d">{lable}</Text>
                :
                <i className={`tableHeadingItemIco ${icon}`}></i>
            }
        </div>
    )
}

export default HeadingItem