import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import Form from '../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../components/general/forms/core/FormActions';
import FileInput from '../../../../../components/general/forms/FileInput';
import PlainStep from '../../../../../components/general/forms/PlainStep';
import TextArea from '../../../../../components/general/forms/TextArea';
import TextInput from '../../../../../components/general/forms/TextInput';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, DEFAULT_IP_ADDRESS, LOGGER } from '../../../../../config';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';
import { PIZZA_SIZE_CREATED, REQUEST_ERROR } from '../../../../../utils/messages';
import SplitContainer from '../../../../../components/general/containers/SplitContainer';
import { getUserIpAddress } from '../../../../../utils/functions';

const AddPizzaSize = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [icon, setIcon] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [imageId, setImageId] = useState("");

    const [isNameError, setIsNameError] = useState(false);
    const [isDescriptionError, setIsDescriptionError] = useState(false);
    const [isIconError, setIsIconError] = useState(false);
    const [isImageError, setIsImageError] = useState(false);

    const [nameErrorTxt, setNameErrorTxt] = useState("");
    const [descriptionErrorTxt, setDescriptionErrorTxt] = useState("");
    const [iconErrorTxt, setIconErrorTxt] = useState("");
    const [imageErrorTxt, setImageErrorTxt] = useState("");

    const [resetAttachment, setResetAttachment] = useState(false);

    const [ipAddress, setIpAddress] = useState(DEFAULT_IP_ADDRESS);

    const setIpAddressHandler = async () => {
        const getIpAddressHandler = await getUserIpAddress();
        setIpAddress(getIpAddressHandler.ip);
    }

    useEffect(() => {
        setIpAddressHandler();
    }, []);

    const submitCreatePizzaSizeForm = async (e) => {
        e.preventDefault();

        if(name == ''){
            setNameErrorTxt('Name is required');
            setIsNameError(true);
            return;
        }
        else{
            setNameErrorTxt('');
            setIsNameError(false);
        }

        const data = {
            name: name,
            description: description,
            image: imageId,
            icon: icon,
            ip_address: ipAddress
        }

        LOGGER &&  console.log(data);

        try {
            const createPizzaSizeReq = await axios.post(`${API_BASE_URL}pizza-size`, data, config);
            notifySuccess(PIZZA_SIZE_CREATED);
            navigate('/dashboard/masterdata/pizza-sizes');
            LOGGER &&  console.log(createPizzaSizeReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {

        setName("");
        setDescription("");
        setIcon("");
        setImageUrl("");
        setImageId("");

        setIsNameError(false);
        setIsDescriptionError(false);
        setIsIconError(false);
        setIsImageError(false);

        setNameErrorTxt("");
        setDescriptionErrorTxt("");
        setIconErrorTxt("");
        setImageErrorTxt("");

        setResetAttachment(true)
    }

    return (
        <Section heading="pizza sizes" description={routeText} action={() => navigate("/dashboard/masterdata/pizza-sizes")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitCreatePizzaSizeForm(e)} noValidate>
                    <Form>
                        <TextInput lable="name" capitalize={true} isMandatory={true} isError={isNameError} error={nameErrorTxt}>
                            <input type="text" className="formStepInputTxt capitalize" value={name} placeholder="Please Enter The Size Name" onChange={(e) => setName(e.target.value)} />
                        </TextInput>
                        <TextArea lable="description" rows={3} isMandatory={false} placeholder="Please Enter The Description" value={description} onchange={(data) => setDescription(data)} isError={isDescriptionError} error={descriptionErrorTxt} />
                        <TextInput lable="icon" isMandatory={false} isError={isIconError} error={iconErrorTxt}>
                            <input type="text" className="formStepInputTxt capitalize" value={icon} placeholder="Please Enter The Size Icon" onChange={(e) => setIcon(e.target.value)} />
                        </TextInput>
                        <PlainStep>
                            <FileInput lable="image" isMandatory={false} value={imageId} preview={imageUrl} onchange={(data) => {setImageId(data); LOGGER &&  console.log(data)}} isError={isImageError} error={imageErrorTxt} reset={resetAttachment} />
                        </PlainStep>
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default AddPizzaSize