import React from 'react'
import PlainContainer from '../../../general/containers/PlainContainer'

const TableRow = ({children}) => {
    return (
        <div className="tableRow">
            {children}
        </div>
    )
}

export default TableRow