import React, { useEffect, useRef, useState } from 'react';

import PlainContainer from '../../general/containers/PlainContainer';
import Text from '../../general/typography/Text';
import PopUp from './PopUp';

import { useSelector } from 'react-redux';
import axios from 'axios';
import { API_BASE_URL, LOGGER } from '../../../config';
import TextInput from '../../general/forms/TextInput';
import { notifyError, notifySuccess } from '../../../utils/toastify';
import { PRODUCT_SINGLE_VARIANT_CREATED, PRODUCT_SINGLE_VARIANT_DEFAULT_COUNT_FAILED, PRODUCT_SINGLE_VARIANT_DEFAULT_UPDATED, PRODUCT_SINGLE_VARIANT_DELETED, PRODUCT_SINGLE_VARIANT_MANDATORY_COUNT_FAILED, PRODUCT_SINGLE_VARIANT_MANDATORY_UPDATED, PRODUCT_SINGLE_VARIANT_UPDATED, PRODUCT_VARIANT_CREATED, PRODUCT_VARIANT_UPDATED, REQUEST_ERROR } from '../../../utils/messages';
import NoDataContainer from '../../general/containers/NoDataContainer';
import SplitContainer from '../../general/containers/SplitContainer';
import RadioButtonGroup from '../../general/forms/RadioButtonGroup';
import RadioButtonInput from '../../general/forms/RadioButtonInput';
import Form from '../../general/forms/core/Form';
import FormActions from '../../general/forms/core/FormActions';

import Table from '../../../components/dashboard/common/table/Table';
import Heading from '../../../components/dashboard/common/table/Heading';
import HeadingItem from '../../../components/dashboard/common/table/HeadingItem';
import RowItem from '../../../components/dashboard/common/table/RowItem';
import TableButton from '../../../components/dashboard/common/table/TableButton';
import TableRow from '../../../components/dashboard/common/table/TableRow';
import Button from '../../general/forms/Button';

const DisplayProductVariantsPopUp = ({lable, primaryLable, primaryAction, secondaryLable, secondaryAction, secondaryType, getUpdatedVariantData, product, variant_type_id}) => {
    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const [variantType, setVariantType] = useState();

    const [showVariants, setShowVariants] = useState(false);
    const [showNoVariantsFound, setShowNoVariantsFound] = useState(false);
    const [showAddVariant, setShowAddVariant] = useState(false);
    const [showEditVariant, setShowEditVariant] = useState(false);

    const getThisVariantType = async (data) => {
        if(data != 0){
            try {
                const getThisVariantType = await axios.get(`${API_BASE_URL}product/variant_type?slug=${product.seo.slug}&variant_type=${data}`, config);
                LOGGER &&  console.log(getThisVariantType);
                if(getThisVariantType.data){
                    setVariantType(getThisVariantType.data);
                }
            }
            catch (error) {
                notifyError(REQUEST_ERROR);
                LOGGER &&  console.log(error);
            }
        }
    }

    useEffect(() => {
        if(variant_type_id != 0){
            getThisVariantType(variant_type_id);
        }
    }, []);

    console.log(variant_type_id);

    const [variants, setVariants] = useState([]);

    useEffect(() => {
        if(variantType != undefined){
            console.log(variantType);
            setVariants(variantType.types);
        }
    }, [variantType]);

    useEffect(() => {
        if(variants.length != 0){
            console.log(variants);
            setShowNoVariantsFound(false);
            setShowAddVariant(false);
            setShowEditVariant(false);
            setShowVariants(true);
        }
        else{
            console.log(variants);
            setShowVariants(false);
            setShowAddVariant(false);
            setShowEditVariant(false);
            setShowNoVariantsFound(true);
        }
    }, [variants]);

    const addVariant = () => {
        console.log("add variant");
    }

    const addVariantClicked = () => {
        setShowVariants(false);
        setShowNoVariantsFound(false);
        setShowAddVariant(true);
    }

    const [name, setName] = useState('');
    const [isNameError, setIsNameError] = useState(false);
    const [nameErrorTxt, setNameErrorTxt] = useState('');

    const [isDefault, setIsDefault] = useState(false);
    const [isDefaultError, setIsDefaultError] = useState(false);
    const [isDefaultErrorTxt, setIsDefaultErrorTxt] = useState('');

    const [isMandatory, setIsMandatory] = useState(false);
    const [isMandatoryError, setIsMandatoryError] = useState(false);
    const [isMandatoryErrorTxt, setIsMandatoryErrorTxt] = useState('');

    const [storePrice, setStorePrice] = useState(0);
    const [isStorePriceError, setIsStorePriceError] = useState(false);
    const [storePriceErrorTxt, setStorePriceErrorTxt] = useState('');

    const [onlinePrice, setOnlinePrice] = useState(0);
    const [isOnlinePriceError, setIsOnlinePriceError] = useState(false);
    const [onlinePriceErrorTxt, setOnlinePriceErrorTxt] = useState('');

    const [isStoreStockAvailable, setIsStoreStockAvailable] = useState(false);
    const [isStoreStockAvailableError, setIsStoreStockAvailableError] = useState(false);
    const [isStoreStockAvailableErrorTxt, setIsStoreStockAvailableErrorTxt] = useState("");

    const [isOnlineStockAvailable, setIsOnlineStockAvailable] = useState(false);
    const [isOnlineStockAvailableError, setIsOnlineStockAvailableError] = useState(false);
    const [isOnlineStockAvailableErrorTxt, setIsOnlineStockAvailableErrorTxt] = useState("");

    const isStoreStockAvailableTrueCheckInputRef = useRef(null);
    const isStoreStockAvailableFalseCheckInputRef = useRef(null);
    const isOnlineStockAvailableTrueCheckInputRef = useRef(null);
    const isOnlineStockAvailableFalseCheckInputRef = useRef(null);

    const checkAndSetStorePrice = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        console.log(value);
        setStorePrice(value)
    }

    const checkAndSetOnlinePrice = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setOnlinePrice(value)
    }

    const isDefaultChanged = (e) => {
        console.log(e);
        if(e === "YES"){
            setIsDefault(true);
        }
        else if(e === "NO"){
            setIsDefault(false);
        }
    }

    const isMandatoryChanged = (e) => {
        console.log(e);
        if(e === "YES"){
            setIsMandatory(true);
        }
        else if(e === "NO"){
            setIsMandatory(false);
        }
    }

    const submitAddVariantForm = async (e) => {
        e.preventDefault();

        if(name == ''){
            setNameErrorTxt('Name is required');
            setIsNameError(true);
            return;
        }
        else{
            setNameErrorTxt('');
            setIsNameError(false);
        }

        if(storePrice === ''){
            setStorePriceErrorTxt('Store price is required');
            setIsStorePriceError(true);
            return;
        }
        else{
            setStorePriceErrorTxt('');
            setIsStorePriceError(false);
        }

        if(onlinePrice === ''){
            setOnlinePriceErrorTxt('Online price is required');
            setIsOnlinePriceError(true);
            return;
        }
        else{
            setOnlinePriceErrorTxt('');
            setIsOnlinePriceError(false);
        }

        const data = {
            name: name,
            store_price: storePrice,
            online_price: onlinePrice
        }

        try {
            const addVariantProductReq = await axios.post(`${API_BASE_URL}product/${product.id}/${variant_type_id}/variant/add`, data, config);
            notifySuccess(PRODUCT_SINGLE_VARIANT_CREATED);
            LOGGER &&  console.log(addVariantProductReq);
            
            try {
                const getThisVariantType = await axios.get(`${API_BASE_URL}product/variant_type?slug=${product.seo.slug}&variant_type=${variant_type_id}`, config);
                if(getThisVariantType.data){
                    setVariantType(getThisVariantType.data);
                    resetForm();
                    setShowNoVariantsFound(false);
                    setShowAddVariant(false);
                    setShowEditVariant(false);
                    setShowVariants(true);
                }
            }
            catch (error) {
                console.log(error);
                notifyError(REQUEST_ERROR);
                LOGGER &&  console.log(error);
            }
        }
        catch (error) {
            console.log(error);
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const resetForm = () => {
        
        setName('');
        setNameErrorTxt('');
        setIsNameError(false);

        setIsDefault(false);
        setIsDefaultErrorTxt('');
        setIsDefaultError(false);

        setIsMandatory(false);
        setIsMandatoryErrorTxt('');
        setIsMandatoryError(false);

        setStorePrice(0);
        setStorePriceErrorTxt('');
        setIsStorePriceError(false);

        setOnlinePrice(0);
        setOnlinePriceErrorTxt('');
        setIsOnlinePriceError(false);
    }

    const cancelForm = () => {

        if(variants.length === 0){
            setShowAddVariant(false);
            setShowEditVariant(false);
            setShowNoVariantsFound(true);
        }
        else{
            setShowAddVariant(false);
            setShowEditVariant(false);
            setShowVariants(true);
        }
    }

    const addNewVariantClicked = () => {
        setShowVariants(false)
        setShowNoVariantsFound(false);
        setShowEditVariant(false);
        setShowAddVariant(true);
    }

    const deleteVariant = async (variant) => {
        const data = {}

        try {
            const deleteVariantProductReq = await axios.post(`${API_BASE_URL}product/${product.id}/variant_type/${variant_type_id}/variant/${variant}/delete`, data, config);
            notifySuccess(PRODUCT_SINGLE_VARIANT_DELETED);
            LOGGER &&  console.log(deleteVariantProductReq);
            
            try {
                const getThisVariantType = await axios.get(`${API_BASE_URL}product/variant_type?slug=${product.seo.slug}&variant_type=${variant_type_id}`, config);
                if(getThisVariantType.data){
                    setVariantType(getThisVariantType.data);
                    if(getThisVariantType.data.types.length != 0){
                        setShowNoVariantsFound(false);
                        setShowAddVariant(false);
                        setShowEditVariant(false);
                        setShowVariants(true);
                    }
                    else{
                        setShowAddVariant(false);
                        setShowEditVariant(false);
                        setShowVariants(false);
                        setShowNoVariantsFound(true);
                    }
                }
            }
            catch (error) {
                notifyError(REQUEST_ERROR);
                LOGGER &&  console.log(error);
            }
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const updateDefaultVariant = async (variant, type, currentStatus) => {
        console.log(currentStatus);

        const data = {
            variant_type_id: variant_type_id,
            variant_id: variant,
            type: type,
            status: !currentStatus
        }

        console.log(data);

        try {
            const updateDefaultVariantProductReq = await axios.post(`${API_BASE_URL}product/mark-variant-default/${product.id}`, data, config);
            notifySuccess(PRODUCT_SINGLE_VARIANT_DEFAULT_UPDATED);
            LOGGER &&  console.log(updateDefaultVariantProductReq);
            
            try {
                const getThisVariantType = await axios.get(`${API_BASE_URL}product/variant_type?slug=${product.seo.slug}&variant_type=${variant_type_id}`, config);
                if(getThisVariantType.data){
                    setVariantType(getThisVariantType.data);
                }
            }
            catch (error) {
                notifyError(PRODUCT_SINGLE_VARIANT_DEFAULT_COUNT_FAILED);
                LOGGER &&  console.log(error);
            }
        }
        catch (error) {
            notifyError(PRODUCT_SINGLE_VARIANT_DEFAULT_COUNT_FAILED);
            LOGGER &&  console.log(error);
        }
    }

    const updateMandatoryVariant = async (variant, type, currentStatus) => {
        const data = {
            variant_type_id: variant_type_id,
            variant_id: variant,
            type: type,
            status: !currentStatus
        }

        try {
            const updateMandatoryVariantProductReq = await axios.post(`${API_BASE_URL}product/mark-variant-mandatory/${product.id}`, data, config);
            notifySuccess(PRODUCT_SINGLE_VARIANT_MANDATORY_UPDATED);
            LOGGER &&  console.log(updateMandatoryVariantProductReq);
            
            try {
                const getThisVariantType = await axios.get(`${API_BASE_URL}product/variant_type?slug=${product.seo.slug}&variant_type=${variant_type_id}`, config);
                if(getThisVariantType.data){
                    setVariantType(getThisVariantType.data);
                }
            }
            catch (error) {
                notifyError(PRODUCT_SINGLE_VARIANT_MANDATORY_COUNT_FAILED);
                LOGGER &&  console.log(error);
            }
        }
        catch (error) {
            notifyError(PRODUCT_SINGLE_VARIANT_MANDATORY_COUNT_FAILED);
            LOGGER &&  console.log(error);
        }
    }

    const [editVariantId, setEditVariantId] = useState(0);

    const editVariantClicked = async (variant_id) => {
        try {
            const getThisVariantType = await axios.get(`${API_BASE_URL}product/variant_type?slug=${product.seo.slug}&variant_type=${variant_type_id}`, config);
            if(getThisVariantType.data){
                const variant_type = getThisVariantType.data;
                const variants = variant_type.types;
                const variant = variants.find(singleVariant => singleVariant.id === variant_id);

                console.log(variant);
        
                setEditVariantId(variant_id);
                setName(variant.name);
                setStorePrice(variant.price.base.store);
                setOnlinePrice(variant.price.base.online);
                setIsStoreStockAvailable(variant.in_stock_store);
                setIsOnlineStockAvailable(variant.in_stock_online);
                setShowVariants(false);
                setShowEditVariant(true);
            }
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const submitEditVariantForm = async (e) => {
        e.preventDefault();

        if(name == ''){
            setNameErrorTxt('Name is required');
            setIsNameError(true);
            return;
        }
        else{
            setNameErrorTxt('');
            setIsNameError(false);
        }

        if(storePrice === ''){
            setStorePriceErrorTxt('Store price is required');
            setIsStorePriceError(true);
            return;
        }
        else{
            setStorePriceErrorTxt('');
            setIsStorePriceError(false);
        }

        if(onlinePrice === ''){
            setOnlinePriceErrorTxt('Online price is required');
            setIsOnlinePriceError(true);
            return;
        }
        else{
            setOnlinePriceErrorTxt('');
            setIsOnlinePriceError(false);
        }

        const data = {
            name: name,
            store_price: storePrice,
            online_price: onlinePrice
        }

        try {
            const editVariantProductReq = await axios.post(`${API_BASE_URL}product/${product.id}/variant_type/${variant_type_id}/variant/${editVariantId}/update`, data, config);
            notifySuccess(PRODUCT_SINGLE_VARIANT_UPDATED);
            LOGGER &&  console.log(editVariantProductReq);
            
            try {
                const getThisVariantType = await axios.get(`${API_BASE_URL}product/variant_type?slug=${product.seo.slug}&variant_type=${variant_type_id}`, config);
                if(getThisVariantType.data){
                    setVariantType(getThisVariantType.data);
                    resetForm();
                    setShowNoVariantsFound(false);
                    setShowAddVariant(false);
                    setShowEditVariant(false);
                    setShowVariants(true);
                }
            }
            catch (error) {
                notifyError(REQUEST_ERROR);
                LOGGER &&  console.log(error);
            }
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const isStoreStockAvailableChanged = async (e) => {
        console.log(e);
        if(e.target.value === "YES"){
            setIsStoreStockAvailable(true);
            const isStockUpdated = await updateStockStatus("STORE", true);
            console.log(isStockUpdated);
            if(!isStockUpdated){
                setIsStoreStockAvailable(false);
                isStoreStockAvailableTrueCheckInputRef.current.checked = false;
                e.target.checked = false;
                document.getElementById('storeStockTrue').checked = false;
                document.getElementById('storeStockFalse').checked = true;
                notifyError("Unable to Update Stock Status");
            }
            else{
                notifySuccess("Stock Successfully Updated");
            }
        }
        else if(e.target.value === "NO"){
            setIsStoreStockAvailable(false);
            const isStockUpdated = await updateStockStatus("STORE", false);
            console.log(isStockUpdated);
            if(!isStockUpdated){
                setIsStoreStockAvailable(true);
                isStoreStockAvailableTrueCheckInputRef.current.checked = true;
                e.target.checked = true;
                document.getElementById('storeStockFalse').checked = false;
                document.getElementById('storeStockTrue').checked = true;
                notifyError("Unable to Update Stock Status");
            }
            else{
                notifySuccess("Stock Successfully Updated");
            }
        }
    }

    const isOnlineStockAvailableChanged = async (e) => {
        console.log(e);
        if(e.target.value === "YES"){
            setIsOnlineStockAvailable(true);
            const isStockUpdated = await updateStockStatus("ONLINE", true);
            console.log(isStockUpdated);
            if(!isStockUpdated){
                setIsOnlineStockAvailable(false);
                isOnlineStockAvailableTrueCheckInputRef.current.checked = false;
                e.target.checked = false;
                document.getElementById('storeStockTrue').checked = false;
                document.getElementById('storeStockFalse').checked = true;
                notifyError("Unable to Update Stock Status");
            }
            else{
                notifySuccess("Stock Successfully Updated");
            }
        }
        else if(e.target.value === "NO"){
            setIsOnlineStockAvailable(false);
            const isStockUpdated = await updateStockStatus("ONLINE", false);
            console.log(isStockUpdated);
            if(!isStockUpdated){
                setIsOnlineStockAvailable(true);
                isOnlineStockAvailableTrueCheckInputRef.current.checked = true;
                e.target.checked = true;
                document.getElementById('onlineStockFalse').checked = false;
                document.getElementById('onlineStockTrue').checked = true;
                notifyError("Unable to Update Stock Status");
            }
            else{
                notifySuccess("Stock Successfully Updated");
            }
        }
    }

    const updateStockStatus = async (type, status) => {
        const data = {
            variant_type_id: variant_type_id,
            variant_id: editVariantId,
            type: type,
            status: status
        }

        try{
            const updateVariantStockStatusReq = await axios.post(`${API_BASE_URL}product/update-variant-stock-status/${product.id}`, data, config);
            console.log(updateVariantStockStatusReq);
            if(updateVariantStockStatusReq.status === 200){
                return true
            }
        }
        catch(error) {
            return false
        }
    }

    const cancelEditForm = () => {
        resetForm();
        setEditVariantId(0);
        setShowEditVariant(false);
        setShowVariants(true);
    }
    
    return (
        <PopUp lable={lable} isPrimary={false} primaryLable={primaryLable} primaryAction={addVariant} isSecondary={false} secondaryLable={secondaryLable} secondaryAction={secondaryAction} secondaryType={secondaryType}>
            <PlainContainer styles={{display: 'flex', flexDirection: 'column', position: 'static'}} type={`fullwidth`}>
                {
                    showNoVariantsFound
                    ?
                    <NoDataContainer styles={{width: '100%', padding: '40px 20px'}} icon={`fa-regular fa-face-frown-open`} heading={`No Variants Found!`} description={`Click on the below link to add variant type for ${variantType?.lable}.`} isPrimary={true} primaryType={'button'} primaryLable={'add variant'} primaryAction={addVariantClicked}></NoDataContainer>
                    :
                    <></>
                }

                {
                    showAddVariant
                    ?
                    <>
                    <form onSubmit={(e) => submitAddVariantForm(e)} noValidate>
                        <Form>
                            <TextInput lable="name" capitalize={true} isMandatory={true} isError={isNameError} error={nameErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={name} placeholder="Please Enter The Variant Name" onChange={(e) => setName(e.target.value)} />
                            </TextInput>
                            <SplitContainer type="fullwidth">
                                <TextInput lable="store price" capitalize={true} isMandatory={true} isError={isStorePriceError} error={storePriceErrorTxt}>
                                    <input type="tel" className="formStepInputTxt capitalize" value={storePrice} placeholder="Please Enter The Store Price" onChange={(e) => checkAndSetStorePrice(e)} />
                                </TextInput>
                                <TextInput lable="online price" capitalize={true} isMandatory={true} isError={isOnlinePriceError} error={onlinePriceErrorTxt}>
                                    <input type="tel" className="formStepInputTxt capitalize" value={onlinePrice} placeholder="Please Enter The Online Price" onChange={(e) => checkAndSetOnlinePrice(e)} />
                                </TextInput>
                            </SplitContainer>
                            <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="cancel" secondaryAction={() => cancelForm()} />
                        </Form>
                    </form>
                    </>
                    :
                    <></>
                }

                {
                    showVariants
                    ?
                        <>
                            <PlainContainer type={`fullwidth`} styles={{display: 'flex', justifyContent: 'center', marginBottom: '10px'}}>
                                <SplitContainer>
                                    <Button type={'primary'} isSubmit={false} action={() => addNewVariantClicked()}>add new</Button>
                                    <Button type={'primary'} isSubmit={false} action={() => console.log('delete all')}>delete all</Button>
                                </SplitContainer>
                            </PlainContainer>
                            {
                                variants.map(variant => {
                                    return (
                                        <div className="variantSingle" key={variant.id}>
                                            <div className="variantSingleHeading">
                                                <p className="variantSingleHeadingTxt">{variant.name}</p>
                                            </div>
                                            <div className="variantSinglePrice">
                                                <p className="variantSinglePriceTxt">Store Price: {variant.price.base.store}Rs.</p>
                                                <p className="variantSinglePriceTxt">Online Price: {variant.price.base.online}Rs.</p>
                                            </div>
                                            <div className="variantSingleActions">
                                                {
                                                    product.service_type === "BOTH"
                                                    ?
                                                    <>
                                                        <SplitContainer type={`fullwidth`}>
                                                            <Button type={variant.is_store_default ? `success` : `danger`} isSubmit={false} styles={{width: '50%'}} action={() => updateDefaultVariant(variant.id, "STORE", variant.is_store_default)}>store default</Button>
                                                            <Button type={variant.is_store_mandatory ? `success` : `danger`} isSubmit={false} styles={{width: '50%'}} action={() => updateMandatoryVariant(variant.id, "STORE", variant.is_store_mandatory)}>store mandatory</Button>
                                                        </SplitContainer>
                                                        <SplitContainer type={`fullwidth`}>
                                                            <Button type={variant.is_online_default ? `success` : `danger`} isSubmit={false} styles={{width: '50%'}} action={() => updateDefaultVariant(variant.id, "ONLINE", variant.is_online_default)}>online default</Button>
                                                            <Button type={variant.is_online_mandatory ? `success` : `danger`} isSubmit={false} styles={{width: '50%'}} action={() => updateMandatoryVariant(variant.id, "ONLINE", variant.is_online_mandatory)}>online mandatory</Button>
                                                        </SplitContainer>
                                                    </>
                                                    :
                                                    product.service_type === "STORE"
                                                    ?
                                                    <SplitContainer type={`fullwidth`}>
                                                        <Button type={variant.is_store_default ? `success` : `danger`} isSubmit={false} styles={{width: '50%'}} action={() => updateDefaultVariant(variant.id, "STORE", variant.is_store_default)}>store default</Button>
                                                        <Button type={variant.is_store_mandatory ? `success` : `danger`} isSubmit={false} styles={{width: '50%'}} action={() => updateMandatoryVariant(variant.id, "STORE", variant.is_store_mandatory)}>store mandatory</Button>
                                                    </SplitContainer>
                                                    :
                                                    product.service_type === "ONLINE"
                                                    ?
                                                    <SplitContainer type={`fullwidth`}>
                                                        <Button type={variant.is_online_default ? `success` : `danger`} isSubmit={false} styles={{width: '50%'}} action={() => updateDefaultVariant(variant.id, "ONLINE", variant.is_online_default)}>online default</Button>
                                                        <Button type={variant.is_online_mandatory ? `success` : `danger`} isSubmit={false} styles={{width: '50%'}} action={() => updateMandatoryVariant(variant.id, "ONLINE", variant.is_online_mandatory)}>online mandatory</Button>
                                                    </SplitContainer>
                                                    :
                                                    <></>
                                                }
                                                <SplitContainer type={`fullwidth`}>
                                                    <Button type={`primary`} isSubmit={false} styles={{width: '50%'}} action={() => editVariantClicked(variant.id)}>edit</Button>
                                                    <Button type={`primary`} isSubmit={false} styles={{width: '50%'}} action={() => deleteVariant(variant.id)}>delete</Button>
                                                </SplitContainer>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            
                        </>
                    :
                        <></>
                }

                {
                    showEditVariant
                    ?
                    <>
                    <form onSubmit={(e) => submitEditVariantForm(e)} noValidate>
                        <Form>
                            <TextInput lable="name" capitalize={true} isMandatory={true} isError={isNameError} error={nameErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={name} placeholder="Please Enter The Variant Name" onChange={(e) => setName(e.target.value)} />
                            </TextInput>
                            <SplitContainer type="fullwidth">
                                <RadioButtonGroup lable="Store Stock Available?" capitalize={true} isMandatory={false} isError={isStoreStockAvailableError} error={isStoreStockAvailableErrorTxt}>
                                    <label className="form-control">
                                        <input type="radio" id="storeStockTrue" name={`isStoreStockAvailable`} value={`YES`} onChange={(e) => isStoreStockAvailableChanged(e)} defaultChecked={isStoreStockAvailable ? true : false} />
                                        yes
                                    </label>
                                    <label className="form-control">
                                        <input type="radio" id="storeStockFalse" name={`isStoreStockAvailable`} value={`NO`} onChange={(e) => isStoreStockAvailableChanged(e)} defaultChecked={isStoreStockAvailable ? false : true} />
                                        no
                                    </label>
                                </RadioButtonGroup>
                                <RadioButtonGroup lable="Online Stock Available?" capitalize={true} isMandatory={false} isError={isOnlineStockAvailableError} error={isOnlineStockAvailableErrorTxt}>
                                    <label className="form-control">
                                        <input type="radio" id="onlineStockTrue" name={`isOnlineStockAvailable`} value={`YES`} onChange={(e) => isOnlineStockAvailableChanged(e)} defaultChecked={isOnlineStockAvailable ? true : false} />
                                        yes
                                    </label>
                                    <label className="form-control">
                                        <input type="radio" id="onlineStockTrue" name={`isOnlineStockAvailable`} value={`NO`} onChange={(e) => isOnlineStockAvailableChanged(e)} defaultChecked={isOnlineStockAvailable ? false : true} />
                                        no
                                    </label>
                                </RadioButtonGroup>
                            </SplitContainer>
                            <SplitContainer type="fullwidth">
                                <TextInput lable="store price" capitalize={true} isMandatory={true} isError={isStorePriceError} error={storePriceErrorTxt}>
                                    <input type="tel" className="formStepInputTxt capitalize" value={storePrice} placeholder="Please Enter The Store Price" onChange={(e) => checkAndSetStorePrice(e)} />
                                </TextInput>
                                <TextInput lable="online price" capitalize={true} isMandatory={true} isError={isOnlinePriceError} error={onlinePriceErrorTxt}>
                                    <input type="tel" className="formStepInputTxt capitalize" value={onlinePrice} placeholder="Please Enter The Online Price" onChange={(e) => checkAndSetOnlinePrice(e)} />
                                </TextInput>
                            </SplitContainer>
                            <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="cancel" secondaryAction={() => cancelEditForm()} />
                        </Form>
                    </form>
                    </>
                    :
                    <></>
                }
                
            </PlainContainer>
        </PopUp>
    )

}

export default DisplayProductVariantsPopUp