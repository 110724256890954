import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import PlainContainer from '../../../../components/general/containers/PlainContainer';
import Section from '../../../../components/general/containers/Section';
import Heading from '../../../../components/dashboard/common/table/Heading';
import HeadingItem from '../../../../components/dashboard/common/table/HeadingItem';
import RowItem from '../../../../components/dashboard/common/table/RowItem';
import RowIcon from '../../../../components/dashboard/common/table/RowIcon';
import Table from '../../../../components/dashboard/common/table/Table';
import TableButton from '../../../../components/dashboard/common/table/TableButton';
import TableRow from '../../../../components/dashboard/common/table/TableRow';
import { API_BASE_URL, LOGGER } from '../../../../config';
import axios from 'axios';
import { notifyError, notifySuccess } from '../../../../utils/toastify';
import { PRODUCT_ACTIVATED, PRODUCT_DELETED, PRODUCT_INACTIVATED, REQUEST_ERROR } from '../../../../utils//messages';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { arrayMove } from 'react-sortable-hoc';

const RecommendedProducts = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
        'Authorization': admin.access_token
        }
    }

    const [productsLoaded, setProductsLoaded] = useState(false);
    const [products, setProducts] = useState([]);

    const getProducts = async () => {
        try{
            const getProductsReq = await axios.get(`${API_BASE_URL}recommended`);
            if(getProductsReq.status === 200){
                console.log(getProductsReq);
                setProducts(getProductsReq.data.items);
            }
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER && console.log(error);
        }
    }

    useEffect(() => {
        getProducts();
    }, []);

    const SortableItem = SortableElement(({value}) => (
        <TableRow tabIndex={0}>
            <RowItem size="1" lable={value.order} isCenter={true} hasChildren={false}></RowItem>
            <RowItem size="1" lable={value.product.category.name} isCenter={false} hasChildren={false}></RowItem>
            <RowItem size="3" lable={value.product.name} isCenter={false} hasChildren={false}></RowItem>
            <RowItem size="1" lable="3" isCenter={false} hasChildren={true}>
                <TableButton styles={{width: '100%'}} type="danger" action={() => deleteRecommendedProduct(value.id)}>delete</TableButton>
            </RowItem>
        </TableRow>
    ));

    const SortableList = SortableContainer(({products}) => {
        return (
            <div>
                {products.map((value, index) => (
                    <SortableItem key={`${value.id}`} index={index} value={value} />
                ))}
            </div>
        );
    });

    const onSortEnd = ({oldIndex, newIndex}) => {
        console.log(oldIndex, newIndex);
        // console.log(arrayMove(items, oldIndex, newIndex));
        if(oldIndex === newIndex){
            return
        }
        else{
            let sortProducts = products;
            sortProducts = arrayMove(sortProducts, oldIndex, newIndex);
            console.log(sortProducts);

            const tempProducts = [];

            sortProducts.map((product, i) => {
                let prod = {
                    id: product.id,
                    order: i + 1
                }
                tempProducts.push(prod);
            });
            console.log(tempProducts);
            sortProductsHandler(JSON.stringify(tempProducts))
        }
    };

    const deleteRecommendedProduct = async (id) => {
        console.log('delete', id);

        let delProducts = products;
        let productIndex =  delProducts.findIndex(product => product.id === id);
        delProducts.splice(productIndex, 1);
        console.log(delProducts);

        const tempProducts = [];

        delProducts.map((product, i) => {
            let prod = {
                id: product.id,
                order: i + 1
            }
            tempProducts.push(prod);
        });
        console.log(tempProducts);

        deleteProductHandler(id, tempProducts);
    }

    const deleteProductHandler = async (product_id, sort_products) => {
        console.log(product_id);
        console.log(sort_products);

        try{
            const deleteRecommendedProductReq = await axios.delete(`${API_BASE_URL}recommended/${product_id}`, config);
            if(deleteRecommendedProductReq.status === 200){
                sortProductsHandler(JSON.stringify(sort_products));
            }
            else{
                notifyError(REQUEST_ERROR);
            }
        }
        catch(error) {
            notifyError(REQUEST_ERROR);
        }
    }

    const sortProductsHandler = async (prod_string) => {
        console.log(prod_string);
        try{
            const data = {
                products: prod_string
            }
            const sortProductsReq = await axios.post(`${API_BASE_URL}recommended/sort`, data, config);
            if(sortProductsReq.status === 200){
                console.log(sortProductsReq);
                setProducts(sortProductsReq.data.items);
            }
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER && console.log(error);
        }
    }

    useEffect(() => {
        console.log(products);
    }, [products]);

    return (
        <Section heading="Recommended Products" description={routeText} action={() => navigate("create")} actionLable="create" showAction={true}>
            <Table>
                <Heading>
                    <HeadingItem size="1" isIcon={false} icon="" lable={`sl no`} />
                    <HeadingItem size="1" isIcon={false} icon="" lable={`category`} />
                    <HeadingItem size="3" isIcon={false} icon="" lable={`name`} />
                    <HeadingItem size="1" isIcon={false} icon="" lable={`action`} />
                </Heading>
                <PlainContainer styles={{width: '100%'}}>
                    {
                        products.length != 0
                        ?
                            <SortableList products={products} onSortEnd={onSortEnd} />
                        :
                            <></>
                    }
                </PlainContainer>
            </Table>
        </Section>
    )
}

export default RecommendedProducts