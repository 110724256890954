import React, { useEffect, useState } from 'react';
import Text from '../../general/typography/Text';
import PopUp from './PopUp';

import { useSelector } from 'react-redux';
import axios from 'axios';
import { API_BASE_URL, DEFAULT_IP_ADDRESS, LOGGER } from '../../../config';
import { notifyError, notifySuccess } from '../../../utils/toastify';
import { REQUEST_ERROR } from '../../../utils/messages';

import Form from '../../general/forms/core/Form';
import PlainContainer from '../../general/containers/PlainContainer';
import TextInput from '../../general/forms/TextInput';
import SplitContainer from '../../general/containers/SplitContainer';
import RadioButtonGroup from '../../general/forms/RadioButtonGroup';
import RadioButtonInput from '../../general/forms/RadioButtonInput';
import { useRef } from 'react';
import { getUserIpAddress } from '../../../utils/functions';

const UpdateProductSizePricePopUp = ({lable, primaryLable, primaryAction, secondaryLable, secondaryAction, secondaryType, concludeProductSizePricePopUp, product, size, crust, updateProduct}) => {
    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    useEffect(() => {
        console.log(product);
    }, [product]);

    console.log(crust);

    const [storePrice, setStorePrice] = useState(crust.price.base.store);
    const [isStorePriceError, setIsStorePriceError] = useState(false);
    const [storePriceErrorTxt, setStorePriceErrorTxt] = useState("");

    const [isStoreStockAvailable, setIsStoreStockAvailable] = useState(crust.in_stock_store);
    const [isStoreStockAvailableError, setIsStoreStockAvailableError] = useState(false);
    const [isStoreStockAvailableErrorTxt, setIsStoreStockAvailableErrorTxt] = useState("");

    const [onlinePrice, setOnlinePrice] = useState(crust.price.base.online);
    const [isOnlinePriceError, setIsOnlinePriceError] = useState(false);
    const [onlinePriceErrorTxt, setOnlinePriceErrorTxt] = useState("");

    const [isOnlineStockAvailable, setIsOnlineStockAvailable] = useState(crust.in_stock_online);
    const [isOnlineStockAvailableError, setIsOnlineStockAvailableError] = useState(false);
    const [isOnlineStockAvailableErrorTxt, setIsOnlineStockAvailableErrorTxt] = useState("");

    const isStoreStockAvailableTrueCheckInputRef = useRef(null);
    const isStoreStockAvailableFalseCheckInputRef = useRef(null);
    const isOnlineStockAvailableTrueCheckInputRef = useRef(null);
    const isOnlineStockAvailableFalseCheckInputRef = useRef(null);

    const [isStoreDefault, setIsStoreDefault] = useState(crust.is_store_default);
    const [isOnlineDefault, setIsOnlineDefault] = useState(crust.is_online_default);

    const checkAndSetStorePrice = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setStorePrice(value);
    }

    const checkAndSetOnlinePrice = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setOnlinePrice(value);
    }

    const isStoreStockAvailableChanged = async (e) => {
        console.log(e);
        if(e.target.value === "YES"){
            setIsStoreStockAvailable(true);
            const isStockUpdated = await updateStockStatus("STORE", true);
            if(!isStockUpdated){
                setIsStoreStockAvailable(false);
                isStoreStockAvailableTrueCheckInputRef.current.checked = false;
                e.target.checked = false;
                document.getElementById('storeStockTrue').checked = false;
                document.getElementById('storeStockFalse').checked = true;
                notifyError("Unable to Update Stock Status");
            }
            else{
                notifySuccess("Stock Successfully Updated");
            }
        }
        else if(e.target.value === "NO"){
            setIsStoreStockAvailable(false);
            const isStockUpdated = await updateStockStatus("STORE", false);
            if(!isStockUpdated){
                setIsStoreStockAvailable(true);
                isStoreStockAvailableTrueCheckInputRef.current.checked = true;
                e.target.checked = true;
                document.getElementById('storeStockTrue').checked = false;
                document.getElementById('storeStockTrue').checked = true;
                notifyError("Unable to Update Stock Status");
            }
            else{
                notifySuccess("Stock Successfully Updated");
            }
        }
    }

    const isOnlineStockAvailableChanged = async (e) => {
        console.log(e);
        if(e.target.value === "YES"){
            setIsOnlineStockAvailable(true);
            const isStockUpdated = await updateStockStatus("ONLINE", true);
            console.log(isStockUpdated);
            if(!isStockUpdated){
                setIsOnlineStockAvailable(false);
                isOnlineStockAvailableTrueCheckInputRef.current.checked = false;
                e.target.checked = false;
                document.getElementById('onlineStockTrue').checked = false;
                document.getElementById('onlineStockFalse').checked = true;
                notifyError("Unable to Update Stock Status");
            }
            else{
                notifySuccess("Stock Successfully Updated");
            }
        }
        else if(e.target.value === "NO"){
            setIsOnlineStockAvailable(false);
            const isStockUpdated = await updateStockStatus("ONLINE", false);
            console.log(isStockUpdated);
            if(!isStockUpdated){
                setIsOnlineStockAvailable(true);
                isOnlineStockAvailableTrueCheckInputRef.current.checked = true;
                e.target.checked = true;
                document.getElementById('onlineStockFalse').checked = false;
                document.getElementById('onlineStockTrue').checked = true;
                notifyError("Unable to Update Stock Status");
            }
            else{
                notifySuccess("Stock Successfully Updated");
            }
        }
    }

    const updateStockStatus = async (type, status) => {
        const data = {
            size_id: size.id,
            crust_id: crust.id,
            type: type,
            status: status
        }

        try{
            const updateProductStockStatusReq = await axios.post(`${API_BASE_URL}product/update-product-size-crust-stock-status/${product}`, data, config);
            console.log(updateProductStockStatusReq);
            if(updateProductStockStatusReq.status === 200){
                return true
            }
        }
        catch(error) {
            return false
        }
    }

    const updateProductPriceForm = async (e) => {
        e.preventDefault();
        
        if(storePrice === ''){
            setStorePriceErrorTxt('Store price is required');
            setIsStorePriceError(true);
            return;
        }
        else{
            setStorePriceErrorTxt('');
            setIsStorePriceError(false);
        }
        
        if(onlinePrice === ''){
            setOnlinePriceErrorTxt('Online price is required');
            setIsOnlinePriceError(true);
            return;
        }
        else{
            setOnlinePriceErrorTxt('');
            setIsOnlinePriceError(false);
        }
  
        const data = {
            size_id: size.id,
            crust_id: crust.id,
            store_price: storePrice,
            online_price: onlinePrice
        }
        
        console.log(data);

        try{
            const updateProductPriceReq = await axios.post(`${API_BASE_URL}product/update-product-size-crust-price/${product}`, data, config);
            console.log(updateProductPriceReq);
            if(updateProductPriceReq.status === 200){
                concludeProductSizePricePopUp(true);
            }
        }
        catch(error) {
            notifyError('Something went wrong. Try again.');
        }
    }

    const markAsDefault = async (type) => {
        const data = {
            size_id: size.id,
            crust_id: crust.id,
            type: type
        }

        try{
            const updateProductPriceReq = await axios.post(`${API_BASE_URL}product/mark-product-size-crust-default/${product}`, data, config);
            if(updateProductPriceReq.status === 200){
                updateProduct(true);
                if(type === "ONLINE"){
                    setIsOnlineDefault(true);
                }
                else if(type === "STORE"){
                    setIsStoreDefault(true);
                }
                notifySuccess("Price Successfully Updated");
            }
        }
        catch(error) {
            notifyError('Something went wrong. Try again.');
        }
    }

    return (
        <PopUp lable={lable} primaryLable={primaryLable} primaryAction={(e) => updateProductPriceForm(e)} secondaryLable={secondaryLable} secondaryAction={secondaryAction} secondaryType={secondaryType}>
            <PlainContainer styles={{display: 'flex', flexDirection: 'column', position: 'static'}} type={`fullwidth`}>
                <Form>
                    <SplitContainer>
                        <TextInput lable="store price" isMandatory={true} isError={isStorePriceError} error={storePriceErrorTxt}>
                            <input type="tel" className="formStepInputTxt capitalize" value={storePrice} placeholder="Please Enter The Store Price" onChange={(e) => checkAndSetStorePrice(e)} />
                        </TextInput>
                        <RadioButtonGroup lable="Store Stock Available?" capitalize={true} isMandatory={true} isError={isStoreStockAvailableError} error={isStoreStockAvailableErrorTxt}>
                            <label className="form-control">
                                <input type="radio" id="storeStockTrue" ref={isStoreStockAvailableTrueCheckInputRef} name={`isStoreStockAvailable`} value={`YES`} onChange={(e) => isStoreStockAvailableChanged(e)} defaultChecked={isStoreStockAvailable ? true : false} />
                                yes
                            </label>
                            <label className="form-control">
                                <input type="radio" id="storeStockFalse" ref={isStoreStockAvailableFalseCheckInputRef} name={`isStoreStockAvailable`} value={`NO`} onChange={(e) => isStoreStockAvailableChanged(e)} defaultChecked={isStoreStockAvailable ? false : true} />
                                no
                            </label>
                        </RadioButtonGroup>
                    </SplitContainer>
                    <PlainContainer type={`fullwidth`} styles={{marginBottom: `20px`}}>
                        {
                            isStoreDefault === true 
                            ?
                                <div className="makeDefaultSizeCrust selected">
                                    <p className="makeDefaultSizeCrustTxt">Marked Size & Crust As Default</p>
                                </div>
                            :
                                <div className="makeDefaultSizeCrust" onClick={() => markAsDefault('STORE')}>
                                    <p className="makeDefaultSizeCrustTxt">Mark Size & Crust As Default</p>
                                </div>
                        }
                    </PlainContainer>
                    <SplitContainer>
                        <TextInput lable="online price" isMandatory={true} isError={isOnlinePriceError} error={onlinePriceErrorTxt}>
                            <input type="tel" className="formStepInputTxt capitalize" value={onlinePrice} placeholder="Please Enter The Online Price" onChange={(e) => checkAndSetOnlinePrice(e)} />
                        </TextInput>
                        <RadioButtonGroup lable="Online Stock Available?" capitalize={true} isMandatory={true} isError={isOnlineStockAvailableError} error={isOnlineStockAvailableErrorTxt}>
                            <label className="form-control">
                                <input type="radio" id="onlineStockTrue" ref={isOnlineStockAvailableTrueCheckInputRef} name={`isOnlineStockAvailable`} value={`YES`} onChange={(e) => isOnlineStockAvailableChanged(e)} defaultChecked={isOnlineStockAvailable ? true : false} />
                                yes
                            </label>
                            <label className="form-control">
                                <input type="radio" id="onlineStockFalse" ref={isOnlineStockAvailableFalseCheckInputRef} name={`isOnlineStockAvailable`} value={`NO`} onChange={(e) => isOnlineStockAvailableChanged(e)} defaultChecked={isOnlineStockAvailable ? false : true} />
                                no
                            </label>
                        </RadioButtonGroup>
                    </SplitContainer>
                    <PlainContainer type={`fullwidth`}>
                        {
                            isOnlineDefault === true 
                            ?
                                <div className="makeDefaultSizeCrust selected">
                                    <p className="makeDefaultSizeCrustTxt">Marked Size & Crust As Default</p>
                                </div>
                            :
                                <div className="makeDefaultSizeCrust" onClick={() => markAsDefault('ONLINE')}>
                                    <p className="makeDefaultSizeCrustTxt">Mark Size & Crust As Default</p>
                                </div>
                        }
                    </PlainContainer>
                </Form>
            </PlainContainer>
        </PopUp>
    )
}

export default UpdateProductSizePricePopUp