import axios from 'axios';

export const getUserIpAddress = async () => {
    try{
        const getIpReq = await axios.get(`https://geolocation-db.com/json/`);
        return {
            status: true,
            ip: getIpReq.data.IPv4
        }
    }
    catch(error){
        return {
            status: false,
            ip: '127.0.0.1'
        }
    }
}

export const allowOnlyNumbers = (phone) => {
    let pattern = /^\d+$/;
    const check = phone.match(pattern);
    if(check){
        return true;
    }
    else{
        return false;
    }
}