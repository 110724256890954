import React from 'react'

const PlainStep = ({children}) => {
    return (
        <div className="formStep">
            <div className="formStepInput">
                {children}
            </div>
        </div>
    )
}

export default PlainStep