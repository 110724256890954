import React, { useEffect, useState } from 'react';
import NavItem from './navigation/NavItem';
import NavSection from './navigation/NavSection';
import { BRAND_NAME, ROLES } from '../../../config';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const routeList = {
    home: [{name:"home", goTo:"/dashboard", icon:"fas fa-home"}],
    masterdata: [
        {name:"categories", goTo:"/dashboard/masterdata/categories", icon:"fa-solid fa-boxes-stacked"},
        {name:"pizza sizes", goTo:"/dashboard/masterdata/pizza-sizes", icon:"fa-solid fa-up-right-and-down-left-from-center"},
        {name:"pizza crusts", goTo:"/dashboard/masterdata/pizza-crusts", icon:"fa-solid fa-pizza-slice"},
        {name:"products", goTo:"/dashboard/masterdata/products", icon:"fa-solid fa-box-open"},
        {name:"stores", goTo:"/dashboard/masterdata/stores", icon:"fa-solid fa-store"},
        {name:"coupons", goTo:"/dashboard/masterdata/coupons", icon:"fa-solid fa-ticket"},
        {name:"recommended", goTo:"/dashboard/masterdata/recommended-products", icon:"fa-solid fa-star"},
    ],
    // orders: [
    //     {name:"create", goTo:"/dashboard/orders/create", icon:"fa-solid fa-map-location-dot"},
    //     {name:"store orders", goTo:"/dashboard/orders/store-orders", icon:"fa-solid fa-map-location-dot"},
    // ],
    // users: [
    //     {name:"staffs", goTo:"/dashboard/users/staffs", icon:"fa-solid fa-map-location-dot"},
    //     {name:"customers", goTo:"/dashboard/users/customers", icon:"fa-solid fa-map-location-dot"},
    // ]
}

const Navbar = ({ showNav }) => {
    const location = useLocation();
    const admin = useSelector((state) => state.admin.value);
    
    const isAdmin = ROLES.ADMIN === admin.role;
    const isSuperAdmin = ROLES.SUPER_ADMIN === admin.role;

    const [lableText, setLableText] = useState(BRAND_NAME);

    useEffect(() => {
        if(showNav === true){
            setLableText(BRAND_NAME);
        }
        else{
            setLableText(BRAND_NAME.charAt(0));
        }
    }, [showNav]);

    return (
        <div className={showNav ? "dashboardNav active" : "dashboardNav"}>
            <div className="dashboardNavHeader">
                <div className="dashboardNavHeaderText">
                    <p className="dashboardNavHeaderTxt">{lableText}</p>
                </div>
            </div>
            <div className="dashboardNavMain">
                <div className="dashboardNavInner">
                    <NavSection lable="main" showNav={showNav}>
                        {
                            routeList.home.map(({name, goTo, icon})=>(
                                <NavItem key={goTo} goTo={goTo} name={name} icon={icon} showNav={showNav} active={location.pathname === goTo} />
                            ))
                        }
                    </NavSection>
                    <NavSection lable="masterdata" showNav={showNav}>
                        {
                            routeList.masterdata.map(({name, goTo, icon})=>(
                                <NavItem key={goTo} goTo={goTo} name={name} icon={icon} showNav={showNav} active={location.pathname.includes(goTo)} />
                            ))
                        }
                    </NavSection>
                    {/* <NavSection lable="orders" showNav={showNav}>
                        {
                            routeList.orders.map(({name, goTo, icon})=>(
                                <NavItem key={goTo} goTo={goTo} name={name} icon={icon} showNav={showNav} active={location.pathname.includes(goTo)} />
                            ))
                        }
                    </NavSection>
                    <NavSection lable="users" showNav={showNav}>
                        {
                            routeList.users.map(({name, goTo, icon})=>(
                                <NavItem key={goTo} goTo={goTo} name={name} icon={icon} showNav={showNav} active={location.pathname.includes(goTo)} />
                            ))
                        }
                    </NavSection> */}
                </div>
            </div>
        </div>
    );
};

export default Navbar;
