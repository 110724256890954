import React, { useState, useEffect, forwardRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import Form from '../../../../../components/general/forms/core/Form';
import FormActions from '../../../../../components/general/forms/core/FormActions';
import FileInput from '../../../../../components/general/forms/FileInput';
import PlainStep from '../../../../../components/general/forms/PlainStep';
import TextArea from '../../../../../components/general/forms/TextArea';
import TextInput from '../../../../../components/general/forms/TextInput';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { API_BASE_URL, LOGGER } from '../../../../../config';
import { notifyError, notifySuccess } from '../../../../../utils/toastify';
import { STORE_CREATED, REQUEST_ERROR, COUPON_CREATED } from '../../../../../utils/messages';
import { allowOnlyNumbers } from '../../../../../utils/functions';
import SplitContainer from '../../../../../components/general/containers/SplitContainer';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RadioButtonGroup from '../../../../../components/general/forms/RadioButtonGroup';
import RadioButtonInput from '../../../../../components/general/forms/RadioButtonInput';
import Autocomplete from '../../../../../components/general/forms/Autocomplete';
import AutoCompleteBubble from '../../../../../components/general/forms/AutoCompleteBubble';

const AddCoupon = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const [code, setCode] = useState("");
    const [couponType, setCouponType] = useState("PROMO");
    const [availableAt, setAvailableAt] = useState("BOTH");
    const [applicableTo, setApplicableTo] = useState("ALL");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [percentage, setPercentage] = useState(0);
    const [maxDiscountInRs, setMaxDiscountInRs] = useState(0);
    const [freebieProductName, setFreebieProductName] = useState('');
    const [showFreebieProductAutocomplete, setShowFreebieProductAutocomplete] = useState('');
    const [autoCompleteFreebieProducts, setAutoCompleteFreebieProducts] = useState([]);
    const [selectedFreebieProduct, setSelectedFreebieProduct] = useState('');
    const [showSelectedFreebieProduct, setShowSelectedFreebieProduct] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [minOrderValue, setMinOrderValue] = useState(0);
    const [buyGetApplicableTo, setBuyGetApplicableTo] = useState("CATEGORY");
    const [buyGetCategories, setBuyGetCategories] = useState([]);
    const [buyGetCategory, setBuyGetCategory] = useState(0);
    const [buyGetProducts, setBuyGetProducts] = useState([]);
    const [buyGetProductName, setBuyGetProductName] = useState('');
    const [autoCompleteBuyGetProducts, setAutoCompleteBuyGetProducts] = useState([]);
    const [showBuyGetProductAutocomplete, setShowBuyGetProductAutocomplete] = useState(false);
    const [showSelectedBuyGetProducts, setShowSelectedBuyGetProducts] = useState(false);
    const [selectedBuyGetProducts, setSelectedBuyGetProducts] = useState([]);
    const [buyQuantity, setBuyQuantity] = useState(1);
    const [getQuantity, setGetQuantity] = useState(1);
    const [flatApplicableTo, setFlatApplicableTo] = useState("ORDER");
    const [flatDiscountType, setFlatDiscontType] = useState("PERCENTAGE");
    const [flatDiscount, setFlatDiscount] = useState(0);
    const [flatCategories, setFlatCategories] = useState([]);
    const [flatCategoryName, setFlatCategoryName] = useState('');
    const [autoCompleteFlatCategories, setAutoCompleteFlatCategories] = useState([]);
    const [showFlatCategoryAutocomplete, setShowFlatCategoryAutocomplete] = useState(false);
    const [showSelectedFlatCategories, setShowSelectedFlatCategories] = useState(false);
    const [selectedFlatCategories, setSelectedFlatCategories] = useState([]);
    const [flatProducts, setFlatProducts] = useState([]);
    const [flatProductName, setFlatProductName] = useState('');
    const [autoCompleteFlatProducts, setAutoCompleteFlatProducts] = useState([]);
    const [showFlatProductAutocomplete, setShowFlatProductAutocomplete] = useState(false);
    const [showSelectedFlatProducts, setShowSelectedFlatProducts] = useState(false);
    const [selectedFlatProducts, setSelectedFlatProducts] = useState([]);
    const [totalRedeemCount, setTotalRedeemCount] = useState(100000);
    const [totalRedeemCountPerCustomer, setTotalRedeemCountPerCustomer] = useState(1000);

    const [isCodeError, setIsCodeError] = useState(false);
    const [isCouponTypeError, setIsCouponTypeError] = useState(false);
    const [isAvailableAtError, setIsAvailableAtError] = useState(false);
    const [isApplicableToError, setIsApplicableToError] = useState(false);
    const [isStartDateError, setIsStartDateError] = useState(false);
    const [isEndDateError, setIsEndDateError] = useState(false);
    const [isPercentageError, setIsPercentageError] = useState(false);
    const [isMaxDiscountInRsError, setIsMaxDiscountInRsError] = useState(false);
    const [isFreebieProductError, setIsFreebieProductError] = useState(false);
    const [isQuantityError, setIsQuantityError] = useState(false);
    const [isMinOrderValueError, setIsMinOrderValueError] = useState(false);
    const [isBuyGetApplicableToError, setIsBuyGetApplicableToError] = useState(false);
    const [isBuyGetCategoryError, setIsBuyGetCategoryError] = useState(false);
    const [isBuyGetProductsError, setIsBuyGetProductsError] = useState(false);
    const [isBuyQuantityError, setIsBuyQuantityError] = useState(false);
    const [isGetQuantityError, setIsGetQuantityError] = useState(false);
    const [isFlatApplicableToError, setIsFlatApplicableToError] = useState(false);
    const [isFlatDiscountTypeError, setIsFlatDiscountTypeError] = useState(false);
    const [isFlatDiscountError, setIsFlatDiscountError] = useState(false);
    const [isFlatCategoriesError, setIsFlatCategoriesError] = useState(false);
    const [isFlatProductsError, setIsFlatProductsError] = useState(false);
    const [isTotalRedeemCountError, setIsTotalRedeemCountError] = useState(false);
    const [isTotalRedeemCountPerCustomerError, setIsTotalRedeemCountPerCustomerError] = useState(false);

    const [codeErrorTxt, setCodeErrorTxt] = useState("");
    const [couponTypeErrorTxt, setCouponTypeErrorTxt] = useState("");
    const [availableAtErrorTxt, setAvailableAtErrorTxt] = useState("");
    const [applicableToErrorTxt, setApplicableToErrorTxt] = useState("");
    const [startDateErrorTxt, setStartDateErrorTxt] = useState("");
    const [endDateErrorTxt, setEndDateErrorTxt] = useState("");
    const [percentageErrorTxt, setPercentageErrorTxt] = useState("");
    const [maxDiscountInRsErrorTxt, setMaxDiscountInRsErrorTxt] = useState("");
    const [freebieProductErrorTxt, setFreebieProductErrorTxt] = useState("");
    const [quantityErrorTxt, setQuantityErrorTxt] = useState("");
    const [minOrderValueErrorTxt, setMinOrderValueErrorTxt] = useState("");
    const [buyGetApplicableToErrorTxt, setBuyGetApplicableToErrorTxt] = useState("");
    const [buyGetCategoryErrorTxt, setBuyGetCategoryErrorTxt] = useState("");
    const [buyGetProductsErrorTxt, setBuyGetProductsErrorTxt] = useState("");
    const [buyQuantityErrorTxt, setBuyQuantityErrorTxt] = useState("");
    const [getQuantityErrorTxt, setGetQuantityErrorTxt] = useState("");
    const [flatApplicableToErrorTxt, setFlatApplicableToErrorTxt] = useState("");
    const [flatDiscountTypeErrorTxt, setFlatDiscountTypeErrorTxt] = useState("");
    const [flatDiscountErrorTxt, setFlatDiscountErrorTxt] = useState("");
    const [flatCategoriesErrorTxt, setFlatCategoriesErrorTxt] = useState("");
    const [flatProductsErrorTxt, setFlatProductsErrorTxt] = useState("");
    const [totalRedeemCountErrorTxt, setTotalRedeemCountErrorTxt] = useState("");
    const [totalRedeemCountPerCustomerErrorTxt, setTotalRedeemCountPerCustomerErrorTxt] = useState("");

    const [couponTypes, setCouponTypes] = useState([
        {
            id: 'PROMO',
            value: 'Promo'
        },
        {
            id: 'FREEBIE',
            value: 'Freebie'
        },
        {
            id: 'BUYGET',
            value: 'Buy Get'
        },
        {
            id: 'FLAT',
            value: 'Flat'
        }
    ]);
    
    const StartDateInput = forwardRef(({ value, onClick }, ref) => (
        <TextInput lable="start date" isMandatory={true} isError={isStartDateError} error={startDateErrorTxt}>
            <input type="text" readOnly ref={ref} className="formStepInputTxt capitalize" defaultValue={value} placeholder="Please Enter The Start Date" onClick={onClick} />
        </TextInput>
    ));

    const EndDateInput = forwardRef(({ value, onClick }, ref) => (
        <TextInput lable="end date" isMandatory={true} isError={isEndDateError} error={endDateErrorTxt}>
            <input type="text" readOnly ref={ref} className="formStepInputTxt capitalize" defaultValue={value} placeholder="Please Enter The End Date" onClick={onClick} />
        </TextInput>
    ));

    const getCategories = async () => {
        try{
            const getCategoriesReq = await axios.get(`${API_BASE_URL}category`);
            if(getCategoriesReq.status === 200){
                setBuyGetCategories(getCategoriesReq.data.items);
            }
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER && console.log(error);
        }
    }

    useEffect(() => {
        getCategories();
    }, []);

    const codeChanged = async (e) => {
        setCode(e);
        try{
            const codeStatusReq = await axios.get(`${API_BASE_URL}coupon/check-code?code=${e}`, config);
            if(codeStatusReq.status === 200){
                setCodeErrorTxt('');
                setIsCodeError(false);
            }
        }
        catch(error){
            if(error.response.status === 403){
                setCodeErrorTxt('Coupon Already Exist');
                setIsCodeError(true);
            }
            else{
                setCodeErrorTxt("Something Went Wrong. Try Again.");
                setIsCodeError(true);
            }
        }
    }

    const promoPercentageChanged = (e) => {
        const isPercentageValid = allowOnlyNumbers(e);
        if(isPercentageValid){
            setPercentage(e);
            setIsPercentageError(false);
            setPercentageErrorTxt('');
        }
        else if(e.length === 0){
            setPercentage('');
            setIsPercentageError(true);
            setPercentageErrorTxt('Percentage is Required');
        }
    }

    const promoMaxDiscountChanged = (e) => {
        const isMaxDiscountValid = allowOnlyNumbers(e);
        if(isMaxDiscountValid){
            setMaxDiscountInRs(e);
            setIsMaxDiscountInRsError(false);
            setMaxDiscountInRsErrorTxt('');
        }
        else if(e.length === 0){
            setMaxDiscountInRs('');
            setIsMaxDiscountInRsError(true);
            setMaxDiscountInRsErrorTxt('Max Discount in Rs. is Required');
        }
    }

    const totalRedeemCountChanged = (e) => {
        const isTotalRedeemCountValid = allowOnlyNumbers(e);
        if(isTotalRedeemCountValid){
            setTotalRedeemCount(e);
            setIsTotalRedeemCountError(false);
            setTotalRedeemCountErrorTxt('');
        }
        else if(e.length === 0){
            setTotalRedeemCount('');
            setIsTotalRedeemCountError(true);
            setTotalRedeemCountErrorTxt('Max Redeem Count is Required');
        }
    }

    const totalRedeemCountPerCustomerChanged = (e) => {
        const isTotalRedeemCountPerCustrValid = allowOnlyNumbers(e);
        if(isTotalRedeemCountPerCustrValid){
            setTotalRedeemCountPerCustomer(e);
            setIsTotalRedeemCountPerCustomerError(false);
            setTotalRedeemCountPerCustomerErrorTxt('');
        }
        else if(e.length === 0){
            setTotalRedeemCountPerCustomer('');
            setIsTotalRedeemCountPerCustomerError(true);
            setTotalRedeemCountPerCustomerErrorTxt('Max Redeem Count Per Custr. is Required');
        }
    }

    const freebieProductChanged = async (e) => {
        setFreebieProductName(e);
        console.log(e);
        if(e.length >= 3){
            try {
                const getSimilarProductsReq = await axios.get(`${API_BASE_URL}product/similar/${e}`, config);
                console.log(getSimilarProductsReq);
                if (getSimilarProductsReq.status === 200) {
                    setAutoCompleteFreebieProducts(getSimilarProductsReq.data);
                    setShowFreebieProductAutocomplete(true);
                }
                else{
                    setAutoCompleteFreebieProducts([]);
                    setShowFreebieProductAutocomplete(false);
                }
            }
            catch (error) {
                LOGGER && console.log(error);
                notifyError(REQUEST_ERROR);
                setAutoCompleteFreebieProducts([]);
                setShowFreebieProductAutocomplete(false);
            }
        }
        else{
            setAutoCompleteFreebieProducts([]);
            setShowFreebieProductAutocomplete(false);
        }
    }

    const freebieProductAutoCompleteSelectionHandler = async (product) => {
        console.log(product);
        const selectedProductArr = [];
        const selected_product = {
            id: product.id,
            shortcode: product.shortcode,
            name: product.name
        }
        setFreebieProductName('');
        selectedProductArr.push(selected_product);
        setSelectedFreebieProduct(selectedProductArr);
        setShowSelectedFreebieProduct(true);
        setShowFreebieProductAutocomplete(false);
    }

    const deleteThisFreebieAutoCompleteProduct = async () => {
        setSelectedFreebieProduct([]);
        setShowSelectedFreebieProduct(false);
    }

    const freebieQtyChanged = (e) => {
        const isQtyValid = allowOnlyNumbers(e);
        if(isQtyValid){
            setQuantity(e);
            setIsQuantityError(false);
            setQuantityErrorTxt('');
        }
        else if(e.length === 0){
            setQuantity('');
            setIsQuantityError(true);
            setQuantityErrorTxt('Quantity is Required');
        }
    }

    const freebieMinOrderValChanged = (e) => {
        const isMinIrderValValid = allowOnlyNumbers(e);
        if(isMinIrderValValid){
            setMinOrderValue(e);
            setIsMinOrderValueError(false);
            setMinOrderValueErrorTxt('');
        }
        else if(e.length === 0){
            setMinOrderValue('');
            setIsMinOrderValueError(true);
            setMinOrderValueErrorTxt('Min. Order Value is Required');
        }
    }

    const buyGetProductNameChanged = async (e) => {
        setBuyGetProductName(e);
        console.log(e);
        if(e.length >= 3){
            try {
                const getSimilarProductsReq = await axios.get(`${API_BASE_URL}product/similar/${e}`, config);
                console.log(getSimilarProductsReq);
                if (getSimilarProductsReq.status === 200) {
                    setAutoCompleteBuyGetProducts(getSimilarProductsReq.data);
                    setShowBuyGetProductAutocomplete(true);
                }
                else{
                    setAutoCompleteBuyGetProducts([]);
                    setShowBuyGetProductAutocomplete(false);
                }
            }
            catch (error) {
                LOGGER && console.log(error);
                notifyError(REQUEST_ERROR);
                setAutoCompleteBuyGetProducts([]);
                setShowBuyGetProductAutocomplete(false);
            }
        }
        else{
            setAutoCompleteBuyGetProducts([]);
            setShowBuyGetProductAutocomplete(false);
        }
    }

    const buyGetProductAutoCompleteSelectionHandler = async (product) => {
        console.log(product);
        const selectedProductArr = buyGetProducts;

        let isProductAvailable = selectedProductArr.find(prod => prod.id === product.id);
        console.log(isProductAvailable);

        if(isProductAvailable===undefined){
            const selected_product = {
                id: product.id,
                shortcode: product.shortcode,
                name: product.name
            }
            setBuyGetProductName('');
            selectedProductArr.push(selected_product);
            setSelectedBuyGetProducts(selectedProductArr);
            setShowSelectedBuyGetProducts(true);
            setShowBuyGetProductAutocomplete(false);
        }
        else{
            setBuyGetProductName('');
            setShowSelectedBuyGetProducts(true);
            setShowBuyGetProductAutocomplete(false);
        }
    }

    const deleteThisBuyGetAutocompleteProduct = async (product) => {
        const products = [];

        selectedBuyGetProducts.map(prod => {
            if(prod.id != product){
                products.push(prod)
            }
        });

        setSelectedBuyGetProducts(products);
        if(products.length === 0){
            setShowSelectedBuyGetProducts(false);
        }
        else{
            setShowSelectedBuyGetProducts(true);
        }
    }

    const flatCategoryNameChanged = async (e) => {
        setFlatCategoryName(e);
        console.log(e);
        if(e.length >= 3){
            try {
                const getSimilarCategoriesReq = await axios.get(`${API_BASE_URL}category/similar/${e}`, config);
                console.log(getSimilarCategoriesReq);
                if (getSimilarCategoriesReq.status === 200) {
                    setAutoCompleteFlatCategories(getSimilarCategoriesReq.data);
                    setShowFlatCategoryAutocomplete(true);
                }
                else{
                    setAutoCompleteFlatCategories([]);
                    setShowFlatCategoryAutocomplete(false);
                }
            }
            catch (error) {
                LOGGER && console.log(error);
                notifyError(REQUEST_ERROR);
                setAutoCompleteFlatCategories([]);
                setShowFlatCategoryAutocomplete(false);
            }
        }
        else{
            setAutoCompleteFlatCategories([]);
            setShowFlatCategoryAutocomplete(false);
        }
    }

    const flatCategoryAutoCompleteSelectionHandler = async (category) => {
        console.log(category);
        const selectedCategoryArr = selectedFlatCategories;

        let isCategoryAvailable = selectedCategoryArr.find(cat => cat.id === category.id);
        console.log(isCategoryAvailable);

        if(isCategoryAvailable===undefined){
            const selected_category = {
                id: category.id,
                shortcode: category.shortcode,
                name: category.name
            }
            setFlatCategoryName('');
            selectedCategoryArr.push(selected_category);
            setSelectedFlatCategories(selectedCategoryArr);
            setShowSelectedFlatCategories(true);
            setShowFlatCategoryAutocomplete(false);
        }
        else{
            setFlatCategoryName('');
            setShowSelectedFlatCategories(true);
            setShowFlatCategoryAutocomplete(false);
        }
    }

    const deleteThisFlatAutocompleteCategory = (category) => {
        const categories = [];

        selectedFlatCategories.map(cat => {
            if(cat.id != category){
                categories.push(cat)
            }
        });

        setSelectedFlatCategories(categories);
        if(categories.length === 0){
            setShowSelectedFlatCategories(false);
        }
        else{
            setShowSelectedFlatCategories(true);
        }
    }

    const flatProductNameChanged = async (e) => {
        setFlatProductName(e);
        console.log(e);
        if(e.length >= 3){
            try {
                const getSimilarProductsReq = await axios.get(`${API_BASE_URL}product/similar/${e}`, config);
                console.log(getSimilarProductsReq);
                if (getSimilarProductsReq.status === 200) {
                    setAutoCompleteFlatProducts(getSimilarProductsReq.data);
                    setShowFlatProductAutocomplete(true);
                }
                else{
                    setAutoCompleteFlatProducts([]);
                    setShowFlatProductAutocomplete(false);
                }
            }
            catch (error) {
                LOGGER && console.log(error);
                notifyError(REQUEST_ERROR);
                setAutoCompleteFlatProducts([]);
                setShowFlatProductAutocomplete(false);
            }
        }
        else{
            setAutoCompleteFlatProducts([]);
            setShowFlatProductAutocomplete(false);
        }
    }

    const flatProductAutoCompleteSelectionHandler = async (product) => {
        console.log(product);
        const selectedProductArr = buyGetProducts;

        let isProductAvailable = selectedProductArr.find(prod => prod.id === product.id);
        console.log(isProductAvailable);

        if(isProductAvailable===undefined){
            const selected_product = {
                id: product.id,
                shortcode: product.shortcode,
                name: product.name
            }
            setFlatProductName('');
            selectedProductArr.push(selected_product);
            setSelectedFlatProducts(selectedProductArr);
            setShowSelectedFlatProducts(true);
            setShowFlatProductAutocomplete(false);
        }
        else{
            setFlatProductName('');
            setShowSelectedFlatProducts(true);
            setShowFlatProductAutocomplete(false);
        }
    }

    const deleteThisFlatAutocompleteProduct = (product) => {
        const products = [];

        selectedFlatProducts.map(prod => {
            if(prod.id != product){
                products.push(prod)
            }
        });

        setSelectedFlatProducts(products);
        if(products.length === 0){
            setShowSelectedFlatProducts(false);
        }
        else{
            setShowSelectedFlatProducts(true);
        }
    }

    useEffect(() => {
        setIsBuyGetCategoryError(false);
        setIsBuyGetProductsError(false);
    }, [buyGetApplicableTo]);

    useEffect(() => {
        setIsFlatCategoriesError(false);
        setIsFlatProductsError(false);
    }, [flatApplicableTo]);

    const flatDiscountChanged = (e) => {
        const isDiscountValid = allowOnlyNumbers(e);
        if(isDiscountValid){
            setFlatDiscount(e);
            setIsFlatDiscountError(false);
            setFlatDiscountErrorTxt('');
        }
        else if(e.length === 0){
            setMinOrderValue('');
            setIsFlatDiscountError(true);
            setFlatDiscountErrorTxt('Discount is Required');
        }
    }

    const handleChangeBuyGetApplicableTo = (data) => {
        setBuyGetApplicableTo(data);
        if(data === "PRODUCTS"){
            setBuyGetCategory(0);
        }
        else{
            setSelectedBuyGetProducts([]);
            setShowSelectedBuyGetProducts(false);
        }
    }

    const handleChangeFlatApplicableTo = (data) => {
        setFlatApplicableTo(data);
        if(data === "ORDER"){
            setSelectedFlatCategories([]);
            setShowSelectedFlatCategories(false);

            setSelectedFlatProducts([]);
            setShowSelectedFlatProducts(false);
        }
        else if(data === "CATEGORY"){
            setSelectedFlatProducts([]);
            setShowSelectedFlatProducts(false);
        }
        else if(data === "PRODUCT"){
            setSelectedFlatCategories([]);
            setShowSelectedFlatCategories(false);
        }
        else{
            console.log('error');
        }
    }

    const createCouponHandler = async (data) => {
        console.log(data);

        try {
            const createCouponReq = await axios.post(`${API_BASE_URL}coupon`, data, config);
            notifySuccess(COUPON_CREATED);
            navigate('/dashboard/masterdata/coupons');
            LOGGER &&  console.log(createCouponReq);
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    const submitCreateCouponForm = async (e) => {
        e.preventDefault();

        if(couponType === "0"){
            setIsCouponTypeError(true);
            setCouponTypeErrorTxt("Coupon Type is Required");
            return
        }
        else{
            setIsCouponTypeError(false);
            setCouponTypeErrorTxt("")
        }

        if(code === ''){
            setIsCodeError(true);
            setCodeErrorTxt('Coupon Code is Required');
            return
        }
        else{
            if(isCodeError === true){
                setIsCodeError(true);
                setCodeErrorTxt('Coupon Code Already Exist');
                return
            }
            else{
                setIsCodeError(false);
                setCodeErrorTxt('');
            }
        }

        if(totalRedeemCount === ''){
            setIsTotalRedeemCountError(true);
            setTotalRedeemCountErrorTxt('Total Redreem Count is Required');
            return
        }
        else{
            if(totalRedeemCount == 0){
                setIsTotalRedeemCountError(true);
                setTotalRedeemCountErrorTxt('Total Redreem Count Cannot Be 0');
                return
            }
            else{
                setIsTotalRedeemCountError(false);
                setTotalRedeemCountErrorTxt('');
            }
        }

        if(totalRedeemCountPerCustomer === ''){
            setIsTotalRedeemCountPerCustomerError(true);
            setTotalRedeemCountPerCustomerErrorTxt('Total Redreem Count Per Custr. is Required');
            return
        }
        else{
            if(totalRedeemCount == 0){
                setIsTotalRedeemCountPerCustomerError(true);
                setTotalRedeemCountPerCustomerErrorTxt('Total Redreem Count Per Custr. Cannot Be 0');
                return
            }
            else{
                setIsTotalRedeemCountPerCustomerError(false);
                setTotalRedeemCountPerCustomerErrorTxt('');
            }
        }

        if(couponType==="PROMO"){
            if(percentage === ''){
                setIsPercentageError(true);
                setPercentageErrorTxt('Percentage is Required');
                return
            }
            else{
                if(percentage == 0){
                    setIsPercentageError(true);
                    setPercentageErrorTxt('Percentage Cannot Be 0');
                    return
                }
                else if(parseInt(percentage) > 100){
                    setIsPercentageError(true);
                    setPercentageErrorTxt('Percentage Cannot Exceed 100');
                    return
                }
                else{
                    setIsPercentageError(false);
                    setPercentageErrorTxt('');
                }
            }

            if(maxDiscountInRs === ''){
                setIsMaxDiscountInRsError(true);
                setMaxDiscountInRsErrorTxt('Max Discount in Rs. is Required');
                return
            }
            else{
                setIsMaxDiscountInRsError(false);
                setMaxDiscountInRsErrorTxt('');
            }

            const data = {
                coupon_type: "PROMO",
                available_at: availableAt,
                service_type: "ALL",
                user_type: applicableTo,
                code: code,
                value: parseInt(percentage),
                value_type: "PERCENTAGE",
                max_discount: parseInt(maxDiscountInRs),
                min_order_value: 0,
                quantity: 0,
                start_date: startDate,
                end_date: endDate,
                total_redeem_count: parseInt(totalRedeemCount),
                total_redeem_count_per_customer: parseInt(totalRedeemCountPerCustomer)
            }

            createCouponHandler(data);
        }
        else if(couponType==="FREEBIE"){
            if(selectedFreebieProduct.length === 0){
                setIsFreebieProductError(true);
                setFreebieProductErrorTxt('Product is Required');
                return
            }
            else{
                setIsFreebieProductError(false);
                setFreebieProductErrorTxt('');
            }

            if(quantity === ''){
                setIsQuantityError(true);
                setQuantityErrorTxt('Quantity is Required');
                return
            }
            else{
                if(quantity == 0){
                    setIsQuantityError(true);
                    setQuantityErrorTxt('Quantity Cannot Be 0');
                    return
                }
                else{
                    setIsQuantityError(false);
                    setQuantityErrorTxt('');
                }
            }

            if(minOrderValue === ''){
                setIsMinOrderValueError(true);
                setMinOrderValueErrorTxt('Min Order Value is Required');
                return
            }
            else{
                setIsMinOrderValueError(false);
                setMinOrderValueErrorTxt('');
            }

            const data = {
                coupon_type: "FREEBIE",
                available_at: availableAt,
                service_type: "ALL",
                user_type: applicableTo,
                code: code,
                value: 0,
                value_type: "PERCENTAGE",
                max_discount: 0,
                min_order_value: minOrderValue,
                freebie_product: selectedFreebieProduct[0].id,
                quantity: quantity,
                start_date: startDate,
                end_date: endDate,
                total_redeem_count: parseInt(totalRedeemCount),
                total_redeem_count_per_customer: parseInt(totalRedeemCountPerCustomer)
            }

            createCouponHandler(data);
        }
        else if(couponType==="BUYGET"){
            if(buyGetApplicableTo === "CATEGORY"){
                if(buyGetCategory == 0){
                    setIsBuyGetCategoryError(true);
                    setBuyGetCategoryErrorTxt('Category is Required');
                    return
                }
                else{
                    setIsBuyGetCategoryError(false);
                    setBuyGetCategoryErrorTxt('');
                }
            }
            else if(buyGetApplicableTo === "PRODUCTS"){
                if(selectedBuyGetProducts.length === 0){
                    setIsBuyGetProductsError(true);
                    setBuyGetProductsErrorTxt('Product is Required');
                    return
                }
                else{
                    setIsBuyGetProductsError(false);
                    setBuyGetProductsErrorTxt('');
                }
            }

            if(buyQuantity === ''){
                setIsBuyQuantityError(true);
                setBuyQuantityErrorTxt('Buy Quantity is Required');
                return
            }
            else{
                if(buyQuantity == 0){
                    setIsBuyQuantityError(true);
                    setBuyQuantityErrorTxt('Buy Quantity Cannot Be 0');
                    return
                }
                else{
                    setIsBuyQuantityError(false);
                    setBuyQuantityErrorTxt('');
                }
            }

            if(getQuantity === ''){
                setIsGetQuantityError(true);
                setGetQuantityErrorTxt('Get Quantity is Required');
                return
            }
            else{
                if(getQuantity == 0){
                    setIsGetQuantityError(true);
                    setGetQuantityErrorTxt('Get Quantity Cannot Be 0');
                    return
                }
                else{
                    setIsGetQuantityError(false);
                    setGetQuantityErrorTxt('');
                }
            }

            let BG_Products = [];

            buyGetProducts.map(bgProd => {
                let product = {
                    product: bgProd.id
                }
                BG_Products.push(product);
            });

            const data = {
                coupon_type: "BUYGET",
                available_at: availableAt,
                service_type: "ALL",
                user_type: applicableTo,
                code: code,
                value: 0,
                value_type: "PERCENTAGE",
                max_discount: 0,
                min_order_value: 0,
                category: buyGetCategory,
                applicable_products: BG_Products,
                buy_quantity: buyQuantity,
                get_quantity: getQuantity,
                start_date: startDate,
                end_date: endDate,
                total_redeem_count: parseInt(totalRedeemCount),
                total_redeem_count_per_customer: parseInt(totalRedeemCountPerCustomer)
            }

            createCouponHandler(data);
        }
        else if(couponType==="FLAT"){
            if(flatApplicableTo === "CATEGORY"){
                if(selectedFlatCategories.length == 0){
                    setIsFlatCategoriesError(true);
                    setFlatCategoriesErrorTxt('Category is Required');
                    return
                }
                else{
                    setIsFlatCategoriesError(false);
                    setFlatCategoriesErrorTxt('');
                }
            }
            else if(flatApplicableTo === "PRODUCT"){
                if(selectedFlatProducts.length === 0){
                    setIsFlatProductsError(true);
                    setFlatProductsErrorTxt('Product is Required');
                    return
                }
                else{
                    setIsFlatProductsError(false);
                    setFlatProductsErrorTxt('');
                }
            }

            if(flatDiscount === ''){
                setIsFlatDiscountError(true);
                setFlatDiscountErrorTxt('Discount is Required');
                return
            }
            else{
                if(flatDiscount == 0){
                    setIsFlatDiscountError(true);
                    setFlatDiscountErrorTxt('Discount Cannot Be 0');
                    return
                }
                else{
                    if(flatDiscountType==="PERCENTAGE"){
                        if(parseInt(flatDiscount) > 100){
                            setIsFlatDiscountError(true);
                            setFlatDiscountErrorTxt('Percentage Cannot Exceed 100');
                            return
                        }
                        else{
                            setIsFlatDiscountError(false);
                            setFlatDiscountErrorTxt('');
                        }
                    }
                    else if(flatDiscountType==="RUPEE"){
                        setIsFlatDiscountError(false);
                        setFlatDiscountErrorTxt('');
                    }
                }
            }

            let Fl_Categories = [];
            let Fl_Products = [];

            selectedFlatCategories.map(flCat => {
                let category = {
                    id: flCat.id
                }
                Fl_Categories.push(category);
            });

            selectedFlatProducts.map(flProd => {
                let product = {
                    product: flProd.id
                }
                Fl_Products.push(product);
            });

            const data = {
                coupon_type: "FLAT",
                available_at: availableAt,
                service_type: "ALL",
                user_type: applicableTo,
                code: code,
                value: flatDiscount,
                value_type: flatDiscountType,
                max_discount: 0,
                min_order_value: 0,
                categories: flatApplicableTo === "CATEGORY" ? Fl_Categories : [],
                applicable_products: flatApplicableTo === "PRODUCT" ? Fl_Products : [],
                start_date: startDate,
                end_date: endDate,
                total_redeem_count: parseInt(totalRedeemCount),
                total_redeem_count_per_customer: parseInt(totalRedeemCountPerCustomer)
            }

            createCouponHandler(data);
        }
    }

    const resetForm = async () => {

    }

    return (
        <Section heading="coupons" description={routeText} action={() => navigate("/dashboard/masterdata/coupons")} actionLable="back" showAction={true}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitCreateCouponForm(e)} noValidate>
                    <SplitContainer type="fullwidth">
                        <TextInput lable="coupon code" isMandatory={true} isError={isCodeError} error={codeErrorTxt}>
                            <input type="text" className="formStepInputTxt" value={code} placeholder="Please Enter The Coupon Code" onChange={(e) => codeChanged(e.target.value)} />
                        </TextInput>
                        <TextInput lable="coupon type" isMandatory={true} isError={isCouponTypeError} error={couponTypeErrorTxt}>
                            <select className={"formStepInputSelect"} value={couponType} onChange={(e) => setCouponType(e.target.value)}>
                                <option value={0}>Please select the coupon type</option>
                                {
                                    couponTypes.map(type => {
                                        return (
                                            <option value={type.id} key={type.id}>{type.value}</option>
                                        )
                                    })
                                }
                            </select>
                        </TextInput>
                    </SplitContainer>
                    <SplitContainer type="fullwidth">
                        <RadioButtonGroup lable="Available at" capitalize={true} isMandatory={true} isError={isAvailableAtError} error={availableAtErrorTxt}>
                            <RadioButtonInput lable="store" name="availableAt" value="STORE" checked={availableAt === "STORE" ? true : false} onchange={(data) => setAvailableAt(data)} />
                            <RadioButtonInput lable="online" name="availableAt" value="ONLINE" checked={availableAt === "ONLINE" ? true : false} onchange={(data) =>  setAvailableAt(data)} />
                            <RadioButtonInput lable="both" name="availableAt" value="BOTH" checked={availableAt === "BOTH" ? true : false} onchange={(data) =>  setAvailableAt(data)} />
                        </RadioButtonGroup>
                        <RadioButtonGroup lable="Applicable to" capitalize={true} isMandatory={true} isError={isApplicableToError} error={applicableToErrorTxt}>
                            <RadioButtonInput lable="new users" name="applicableTo" value="NEW" checked={applicableTo === "NEW" ? true : false} onchange={(data) => setApplicableTo(data)} />
                            <RadioButtonInput lable="all users" name="applicableTo" value="ALL" checked={applicableTo === "ALL" ? true : false} onchange={(data) =>  setApplicableTo(data)} />
                        </RadioButtonGroup>
                    </SplitContainer>
                    <SplitContainer type="fullwidth">
                        <DatePicker customInput={<StartDateInput />} selected={startDate} selectsStart startDate={startDate} endDate={endDate} minDate={startDate} onChange={(date) => setStartDate(date)} timeInputLabel="Time:" timeFormat="HH:mm" timeIntervals={30} timeCaption="time" dateFormat="MMMM d, yyyy h:mm aa" showTimeInput />
                        <DatePicker customInput={<EndDateInput />} selected={endDate} selectsEnd startDate={startDate} endDate={endDate} minDate={startDate} onChange={(date) => setEndDate(date)} timeInputLabel="Time:" timeFormat="HH:mm" timeIntervals={30} timeCaption="time" dateFormat="MMMM d, yyyy h:mm aa" showTimeInput />
                    </SplitContainer>
                    {
                        couponType === "PROMO"
                        ?
                            <SplitContainer type="fullwidth">
                                <TextInput lable="discount percentage" isMandatory={true} isError={isPercentageError} error={percentageErrorTxt}>
                                    <input type="tel" className="formStepInputTxt" value={percentage} placeholder="Please Enter The Discount Percentage" onChange={(e) => promoPercentageChanged(e.target.value)} />
                                </TextInput>
                                <TextInput lable="Max Discount In Rs." isMandatory={true} isError={isMaxDiscountInRsError} error={maxDiscountInRsErrorTxt}>
                                    <input type="tel" className="formStepInputTxt" value={maxDiscountInRs} placeholder="Please Enter The Max Discount in Rs." onChange={(e) => promoMaxDiscountChanged(e.target.value)} />
                                </TextInput>
                            </SplitContainer>
                        :
                        couponType === "FREEBIE"
                        ?
                        <>
                            <TextInput lable="product" capitalize={true} isMandatory={true} isError={isFreebieProductError} error={freebieProductErrorTxt}>
                                <input type="text" className="formStepInputTxt capitalize" value={freebieProductName} placeholder="Enter The Product Name" onChange={(e) => freebieProductChanged(e.target.value)} />
                                {
                                    showFreebieProductAutocomplete
                                    ?
                                        <>
                                            <Autocomplete items={autoCompleteFreebieProducts} action={(data)=>freebieProductAutoCompleteSelectionHandler(data)} />
                                        </>
                                    :
                                        <></>
                                }
                                {
                                    showSelectedFreebieProduct
                                    ?
                                        <>
                                            <AutoCompleteBubble items={selectedFreebieProduct} action={(data) => deleteThisFreebieAutoCompleteProduct(data)} />
                                        </>
                                    :
                                        <></>
                                }
                            </TextInput>
                            <SplitContainer type="fullwidth">
                                <TextInput lable="quantity" isMandatory={true} isError={isQuantityError} error={quantityErrorTxt}>
                                    <input type="tel" className="formStepInputTxt" value={quantity} placeholder="Please Enter The Quantity" onChange={(e) => freebieQtyChanged(e.target.value)} />
                                </TextInput>
                                <TextInput lable="min. order value" isMandatory={true} isError={isMinOrderValueError} error={minOrderValueErrorTxt}>
                                    <input type="tel" className="formStepInputTxt" value={minOrderValue} placeholder="Please Enter The Min. Order Value" onChange={(e) => freebieMinOrderValChanged(e.target.value)} />
                                </TextInput>
                            </SplitContainer>
                                
                        </>
                        :
                        couponType === "BUYGET"
                        ?
                        <>
                            <SplitContainer type="fullwidth">
                                <RadioButtonGroup lable="Applicable To" capitalize={true} isMandatory={true} isError={isBuyGetApplicableToError} error={buyGetApplicableToErrorTxt}>
                                    <RadioButtonInput lable="specific category" name="buyGetApplicableTo" value="CATEGORY" checked={buyGetApplicableTo === "CATEGORY" ? true : false} onchange={(data) => handleChangeBuyGetApplicableTo(data)} />
                                    <RadioButtonInput lable="multiple products" name="buyGetApplicableTo" value="PRODUCTS" checked={buyGetApplicableTo === "PRODUCTS" ? true : false} onchange={(data) =>  handleChangeBuyGetApplicableTo(data)} />
                                </RadioButtonGroup>
                                {
                                    buyGetApplicableTo === "CATEGORY"
                                    ?
                                    <>
                                        <TextInput lable="category" isMandatory={true} isError={isBuyGetCategoryError} error={buyGetCategoryErrorTxt}>
                                            <select className={"formStepInputSelect"} value={buyGetCategory} onChange={(e) => setBuyGetCategory(e.target.value)}>
                                                <option value={0}>Please select the category</option>
                                                {
                                                    buyGetCategories.map(category => {
                                                        return (
                                                            <option value={category.id} key={category.id}>{category.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </TextInput>
                                    </>
                                    :
                                    buyGetApplicableTo === "PRODUCTS"
                                    ?
                                    <>
                                        <TextInput lable="product" capitalize={true} isMandatory={true} isError={isBuyGetProductsError} error={buyGetProductsErrorTxt}>
                                            <input type="text" className="formStepInputTxt capitalize" value={buyGetProductName} placeholder="Enter The Product Name" onChange={(e) => buyGetProductNameChanged(e.target.value)} />
                                            {
                                                showBuyGetProductAutocomplete
                                                ?
                                                    <>
                                                        <Autocomplete items={autoCompleteBuyGetProducts} action={(data)=>buyGetProductAutoCompleteSelectionHandler(data)} />
                                                    </>
                                                :
                                                    <></>
                                            }
                                            {
                                                showSelectedBuyGetProducts
                                                ?
                                                    <>
                                                        <AutoCompleteBubble items={selectedBuyGetProducts} action={(data) => deleteThisBuyGetAutocompleteProduct(data)} />
                                                    </>
                                                :
                                                    <></>
                                            }
                                        </TextInput>
                                    </>
                                    :
                                    <></>
                                }
                            </SplitContainer>

                            <SplitContainer type="fullwidth">
                                <TextInput lable="buy quantity" isMandatory={true} isError={isBuyQuantityError} error={buyQuantityErrorTxt}>
                                    <input type="tel" className="formStepInputTxt" value={buyQuantity} placeholder="Please Enter The Buy Quantity" onChange={(e) => setBuyQuantity(e.target.value)} />
                                </TextInput>
                                <TextInput lable="get quantity" isMandatory={true} isError={isGetQuantityError} error={getQuantityErrorTxt}>
                                    <input type="tel" className="formStepInputTxt" value={getQuantity} placeholder="Please Enter The Get Quantity" onChange={(e) => setGetQuantity(e.target.value)} />
                                </TextInput>
                            </SplitContainer>
                        </>
                        :
                        couponType === "FLAT"
                        ?
                        <>
                            <SplitContainer type="fullwidth">
                                <RadioButtonGroup lable="Applicable To" capitalize={true} isMandatory={true} isError={isFlatApplicableToError} error={flatApplicableToErrorTxt}>
                                    <RadioButtonInput lable="total order" name="flatApplicableTo" value="ORDER" checked={flatApplicableTo === "ORDER" ? true : false} onchange={(data) => handleChangeFlatApplicableTo(data)} />
                                    <RadioButtonInput lable="categories" name="flatApplicableTo" value="CATEGORY" checked={flatApplicableTo === "CATEGORY" ? true : false} onchange={(data) =>  handleChangeFlatApplicableTo(data)} />
                                    <RadioButtonInput lable="products" name="flatApplicableTo" value="PRODUCT" checked={flatApplicableTo === "PRODUCT" ? true : false} onchange={(data) =>  handleChangeFlatApplicableTo(data)} />
                                </RadioButtonGroup>
                                {
                                    flatApplicableTo === "CATEGORY"
                                    ?
                                    <>
                                        <TextInput lable="categories" capitalize={true} isMandatory={true} isError={isFlatCategoriesError} error={flatCategoriesErrorTxt}>
                                            <input type="text" className="formStepInputTxt capitalize" value={flatCategoryName} placeholder="Enter The Category Name" onChange={(e) => flatCategoryNameChanged(e.target.value)} />
                                            {
                                                showFlatCategoryAutocomplete
                                                ?
                                                    <>
                                                        <Autocomplete items={autoCompleteFlatCategories} action={(data)=>flatCategoryAutoCompleteSelectionHandler(data)} />
                                                    </>
                                                :
                                                    <></>
                                            }
                                            {
                                                showSelectedFlatCategories
                                                ?
                                                    <>
                                                        <AutoCompleteBubble items={selectedFlatCategories} action={(data) => deleteThisFlatAutocompleteCategory(data)} />
                                                    </>
                                                :
                                                    <></>
                                            }
                                        </TextInput>
                                    </>
                                    :
                                    flatApplicableTo === "PRODUCT"
                                    ?
                                    <>
                                        <TextInput lable="products" capitalize={true} isMandatory={true} isError={isFlatProductsError} error={flatProductsErrorTxt}>
                                            <input type="text" className="formStepInputTxt capitalize" value={flatProductName} placeholder="Enter The Product Name" onChange={(e) => flatProductNameChanged(e.target.value)} />
                                            {
                                                showFlatProductAutocomplete
                                                ?
                                                    <>
                                                        <Autocomplete items={autoCompleteFlatProducts} action={(data)=>flatProductAutoCompleteSelectionHandler(data)} />
                                                    </>
                                                :
                                                    <></>
                                            }
                                            {
                                                showSelectedFlatProducts
                                                ?
                                                    <>
                                                        <AutoCompleteBubble items={selectedFlatProducts} action={(data) => deleteThisFlatAutocompleteProduct(data)} />
                                                    </>
                                                :
                                                    <></>
                                            }
                                        </TextInput>
                                    </>
                                    :
                                    <></>
                                }
                            </SplitContainer>
                            <SplitContainer type="fullwidth">
                                <RadioButtonGroup lable="discount type" capitalize={true} isMandatory={true} isError={isFlatDiscountTypeError} error={flatDiscountTypeErrorTxt}>
                                    <RadioButtonInput lable="percentage" name="flatDiscountType" value="PERCENTAGE" checked={flatDiscountType === "PERCENTAGE" ? true : false} onchange={(data) => setFlatDiscontType(data)} />
                                    <RadioButtonInput lable="rupee" name="flatDiscountType" value="RUPEE" checked={flatDiscountType === "RUPEE" ? true : false} onchange={(data) =>  setFlatDiscontType(data)} />
                                </RadioButtonGroup>
                                <TextInput lable="discount" isMandatory={true} isError={isFlatDiscountError} error={flatDiscountErrorTxt}>
                                    <input type="tel" className="formStepInputTxt" value={flatDiscount} placeholder="Please Enter The Discount" onChange={(e) => flatDiscountChanged(e.target.value)} />
                                </TextInput>
                            </SplitContainer>
                        </>
                        :
                        <></>
                    }
                    <SplitContainer type={'fullwidth'}>
                        <TextInput lable="max redeem count" isMandatory={true} isError={isTotalRedeemCountError} error={totalRedeemCountErrorTxt}>
                            <input type="tel" className="formStepInputTxt" value={totalRedeemCount} placeholder="Please Enter The Max Redeem Count" onChange={(e) => totalRedeemCountChanged(e.target.value)} />
                        </TextInput>
                        <TextInput lable="max redeem count per customer" isMandatory={true} isError={isTotalRedeemCountPerCustomerError} error={totalRedeemCountPerCustomerErrorTxt}>
                            <input type="tel" className="formStepInputTxt" value={totalRedeemCountPerCustomer} placeholder="Please Enter The Max Redeem Count Per Custr." onChange={(e) => totalRedeemCountPerCustomerChanged(e.target.value)} />
                        </TextInput>
                    </SplitContainer>
                    <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                </form>
            </PlainContainer>
        </Section>
    )
}

export default AddCoupon
