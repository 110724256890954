import React, { useState, useEffect, useRef} from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import { BASIC_ORDER_UPDATED, PRODUCT_ADDED_TO_ORDER, REQUEST_ERROR } from '../../../../../utils/messages';
import { notifyError, notifySuccess, notifyWarning } from '../../../../../utils/toastify';

import axios from 'axios';

import { API_BASE_URL, LOGGER } from '../../../../../config';
import Form from '../../../../../components/general/forms/core/Form';
import { useSelector } from 'react-redux';
import SplitContainer from '../../../../../components/general/containers/SplitContainer';
import TextInput from '../../../../../components/general/forms/TextInput';
import FormActions from '../../../../../components/general/forms/core/FormActions';
import RadioButtonGroup from '../../../../../components/general/forms/RadioButtonGroup';
import RadioButtonInput from '../../../../../components/general/forms/RadioButtonInput';
import Autocomplete from '../../../../../components/general/forms/Autocomplete';
import AutoCompleteBubble from '../../../../../components/general/forms/AutoCompleteBubble';
import CheckBoxGroup from '../../../../../components/general/forms/CheckBoxGroup';
import CheckBoxInput from '../../../../../components/general/forms/CheckBoxInput';
import SelectInput from '../../../../../components/general/forms/SelectInput';

const AddProductToOrder = () => {
    let { id } = useParams(); 
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const [order, setOrder] = useState();

    const getThisOrder = async (order_id) => {
        try{
            const getThisOrderReq = await axios.get(`${API_BASE_URL}order/${order_id}`, config);
            if(getThisOrderReq.status === 200){
                console.log(getThisOrderReq.data);
                setOrder(getThisOrderReq.data);
            }
            else{
                notifyError(REQUEST_ERROR);
            }
        }
        catch (error) {
            LOGGER && console.log(error);
            notifyError(REQUEST_ERROR);
        }
    }

    useEffect(() => {
        getThisOrder(id);
    }, []);

    const [findBy, setFindBy] = useState('SHORTCODE');
    const [isFindByError, setIsFindByError] = useState(false);
    const [findByErrorTxt, setFindByErrorTxt] = useState('');

    const [manualProduct, setManualProduct] = useState();
    const [product, setProduct] = useState();
    const [name, setName] = useState('');
    const [quantity, setQuantity] = useState('');
    const [price, setPrice] = useState('');
    const [shortcode, setShortcode] = useState('');

    const [isShortcodeError, setIsShortcodeError] = useState(false);
    const [shortcodeErrorTxt, setShortcodeErrorTxt] = useState('');
    const shortcodeInputRef = useRef(null);

    const [isManualProductFound, setIsManualProductFound] = useState(false);
    
    const [manualEnteredName, setManualEnteredName] = useState('');
    const [manualEnterName, setManualEnterName] = useState('');
    const [manualQuantity, setManualQuantity] = useState(1);
    const [manualSize, setManualSize] = useState(0);
    const [manualCrust, setManualCrust] = useState(0);

    const [isManualEnteredNameError, setIsManualEnteredNameError] = useState(false);
    const [isManualEnterNameError, setIsManualEnterNameError] = useState(false);
    const [isManualQuantityError, setIsManualQuantityError] = useState(false);
    const [isManualSizeError, setIsManualSizeError] = useState(false);
    const [isManualCrustError, setIsManualCrustError] = useState(false);

    const [manualEnteredNameErrorTxt, setManualEnteredNameErrorTxt] = useState('');
    const [manualEnterNameErrorTxt, setManualEnterNameErrorTxt] = useState('');
    const [manualQuantityErrorTxt, setManualQuantityErrorTxt] = useState('');
    const [manualSizeErrorTxt, setManualSizeErrorTxt] = useState('');
    const [manualCrustErrorTxt, setManualCrustErrorTxt] = useState('');

    const [showManualEnterQuantity, setShowManualEnterQuantity] = useState(false);

    const [autoCompleteProducts, setAutoCompleteProducts] = useState([]);

    const [showProductsAutocomplete, setShowProductsAutocomplete] = useState(false);
    const [showSelectedAutoCompleteProduct, setShowSelectedAutoCompleteProduct] = useState(false);
    const [selectedAutoCompleteProduct, setSelectedAutoCompleteProduct] = useState([]);

    const [selectedVariants, setSelectedVariants] = useState([]);
    const [updatedLocalVariants, setUpdatedLocalVariants] = useState([]);

    const [localSizes, setLocalSizes] = useState([]);

    const [showSizeSelections, setShowSizeSelections] = useState(false);
    const [showCrustSelections, setShowCrustSelections] = useState(false);
    const [showVariantSelections, setShowVariantSelections] = useState(false);

    const getProductByShortcode = async (shortcode) => {
        try{
            const getProductReq = await axios.get(`${API_BASE_URL}product/shortcode/${shortcode}`, config);
            if(getProductReq.status === 200){
                return getProductReq.data;
            }
            else{
                return false
            }
        }
        catch (error) {
            return false
        }
    }    

    const manualEnterNameChanged = async (e) => {
        setManualEnterName(e);
        console.log(e);
        if(e.length >= 3){
            try {
                const getSimilarProductsReq = await axios.get(`${API_BASE_URL}product/similar/${e}`, config);
                console.log(getSimilarProductsReq);
                if (getSimilarProductsReq.status === 200) {
                    setAutoCompleteProducts(getSimilarProductsReq.data);
                    setShowProductsAutocomplete(true);
                }
                else{
                    setAutoCompleteProducts([]);
                    setShowProductsAutocomplete(false);
                }
            }
            catch (error) {
                LOGGER && console.log(error);
                notifyError(REQUEST_ERROR);
                setAutoCompleteProducts([]);
                setShowProductsAutocomplete(false);
            }
        }
        else{
            setAutoCompleteProducts([]);
            setShowProductsAutocomplete(false);
        }
    }
  
    const productAutoCompleteSelectionHandler = (product) => {
        console.log(product);
        const selectedProductArr = [];
        const selected_product = {
            id: product.id,
            shortcode: product.shortcode,
            name: product.name
        }
        setManualEnterName('');
        selectedProductArr.push(selected_product);
        setSelectedAutoCompleteProduct(selectedProductArr);
        setShowSelectedAutoCompleteProduct(true);
        setShowProductsAutocomplete(false);
    }
      
    const deleteThisAutoCompleteProduct = (product) => {
        setSelectedAutoCompleteProduct([]);
        setShowSelectedAutoCompleteProduct(false);
    }
  
    const isVariantSelectedReq = (variant_type_id, variant_id) => {        
        const variant_type = selectedVariants.find(variantType => variantType === variant_type_id);
        if(variant_type != undefined){
            const variant = variant_type.variants.find(variant => variant === variant_id);
            if(variant != undefined){
                return false;
            }
            else{
                return true;
            }
        }
        else{
            return false;
        }
    }

    const makeMaunalProductToDefault = () => {
        setIsManualProductFound(false);
        setShortcode('');
        setIsShortcodeError(false);
        setShortcodeErrorTxt('');
        setManualProduct();
        setManualEnterName('');
        setManualEnterNameErrorTxt('');
        setIsManualEnterNameError(false);
        setManualEnteredName('');
        setManualEnteredNameErrorTxt('');
        setIsManualEnteredNameError(false);
        setManualQuantity(1);
        setManualQuantityErrorTxt('');
        setIsManualQuantityError(false);
        setAutoCompleteProducts([]);
        setSelectedAutoCompleteProduct([]);
        setShowSelectedAutoCompleteProduct(false);
        setShowProductsAutocomplete(false);
    }

    useEffect(() => {
        makeMaunalProductToDefault();
    }, [findBy]);

    const checkAndSetQuantity = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setManualQuantity(value);
    }

    const getAndSetManualProduct = async (shortcode) => {
        const product = await getProductByShortcode(shortcode);

        console.log(product);
        if(product === false) {
            return console.log(false);
        }
    
        setProduct(product);
        setUpdatedLocalVariants(product.variants);
    
        let manual_product = {
            id: product.id,
            name: product.name,
            shortcode: product.shortcode,
            category: product.category.id,
            quantity: manualQuantity,
            sizes: [],
            variants: product.variants != undefined ? product.variants : [],
            pricing: product.pricing
        }
        console.log(manual_product);

        

        product.variants = manual_product.variants;
        setManualProduct(manual_product);
        setManualEnteredName(manual_product.name);
        setIsManualProductFound(true);
        setShowManualEnterQuantity(true);
        
        if(manual_product.category.name === "pizza"){
            setShowSizeSelections(true);
        }
        else{
            setManualSize(0);
            setManualCrust(0);
            setShowSizeSelections(false);
            setShowCrustSelections(false);
        }

        if(manual_product.variants.length != 0){
            console.log(true);
            setShowVariantSelections(true);
        }
        else{
            setShowVariantSelections(false);
        }
    }

    const variantSelectionUpdated = (e, variant_type_id, variant_id) => {

        let variant_type = manualProduct.variants.find(variant_type => variant_type.id === variant_type_id);
        let max_selection = variant_type.max_selection;
        let min_selection = variant_type.max_selection;
        let variant = variant_type.types.find(singleVariant => singleVariant.id === variant_id);

        console.log(variant);

        if(e.target.checked === false){
            if(variant.is_mandatory === true){
                document.getElementById(variant_id).checked = false;
                notifyError("Variant is Mandatory");
                return
            }
            variant.is_selected = false;

            let this_product = product;
            let product_variant_type = this_product.variants.find(singleVariantType => singleVariantType.id === variant_type_id);
            let product_variant = product_variant_type.types.find(singleVariant => singleVariant.id === variant_id);
            product_variant.is_selected = false;
            setProduct(this_product);
        }
        else{
            let selected_variant_count = 0;
            if(max_selection === 1){
                variant_type.types.map(singleVariant => {
                    if(singleVariant.id != variant_id){
                        if(singleVariant.is_selected === true){
                            singleVariant.is_selected = false;
                            document.getElementById(singleVariant.id).checked = false;

                            let this_product = product;
                            let product_variant_type = this_product.variants.find(singleVariantType => singleVariantType.id === variant_type_id);
                            let product_variant = product_variant_type.types.find(this_variant => this_variant.id === singleVariant.id);
                            product_variant.is_selected = false;
                            setProduct(this_product);
                        }
                    }
                });
            }

            variant_type.types.map(singleVariant => {
                if(singleVariant.is_selected === true){
                    selected_variant_count = selected_variant_count + 1;
                }
            });

            if(selected_variant_count >= max_selection){
                document.getElementById(variant_id).checked = false;
                notifyError(`Sorry, Max ${max_selection} variant can be selected.`);
                return
            }

            variant_type.types.map(singleVariant => {
                if(singleVariant.id === variant_id){
                    singleVariant.is_selected = true;

                    let this_product = product;
                    let product_variant_type = this_product.variants.find(singleVariantType => singleVariantType.id === variant_type_id);
                    let product_variant = product_variant_type.types.find(this_variant => this_variant.id === variant_id);
                    product_variant.is_selected = true;
                    setProduct(this_product);
                }
            });
        }
    }

    const submitAddProduct = async (e) => {
        e.preventDefault();
        if(findBy === "SHORTCODE"){
            if(isManualProductFound === false){
                getAndSetManualProduct(shortcode);
            }
            else{
                if(product.variants.length != 0){

                }
            }
        }
        else if(findBy === "PRODUCT_NAME"){
            if(selectedAutoCompleteProduct.length != 0) {
                setIsManualEnterNameError(false);
                setManualEnterNameErrorTxt('');
        
                if(isManualProductFound === false){
                    getAndSetManualProduct(selectedAutoCompleteProduct[0].shortcode);
                }
                else{

                }
            }
        }
    }
  
    return (
        <Section heading="Create Order" description={routeText} action={order?.products.length != 0 ? () => navigate(`/dashboard/orders/create/summary/${id}`) : console.log('discard')} actionLable={order?.products.length != 0 ? "Go To Summary" : "discard order"} showAction={order != undefined ? true : false}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitAddProduct(e)} noValidate>
                <Form>
                        <RadioButtonGroup lable="find by" capitalize={true} isMandatory={true} isError={isFindByError} error={findByErrorTxt}>
                            <RadioButtonInput lable="shortcode" name="find_by" value="SHORTCODE" checked={findBy === 'SHORTCODE' ? true : false} onchange={(data) => {setFindBy(data);}} />
                            <RadioButtonInput lable="product name" name="find_by" value="PRODUCT_NAME" checked={findBy === 'PRODUCT_NAME' ? true : false} onchange={(data) => {setFindBy(data);}} />
                        </RadioButtonGroup>
                        {
                            findBy === "SHORTCODE"
                            ?
                            <>
                                <TextInput lable="short code" capitalize={true} isMandatory={true} isError={isShortcodeError} error={shortcodeErrorTxt}>
                                    <input type="tel" ref={shortcodeInputRef} className="formStepInputTxt capitalize" value={shortcode} placeholder="Enter The Shortcode" onChange={(e) => setShortcode(e.target.value)} />
                                </TextInput>
                            </>
                            :
                            <>
                                <TextInput lable="name" capitalize={true} isMandatory={true} isError={isManualEnterNameError} error={manualEnterNameErrorTxt}>
                                    <input type="text" className="formStepInputTxt capitalize" value={manualEnterName} placeholder="Enter The Product Name" onChange={(e) => manualEnterNameChanged(e.target.value)} />
                                    {
                                        showProductsAutocomplete
                                        ?
                                            <>
                                                <Autocomplete items={autoCompleteProducts} action={(data)=>productAutoCompleteSelectionHandler(data)} />
                                            </>
                                        :
                                            <></>
                                    }
                                    {
                                        showSelectedAutoCompleteProduct
                                        ?
                                            <>
                                                <AutoCompleteBubble items={selectedAutoCompleteProduct} action={(data) => deleteThisAutoCompleteProduct(data)} />
                                            </>
                                        :
                                            <></>
                                    }
                                </TextInput>
                            </>
                        }
                        {
                            isManualProductFound
                            ?
                            <>
                                <TextInput lable="product" capitalize={true} isMandatory={true} isError={isManualEnteredNameError} error={manualEnteredNameErrorTxt}>
                                    <input type="text" readOnly={true} className="formStepInputTxt capitalize" value={manualEnteredName} placeholder="Enter The Product Name" onChange={(e) => setManualEnteredName(e.target.value)} />
                                </TextInput>
                                {
                                    showManualEnterQuantity
                                    ?
                                    <>
                                    <TextInput lable="quantity" capitalize={true} isMandatory={true} isError={isManualQuantityError} error={manualQuantityErrorTxt}>
                                        <input type="tel" className="formStepInputTxt capitalize" value={manualQuantity} placeholder="Enter The Quantity" onChange={(e) => checkAndSetQuantity(e)} />
                                    </TextInput>
                                    </>
                                    :
                                    <></>
                                }
                                {

                                }
                                <SelectInput lable="size" isMandatory={true} isError={isManualQuantityError} error={manualQuantityErrorTxt} placeholder={'Select The Size'} value={0} capitalize={true} options={product.sizes} ></SelectInput>
                                {
                                    showVariantSelections
                                    ?
                                        <>
                                            {
                                                updatedLocalVariants.map(variantType => {
                                                    return (
                                                        <SplitContainer key={variantType.id} type={`fullwidth`}>
                                                                <CheckBoxGroup lable={`${variantType.lable} (Min ${variantType.min_selection} - Max ${variantType.max_selection})`} capitalize={true} isMandatory={false} isError={false} error={``}>
                                                                    {
                                                                        variantType.types.map(variant => {
                                                                            return (
                                                                                <CheckBoxInput key={variant.id} id={variant.id} lable={variant.price.base.store == 0 ? `${variant.name}` : `${variant.name} - ${variant.price.base.store}Rs`} name={variantType.name} value={variant.is_selected} checked={variant.is_selected ? true : false} disabled={variant.is_mandatory ? true: false} onchange={(e) => variantSelectionUpdated(e, variantType.id, variant.id)} />
                                                                            )
                                                                        })
                                                                    }
                                                                </CheckBoxGroup>
                                                        </SplitContainer>
                                                    )
                                                })
                                            } 
                                        </>
                                    :
                                        <></>
                                }
                            </>
                            :
                            <></>
                        }
                        <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => window.location.reload()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}

export default AddProductToOrder