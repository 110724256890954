import React, { useState, useEffect, useRef} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import PlainContainer from '../../../../../components/general/containers/PlainContainer';
import Section from '../../../../../components/general/containers/Section';
import { BASIC_ORDER_CREATED, CUSTOMER_AVAILABLE, CUSTOMER_CREATED, CUSTOMER_NOT_AVAILABLE, ORDER_CREATED, REQUEST_ERROR } from '../../../../../utils/messages';
import { notifyError, notifySuccess, notifyWarning } from '../../../../../utils/toastify';

import axios from 'axios';

import { API_BASE_URL, LOGGER } from '../../../../../config';
import Form from '../../../../../components/general/forms/core/Form';
import { useSelector } from 'react-redux';
import SelectInput from '../../../../../components/general/forms/SelectInput';
import SplitContainer from '../../../../../components/general/containers/SplitContainer';
import TextInput from '../../../../../components/general/forms/TextInput';
import FormActions from '../../../../../components/general/forms/core/FormActions';

const CreateBasicOrder = () => {
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const [stores, setStores] = useState([]);
    const [store, setStore] = useState('0');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('');
    const [customer, setCustomer] = useState();
    const [isCustomerFound, setIsCustomerFound] = useState(false);
    const [showEnterName, setShowEnterName] = useState(false);

    const [isStoreError, setIsStoreError] = useState(false);
    const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);
    const [isNameError, setIsNameError] = useState(false);
    const [isCustomerError, setIsCustomerError] = useState(false);

    const [storeErrorTxt, setStoreErrorTxt] = useState('');
    const [phoneNumberErrorTxt, setPhoneNumberErrorTxt] = useState('');
    const [nameErrorTxt, setNameErrorTxt] = useState('');

    const userNameRef = useRef(null);

    const getStores = async () => {
        try{
            const getStoresReq = await axios.get(`${API_BASE_URL}store`);
            if(getStoresReq.status === 200){
                setStores(getStoresReq.data.items);
            }
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER && console.log(error);
        }
    }

    useEffect(() => {
        getStores();
    }, []);

    const checkCustomerAvailability = async () => {
        try {
            const checkCustomerPhoneReq = await axios.get(`${API_BASE_URL}customer/phone/${phoneNumber}`, config);
            if(checkCustomerPhoneReq.status === 200) {
                LOGGER && console.log(checkCustomerPhoneReq.data);
                notifySuccess(CUSTOMER_AVAILABLE)
                setCustomer(checkCustomerPhoneReq.data);
                createBasicOrder(checkCustomerPhoneReq.data.id);
            }
        }
        catch (error) {
            if (error.response.status === 404) {
                notifyWarning(CUSTOMER_NOT_AVAILABLE);
                setShowEnterName(true);
                userNameRef.current.focus();
            }
            else{
                notifyError(REQUEST_ERROR);
            }
        }
    }

    const registerUser = async (e) => {
        const data = {
            name: name,
            phone_number: phoneNumber
        }
    
        try {
            const registerCustomerReq = await axios.post(`${API_BASE_URL}customer`, data, config);
            if (registerCustomerReq.status === 201) {
                notifySuccess(CUSTOMER_CREATED);
                createBasicOrder(registerCustomerReq.data.customer_id);
            }
        }
        catch (error) {
            if(error.response.status === 404){
                setShowEnterName(true);
            }
            else{
                notifyError(REQUEST_ERROR);
            }
        }
    }

    const createBasicOrder = async (customer_id) => {
        const customerData = {
            customer_id: customer_id,
            store: store,
            created_by: admin.id
        }
    
        LOGGER && console.log(customerData);
    
        try {
            const createBasicOrderReq = await axios.post(`${API_BASE_URL}order/basic`, customerData, config);
            if (createBasicOrderReq.status === 201) {
                notifySuccess(BASIC_ORDER_CREATED);
                navigate(`/dashboard/orders/create/addproduct/${createBasicOrderReq.data.id}`);
            }
        }
        catch (error) {
            LOGGER && console.log(error);
            notifyError(REQUEST_ERROR);
        }
    }
    
    const resetForm = () => {

    }

    const submitCreateBasicOrderForm = (e) => {
        e.preventDefault();

        if(name != ''){
            return registerUser();
        }
        if(isCustomerFound != true){
            checkCustomerAvailability();
        }
    }

    return (
        <Section heading="Create Order" description={routeText} action={() => navigate("/dashboard/orders/storeorders")} actionLable="back" showAction={false}>
            <PlainContainer styles={{width: '100%', display: 'flex', flexDirection: 'column', position: 'static'}}>
                <form onSubmit={(e) => submitCreateBasicOrderForm(e)} noValidate>
                    <Form>
                      <SelectInput lable="store" capitalize={true} isMandatory={true} placeholder="Please Select The Store" value={store} onchange={(data) => setStore(data)} isError={isStoreError} error={storeErrorTxt} options={stores} />
                      <SplitContainer type="fullwidth">
                          <TextInput lable="phone number" capitalize={true} isMandatory={true} isError={isPhoneNumberError} error={phoneNumberErrorTxt}>
                            <input type="tel" className="formStepInputTxt capitalize" value={phoneNumber} placeholder="Please Enter The Phone Number" onChange={(e) => setPhoneNumber(e.target.value)} />
                          </TextInput>
                          {
                            showEnterName === true
                            ?
                            <TextInput lable="name" isMandatory={true} isError={isNameError} error={nameErrorTxt}>
                              <input type="text" ref={userNameRef} className="formStepInputTxt capitalize" value={name} placeholder="Please Enter The Customer Name" onChange={(e) => setName(e.target.value)} />
                            </TextInput>
                            :
                            <></>
                          }
                      </SplitContainer>
                      <FormActions isPrimary={true} isSecondary={true} primaryLable="submit" secondaryLable="reset" secondaryAction={() => resetForm()} />
                    </Form>
                </form>
            </PlainContainer>
        </Section>
    )
}


export default CreateBasicOrder;